// --- Constants ---
export const GET_DEMO_URL = "https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo";
export const PRODUCT_HUNT_URL = "https://www.producthunt.com/products/intelswift";
export const SIGN_UP_URL = "https://login.intelswift.com/oauth/account/sign-up";

// --- Application Constants ---
export const APPLICATION_FORM_URL = "https://forms.gle/zkNB67KKWgkYVQz4A";

// --- Navigation Links Constants ---

export const FOOTER_NAV_LINKS = [
    { path: "/pricing", labelKey: "Pricing" },
    { path: "/about", labelKey: "About Us" },
    { path: "/blog", labelKey: "Blog" },
    { path: "/privacy-policy", labelKey: "Privacy Policy" },
    { path: "/terms-and-conditions", labelKey: "Terms of Services" },
];

// --- Pricing Plans Data ---
export const HARDCODED_PLANS = [
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.884Z",
        description: "Intelswift Free plan",
        isDeleted: false,
        metadata: { conversations_credit: "" },
        name: "Free",
        price: 0,
        priceId: "price_1OkzxXCGsJZY9lajOvYGKSwg",
        stripePlanId: "prod_PaAIh270C36jfM",
        updatedAt: "2024-04-02T06:07:38.801Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa050"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.884Z",
        description: "Intelswift Personal plan",
        isDeleted: false,
        metadata: { conversations_credit: "200" },
        name: "Personal",
        price: 19,
        priceId: "price_1OkzxXCGsJZY9lajOvYGKSwg",
        stripePlanId: "prod_PaAIh270C36jfM",
        updatedAt: "2024-04-02T06:07:38.801Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa050"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.884Z",
        description: "Intelswift Personal plan",
        isDeleted: false,
        metadata: { conversations_credit: "2400" },
        name: "Personal annual",
        price: 190,
        priceId: "price_1OkzxXCGsJZY9lajOvYGKSwg",
        stripePlanId: "prod_PaAIh270C36jfM",
        updatedAt: "2024-04-02T06:07:38.801Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa050"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.694Z",
        description: "Intelswift Pro plan",
        isDeleted: false,
        metadata: { conversations_credit: "500" },
        name: "Pro",
        price: 49,
        priceId: "price_1OkzuzCGsJZY9laj73lNn9Ti",
        stripePlanId: "prod_PaAFuFBd3Pc6Xr",
        updatedAt: "2024-04-02T06:07:39.240Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa04d"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.499Z",
        description: "Intelswift Business plan",
        isDeleted: false,
        metadata: { conversations_credit: "1000" },
        name: "Business",
        price: 125,
        priceId: "price_1Okzw2CGsJZY9lajyJKPEqvy",
        stripePlanId: "prod_PaAGEUtaNOXiGY",
        updatedAt: "2024-04-02T06:07:39.020Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa04a"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.304Z",
        description: "Intelswift Pro plan annual",
        isDeleted: false,
        metadata: { conversations_credit: "6000" },
        name: "Pro annual",
        price: 490,
        priceId: "price_1Ol00tCGsJZY9lajedAuAhFL",
        stripePlanId: "prod_PaAL8M35TcmqQy",
        updatedAt: "2024-04-02T06:07:38.364Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa047"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.112Z",
        description: "Intelswift Business plan (annual)",
        isDeleted: false,
        metadata: { conversations_credit: "12000" },
        name: "Business annual",
        price: 1250,
        priceId: "price_1Ol00HCGsJZY9lajjxXpsIX8",
        stripePlanId: "prod_PaAKOzOHtcTkVO",
        updatedAt: "2024-04-02T06:07:38.583Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa044"
    }
];