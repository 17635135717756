// --- Third Party Libraries ---
import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import { blogHeaderImages } from "../../utils/imageUtils";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// --- Slug Mapping ---
const blogSlugs = {
    1: "ai-sales",
    2: "ai-customer-service-2025",
    3: "chatgpt-vs-gemini",
    4: "intercom-alternatives",
    5: "best-customer-service-automation-software",
    6: "freshworks-alternative",
    7: "zendesk-alternative",
    8: "tidio-alternative",
    9: "helpcrunch-alternatives",
    10: "liveperson-competitors",
    11: "chatbot-alternatives"
};

// --- BlogPage Component ---
export default function BlogPage() {

    // --- State and Hooks ---
    const navigate = useNavigate();
    const { t } = useTranslation();

    // --- Articles array
    const articles = useMemo(
        () => t("BLOG_PAGE.ARTICLES", { returnObjects: true }),
        [t]
    );

    // --- Event Handlers ---
    const handleCardClick = (id) => {
        const numericId = parseInt(id.replace("ARTICLE_", ""));
        const slug = blogSlugs[numericId];
        if (slug) {
            navigate(`/blog/${slug}`);
        } else {
            console.warn("Slug not found for article ID:", id);
        }
    };

    const sortedArticles = useMemo(() => {
        return Object.entries(articles)
            .sort(([aKey], [bKey]) => {
                // Extract the number from ARTICLE_1 → 1
                const aNum = parseInt(aKey.replace("ARTICLE_", ""));
                const bNum = parseInt(bKey.replace("ARTICLE_", ""));
                return bNum - aNum; // Descending (newest first)
            });
    }, [articles]);

    // --- useEffect Hooks ---
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // --- Render Component ---
    return (
        <>
            <Header />
            <Box className="blog-section">
                <Typography variant="h3" gutterBottom>{t("BLOG_PAGE.TITLE")}</Typography>
                <Grid container spacing={4}>
                    {sortedArticles.map(([id, article]) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
                            <Card className="blog-card" onClick={() => handleCardClick(id)}>
                                <CardMedia
                                    component="img"
                                    src={blogHeaderImages[article.CARD_MEDIA]}
                                    alt={article.TITLE}
                                />
                                <CardContent className="blog-content">
                                    <Chip label={article.TYPE} />
                                    <Typography variant="subtitle1">
                                        {article.TITLE}
                                    </Typography>
                                    <Typography variant="body1">
                                        {article.SUBTITLE}
                                    </Typography>
                                    <Typography variant="caption">
                                        {article.DATE}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Footer />
        </>
    );
}
