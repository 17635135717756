import React from "react";
import { Typography, Button, Grid, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import translation hook
import { Link as RouterLink } from "react-router-dom";

import {
  SIGN_UP_URL,
} from "./../../utils/constants";

const Hero = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <Grid container spacing={4} className="copilot-hero-section">
      <Grid item xs={12} md={12} className="copilot-hero-left">
        <Typography variant="h1">
          {t("COPILOT_PAGE.HERO.TITLE")}
        </Typography>
        <Typography variant="h2">
          {/* {t("COPILOT_PAGE.HERO.DESCRIPTION_1")} */}
        </Typography>
        <Stack direction="column" spacing={2} sx={{alignItems: "center", width: "100%"}}>
          <Typography variant="subtitle1">
          {t("COPILOT_PAGE.HERO.DESCRIPTION_1")}
          </Typography>
          <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
            <Button className="free-button" target="_blank">
              {t("COMMON.SIGN_UP_FREE_BUTTON")}
            </Button>
          </Link>
          <Typography variant="subtitle1">
            {t("COPILOT_PAGE.HERO.NO_CREDIT_CARD")}
          </Typography>
        </Stack>
        
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <img
          src={copilotImages.copilot_img_1}
          alt={t("COPILOT_PAGE.HERO.IMAGE_ALT")}
        />
      </Grid> */}
    </Grid>
  );
}

export default Hero;
