import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom'; 
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';  
import Routes from './Routes';
import { DefaultTheme } from "./common_css/defaultTheme";

function App() {
  
  useEffect(() => {
    const isProduction = window.location.hostname.includes("intelswift.com") && !window.location.hostname.includes("stage.intelswift.com");
    if (isProduction) {
      ReactGA.initialize("G-8N5SMHJYGP");
    }
  }, []);

  return (
    <HelmetProvider>  
      <ThemeProvider theme={DefaultTheme}>
        <Router> 
          <Routes />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
