// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { advancedAnalyticsImages } from "../../utils/imageUtils";

// --- Constants ---
import {
    SIGN_UP_URL,
    GET_DEMO_URL,
  } from "../../utils/constants";

// --- Visibility Component ---
const Visibility = () => {
    const { t } = useTranslation();

    return (
        <Box className="advanced-analytics-visibility-container">
            <Typography variant="h3">
                {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.TITLE")}
            </Typography>
            <Typography variant="subtitle1">
                {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.DESCRIPTION")}
            </Typography>
            <Box className="advanced-analytics-visibility-section">
                <Grid container spacing={4} className="advanced-analytics-visibility-grid-container">
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-left">
                        <Typography variant="h3">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_1.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_1.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-image-container">
                        <img
                            className="advanced-analytics-visibility-image"
                            src={advancedAnalyticsImages.advancedAnalytics_img_2}
                            alt={t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_1.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
                </Box>
                <Box className="advanced-analytics-visibility-section">
                <Grid container spacing={4} className="advanced-analytics-visibility-grid-container">
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-image-container">
                        <img
                            className="advanced-analytics-visibility-image"
                            src={advancedAnalyticsImages.advancedAnalytics_img_3}
                            alt={t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_2.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-left">
                        <Typography variant="h3">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_2.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_2.DESCRIPTION")}
                        </Typography>
                    </Grid>
                </Grid>
                </Box>
                <Box className="advanced-analytics-visibility-section">
                <Grid container spacing={4} className="advanced-analytics-visibility-grid-container">
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-left">
                        <Typography variant="h3">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_3.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("ADVANCED_ANALYTICS_PAGE.VISIBILITY.SECTION_3.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="advanced-analytics-visibility-image-container">
                        <img
                            className="advanced-analytics-visibility-image"
                            src={advancedAnalyticsImages.advancedAnalytics_img_4}
                            alt="Intelswift - Customize reports"
                        />
                    </Grid>
                </Grid>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" className="advanced-analytics-visibility-demo-button" href={GET_DEMO_URL} target="_blank">
                        {t("COMMON.REQUEST_DEMO_BUTTON")}
                    </Button>
                    <Button variant="outlined" className="advanced-analytics-visibility-trial-button" href={SIGN_UP_URL} target="_blank">
                        {t("COMMON.START_TRIAL_BUTTON")}
                    </Button>
                </Stack>
        </Box>
    );
};

export default Visibility;