// --- Third Party Libraries ---
import React, { useState } from "react";
import { Box, Typography, Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
// --- Internal Components ---
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cards from "../../components/PlansContent/cards";
import "./index.css";

// --- Constants ---
import { HARDCODED_PLANS, APPLICATION_FORM_URL } from "../../utils/constants";

// --- Pricing Component ---
const Pricing = () => {
    const { t } = useTranslation();
    const [plans, setPlans] = useState(HARDCODED_PLANS);

    return (
        <>
            <Header />
            <Box className="plans-container">
                <Cards plans={plans} />
                <Card className="promotion-container-card">
                    <Typography variant="h3">
                        {t("PLANS.PROMOTION.TITLE")} {t("PLANS.PER")} <span>$7 {t("PLANS.PER_MONTH")}</span> {t("PLANS.PROMOTION.FOR_BUSINESS_PLAN")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("PLANS.PROMOTION.DESCRIPTION")}
                    </Typography>
                    <Button href={APPLICATION_FORM_URL} target="_blank" variant="contained">
                        {t("PLANS.PROMOTION.BUTTON")}
                    </Button>
                </Card>
            </Box>
            <Footer />
        </>
    );
};


export default Pricing;