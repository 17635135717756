// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import WhyChooseIntelswift from "../../components/WhyChooseIntelswift/index.jsx";
import CommissionSlider from "./Commission.jsx";
import CallToAction from "../../components/CallToAction/index.jsx";
import Steps from "./Steps.jsx";
import Partnerships from "./Partnerships.jsx";
import PartnerProgram from "./PartnerProgram.jsx";
import FAQs from "../../components/FAQs/index.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- Partnership Component ---
export default function Partnership() {
  const { t } = useTranslation();

  const whyChooseData = {
    title: t("PARTNERSHIP_PAGE.WHY_CHOOSE.TITLE"),
    subtitle: t("PARTNERSHIP_PAGE.WHY_CHOOSE.SUBTITLE"),
    benefits: [
      {
        title: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.TRACKING.TITLE"),
        description: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.TRACKING.DESCRIPTION")
      },
      {
        title: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.COMMISSION.TITLE"),
        description: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.COMMISSION.DESCRIPTION")
      },
      {
        title: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.EASY_START.TITLE"),
        description: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.EASY_START.DESCRIPTION")
      },
      {
        title: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.DASHBOARD.TITLE"),
        description: t("PARTNERSHIP_PAGE.WHY_CHOOSE.BENEFITS.DASHBOARD.DESCRIPTION")
      }
    ],
    showButtons: false
  };

  const partnershipFAQs = [
    { question: t("PARTNERSHIP_PAGE.FAQS.Q1.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q1.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q2.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q2.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q3.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q3.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q4.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q4.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q5.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q5.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q6.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q6.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q7.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q7.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q8.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q8.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q9.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q9.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q10.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q10.ANSWER") },
    { question: t("PARTNERSHIP_PAGE.FAQS.Q11.QUESTION"), answer: t("PARTNERSHIP_PAGE.FAQS.Q11.ANSWER") }
  ];
  
  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("PARTNERSHIP_PAGE.META.TITLE")}
        description={t("PARTNERSHIP_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/partnership"
      />
      <Header />
      <HeroSection />
      <WhyChooseIntelswift
        title={whyChooseData.title}
        subtitle={whyChooseData.subtitle}
        benefits={whyChooseData.benefits}
        showButtons={whyChooseData.showButtons}
        gridMd={6}
        isProductsPage={true}
      />
      <CommissionSlider />
      <CallToAction
        title={t("PARTNERSHIP_PAGE.CALL_TO_ACTION.TITLE")}
        subtitle={t("PARTNERSHIP_PAGE.CALL_TO_ACTION.SUBTITLE")}
        showButton={false}
      />
      <Steps />
      <Partnerships />
      <PartnerProgram />
      <FAQs
        faqs={partnershipFAQs}
      />
      <Footer />
    </>
  );
}
