import React, { useRef } from "react";
import { Box, Typography, Grid, Button, Stack } from "@mui/material";
import { motion } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import "./index.css";

import {
  SIGN_UP_URL,
  GET_DEMO_URL,
} from "../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

const WhyChooseIntelswift = ({ title, subtitle, benefits, showButtons = true, gridMd = 4, isProductsPage = false }) => {
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);
  const buttonsRef = useRef(null);
  const { t } = useTranslation();

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
        toggleActions: "play none none reverse",
        once: false,
      },
    });

    tl.fromTo(
      sectionRef.current,
      { opacity: 0, y: 0 },
      {
        opacity: 1,
        y: 0,
        duration: 1.0,
        ease: "power1.out",
      }
    ).addLabel("startCards")
      .fromTo(
        cardsRef.current,
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power1.out",
        },
        "startCards+=0"
      )
      .fromTo(
        buttonsRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power1.out",
        },
        "+=0.4"
      );
  }, { scope: sectionRef }); 

  return (
    <Box ref={sectionRef} className="why-choose-container">
      <Typography variant="h3">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Grid container spacing={4} className={`${isProductsPage ? "products-grid" : ""}`} >
        {benefits.map((benefit, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={gridMd}
            ref={(el) => (cardsRef.current[index] = el)}
            className="why-choose-card"
          >
            <motion.div className="why-choose-card-content" whileHover={{ scale: 1.05 }}>
              <motion.div
                className="css-circle"
                whileHover={{ scale: 1.1, rotate: 5 }}
                transition={{ type: "spring", stiffness: 300 }}
              />
              <Typography variant="h5" className="why-choose-title">
                {benefit.title}
              </Typography>
              <Typography variant="body1" className="why-choose-description">
                {benefit.description}
              </Typography>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      {showButtons && (
        <Stack direction="row" className="why-choose-stack-buttons" ref={buttonsRef}>
          <Button variant="contained" className="why-choose-demo-button" href={GET_DEMO_URL} target="_blank">{t("COMMON.REQUEST_DEMO_BUTTON")}</Button>
          <Button variant="outlined" className="trial-button" href={SIGN_UP_URL} target="_blank">{t("COMMON.START_TRIAL_BUTTON")}</Button>
        </Stack>
      )}
    </Box>
  );
};

export default WhyChooseIntelswift;
