// --- Third Party Libraries ---
import React from "react";
import { Typography, Button, Box, Card, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// --- CSS ---
import "./index.css";

import {
    SIGN_UP_URL,
  } from "./../../utils/constants";

// --- Image Utilities ---
import { tryForFreeImages } from "../../utils/imageUtils";

// --- LevelUp Component ---
const LevelUp = ({ title, subtitle, button, isLiveChatPage = false, isWorkflowsPage = false }) => {
    return (
        <Box
        className={`level-up-section ${
          isLiveChatPage ? "live-chat-background" : isWorkflowsPage ? "workflows-background" : ""
        }`}
      >      
            <Card className="level-up-card">
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} position="relative">
                    <Box
                        className="level-up-card-left-content"
                        flex={{ xs: '1 1 100%', md: '1 1 60%' }}
                    >
                        <Typography variant="h3">{title}</Typography>
                        <Typography variant="subtitle1">{subtitle}</Typography>
                        <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                            <Button className="free-button" target="_blank">
                                {button}
                            </Button>
                        </Link>
                    </Box>
                    <Box
                        className="level-up-card-right-content"
                        flex={{ xs: '1 1 100%', md: '1 1 40%' }}
                    >
                        <img src={tryForFreeImages.Logomark} alt="Logomark" />
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default LevelUp;
