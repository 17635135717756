// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { knowledgeBaseImages } from "../../utils/imageUtils";

// --- Features Component ---
const Features = () => {
    const { t } = useTranslation();

    return (
        <Box className="knowledge-base-features-section">
            <Typography variant="h3" className="knowledge-base-features-section-title">
                {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.TITLE")}
            </Typography>
            <Typography variant="subtitle1" className="knowledge-base-features-section-subtitle">
                {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.SUBTITLE")}
            </Typography>
            <Box className="knowledge-base-features-container">
                <Grid container spacing={4} className="knowledge-base-features-grid-container">
                    <Grid item xs={12} md={5} className="knowledge-base-features-left">
                        <Typography variant="h3">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_1.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_1.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} className="knowledge-base-features-image-container">
                        <img
                            className="knowledge-base-features-image"
                            src={knowledgeBaseImages.knowledge_base_img_2}
                            alt={t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_1.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="knowledge-base-features-container">
                <Grid container spacing={4} className="knowledge-base-features-grid-container">
                    <Grid item xs={12} md={7} className="knowledge-base-features-image-container">
                        <img
                            className="knowledge-base-features-image"
                            src={knowledgeBaseImages.knowledge_base_img_3}
                            alt={t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_2.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="knowledge-base-features-left">
                        <Typography variant="h3">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_2.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_2.DESCRIPTION")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className="knowledge-base-features-container">
                <Grid container spacing={4} className="knowledge-base-features-grid-container">
                    <Grid item xs={12} md={5} className="knowledge-base-features-left">
                        <Typography variant="h3">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_3.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_3.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} className="knowledge-base-features-image-container">
                        <img
                            className="knowledge-base-features-image"
                            src={knowledgeBaseImages.knowledge_base_img_4}
                            alt={t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_3.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="knowledge-base-features-container">
                <Grid container spacing={4} className="knowledge-base-features-grid-container">
                    <Grid item xs={12} md={7} className="knowledge-base-features-image-container">
                        <img
                            className="knowledge-base-features-image"
                            src={knowledgeBaseImages.knowledge_base_img_5}
                            alt={t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_4.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="knowledge-base-features-left">
                        <Typography variant="h3">
                        {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_4.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                        {t("KNOWLEDGE_BASE_PAGE.FEATURES_SECTION.FEATURE_4.DESCRIPTION")}                  
                            </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Features;
