// --- Third Party Libraries ---
import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// --- Internal Components ---
import Logomark from "../../assets/img/Logomark.png"

const MAIN_APP_URL = process.env.REACT_APP_PRODUCTION_DOMAIN_ATOM_UI;

const FramedContextWithLogo = (title, subtitle, callToAction) => {
    const { t } = useTranslation();
    return (
        <div className="boostClientChats-section">
            <Grid container spacing={2}>
                <Grid className="boostClientChats-left-content" item xs={12} md={5} lg={5}>
                    <Typography className="boostClientChats-title" variant="h2">
                        {title}
                    </Typography>
                    <Typography className="boostClientChats-subtitle" variant="body2">{subtitle}</Typography>
                    {callToAction && (
                        <Button className="boostClientChats-try-for-free-button" onClick={() => window.open(MAIN_APP_URL, '_self', 'rel=noopener noreferrer')} variant="contained">
                            {t("landingHeader.boostClientChatsTryForFreeButton")}</Button>
                    )}
                </Grid>
                <Grid className="boostClientChats-right-content" item xs={12} md={7} lg={7}>
                    <img src={Logomark} alt="Logomark" className="boostClientChats-logomark-image" />
                </Grid>
            </Grid>
        </div>
    )
}

// --- BoostClientChats Component ---
const BoostClientChats = () => {
    const { t } = useTranslation();

    // Render BoostClientChats Component
    return (
        <>
            {FramedContextWithLogo(t("landingHeader.framedContextWithLogoTitle"), t("landingHeader.framedContextWithLogoSubtitle"), true)}
        </>
    );
}

export default BoostClientChats;
export {
    FramedContextWithLogo
}