import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Link } from "@mui/material";
import { AiOutlineCopyright } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import logo from "../../assets/img/horizontal-logo-for-black-background.png";
import {
  SIGN_UP_URL,
  GET_DEMO_URL,
} from "./../../utils/constants";

import "./index.css";

const Footer = () => {
  const [isProductsExpanded, setIsProductsExpanded] = useState(false);
  const [isSolutionsExpanded, setIsSolutionsExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const { t } = useTranslation();

  const toggleProducts = () => {
    setIsProductsExpanded(prev => !prev);
    if (!isProductsExpanded) setIsSolutionsExpanded(false);
  };

  const toggleSolutions = () => {
    setIsSolutionsExpanded(prev => !prev);
    if (!isSolutionsExpanded) setIsProductsExpanded(false);
  };

  const other = [
    {
      key: "PRICING",
      path: "/pricing"
    },
    {
      key: "ABOUT_US",
      path: "/about"
    },
    {
      key: "BLOG",
      path: "/blog"
    },
  ]
  const solutions = [
    {
      key: "HELPCRUNCH_ALTERNATIVES",
      path: "/blog/helpcrunch-alternatives"
    },
    {
      key: "LIVEPERSON_ALTERNATIVES",
      path: "/blog/liveperson-competitors"
    },
    {
      key: "CHATBOT_ALTERNATIVES",
      path: "/blog/chatbot-alternatives"
    },
    {
      key: "ZENDESK_ALTERNATIVES",
      path: "/blog/zendesk-alternative"
    },
    {
      key: "TIDIO_ALTERNATIVES",
      path: "/blog/tidio-alternative"
    },
    {
      key: "FRESHWORKS_ALTERNATIVES",
      path: "/blog/freshworks-alternative"
    },
    {
      key: "INTERCOM_ALTERNATIVES",
      path: "/blog/intercom-alternatives"
    },
  ]
  const products = [
    {
      key: "SWIFT_AI_COPILOT",
      path: "/ai-copilot"
    },
    {
      key: "AI_CUSTOMER_SERVICE_AGENT",
      path: "/ai-agents"
    },
    {
      key: "LIVE_CHAT",
      path: "/ai-helpdesk"
    },
    {
      key: "WORKFLOWS",
      path: "/workflow"
    },
    {
      key: "AI_KNOWLEDGE_BASE",
      path: "/ai-knowledge-base"
    }
  ]
  return (
    <Box className="footer-section">
      <Grid container spacing={4} className="footer-content">
        <Grid item xs={12} md={3}>
          <img src={logo} alt="Logo" className="footer-logo" />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box className="footer-column">
            {isMobile ? (
              <>
                <Typography variant="h6" onClick={toggleSolutions} sx={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "space-between" }}>
                  {t("FOOTER_COMPONENT.COMPARE_SOLUTIONS")}
                  <IoIosArrowDown style={{
                    transform: isSolutionsExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }} />
                </Typography>
                {isSolutionsExpanded && (
                  <Box className="footer-links-group">
                    {solutions.map(el => (
                      <Link key={el.key} href={el.path}>{t(`FOOTER_COMPONENT.LINKS.${el.key}`)}</Link>
                    ))}
                  </Box>
                )}
              </>
            ) : (
              <>
                <Typography variant="h6">{t("FOOTER_COMPONENT.COMPARE_SOLUTIONS")}</Typography>
                <Box className="footer-links-group">
                  {solutions.map(el => (
                    <Link key={el.key} href={el.path}>{t(`FOOTER_COMPONENT.LINKS.${el.key}`)}</Link>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box className="footer-column">
            {isMobile ? (
              <>
                <Typography variant="h6" onClick={toggleProducts} sx={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "space-between" }}>
                  {t("FOOTER_COMPONENT.PRODUCTS")}
                  <IoIosArrowDown style={{
                    transform: isProductsExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }} />
                </Typography>
                {isProductsExpanded && (
                  <Box className="footer-links-group">
                    {products.map(el => (
                      <Link key={el.key} href={el.path}>{t(`FOOTER_COMPONENT.PRODUCT_NAMES.${el.key}`)}</Link>
                    ))}
                  </Box>
                )}
              </>
            ) : (
              <>
                <Typography variant="h6">{t("FOOTER_COMPONENT.PRODUCTS")}</Typography>
                <Box className="footer-links-group">
                  {products.map(el => (
                    <Link key={el.key} href={el.path}>{t(`FOOTER_COMPONENT.PRODUCT_NAMES.${el.key}`)}</Link>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box className="footer-column">
            {/* <Typography variant="h6" gutterBottom>{t("FOOTER_COMPONENT.USE_CASES")}</Typography>
            {["CUSTOMER_SERVICE_SOLUTIONS", "CUSTOMER_SUPPORT_BPO", "SAAS_SOLUTIONS"].map((key) => (
              <Link key={key} href="#">{t(`FOOTER_COMPONENT.LINKS.${key}`)}</Link>
            ))} */}
            <Box className="footer-links-group">
              {other.map(el => (
                <Link key={el.key} href={el.path}>{t(`FOOTER_COMPONENT.LINKS.${el.key}`)}</Link>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="footer-contact">
          <Box className="footer-column">
            <Typography variant="h6">{t("FOOTER_COMPONENT.CONTACT_US")}</Typography>
            <Link href="#">support@intelswift.com</Link>
            {/* <Link href="#">LinkedIn</Link> */}
            <Button variant="outlined" className="footer-demo-button" component="a" href={GET_DEMO_URL} target="_blank">
              {t("COMMON.BOOK_DEMO_BUTTON")}
            </Button>
            <Button variant="contained" className="footer-trial-button" component="a" href={SIGN_UP_URL} target="_blank">
              {t("COMMON.START_TRIAL_BUTTON")}
            </Button>
            <Typography variant="caption">{t("FOOTER_COMPONENT.CREDIT_CARD_INFO")}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" className="footer-bottom">
        <Grid item>
          <Link href="https://intelswift.com/privacy-policy" target="_blank" className="footer-bottom-left">{t("FOOTER_COMPONENT.LINKS.PRIVACY_POLICY")}</Link>
          <Link href="https://intelswift.com/terms-and-conditions" target="_blank" sx={{ ml: 2 }} className="footer-bottom-left">{t("FOOTER_COMPONENT.LINKS.TERMS_OF_SERVICE")}</Link>
        </Grid>
        <Grid item>
          <Typography className="footer-rights">
            <AiOutlineCopyright /> {t("FOOTER_COMPONENT.COPYRIGHT")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
