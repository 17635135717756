import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    useMediaQuery,
    Stack,
    Button,
    Collapse,
    TextField,
    InputAdornment
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { IoIosCheckmarkCircle } from "react-icons/io";
import PlansComparisonTable from "./comparison";
import { plansData, featureLabels } from "./comparison";
import CustomSwitch from "../../components/CustomSwitch";
import { calculateVolumePricing } from "./../../utils/common"
import { SIGN_UP_URL } from "../../utils/constants";

export default function PlansCard({ plans, couponOff, discBusinessMonthlyPrice }) {
    const navigate = useNavigate();
    const [planType, setPlanType] = useState("Monthly");
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [showCards, setShowCards] = useState(true);
    const [showPriceHeader, setShowPriceHeader] = useState(false);
    const isMediumScreenOrSmaller = useMediaQuery("(max-width:960px)");
    const [cardOpenState, setCardOpenState] = useState({});
    const [cardHeaderOpenState, setHeaderCardOpenState] = useState({});
    const [seats, setSeats] = useState(1);
    const [assistants, setAssistants] = useState(1);
    const { t } = useTranslation();

    const [conversations, setConversations] = useState([
        {
            plan: "Personal",
            conversations: 200,
            valid: true,
        },
        {
            plan: "Pro",
            conversations: 500,
            valid: true,
        },
        {
            plan: "Business",
            conversations: 1000,
            valid: true,
        }
    ])

    const creditsOptions = [
        {
            value: 2000,
            label: '2000'
        },
        {
            value: 3000,
            label: '3000'
        },
        {
            value: 4000,
            label: '4000'
        },
        {
            value: 5000,
            label: '5000'
        },
        {
            value: 6000,
            label: '6000'
        },
        {
            value: 7000,
            label: '7000'
        },
        {
            value: 8000,
            label: '8000'
        },
        {
            value: 9000,
            label: '9000'
        },
        {
            value: 10000,
            label: '10000'
        }
    ]
    const handleSeatsChange = (event) => {
        // Use regular expression to allow only digits
        const newValue = event.target.value.replace(/[^0-9]/g, '');
        setSeats(newValue);
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 50) {
            setShowStickyHeader(true);
            setShowCards(false);
            setCardOpenState(false)
            setTimeout(() => {
                setShowPriceHeader(true);
            }, 200);
        } else {
            setShowStickyHeader(false);
            setHeaderCardOpenState(false);
            setShowCards(true);
            setShowPriceHeader(false);
        }
    };

    useEffect(() => {
        if (!isMediumScreenOrSmaller) {
            window.addEventListener("scroll", handleScroll);
        } else {
            setShowStickyHeader(false);
            setShowCards(true);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isMediumScreenOrSmaller]);

    const toggleCard = (id) => {
        setCardOpenState((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const toggleHeaderCard = (id) => {
        setHeaderCardOpenState((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const getFilteredPlans = () => {
        const isAnnual = planType === "Annual";
        return plans.filter((plan) => {
            // Always include the "Free" plan
            if (plan.name.toLowerCase() === "free") {
                return true;
            }
            if (isAnnual) {
                return plan.name.toLowerCase().includes("annual");
            }
            return !plan.name.toLowerCase().includes("annual");
        });
    };

    const calcPlanName = (name) => name.replace("annual", "").trim();

    const calcPlanPrice = (plan, conversations) => {
        const conversationsMeta = conversations.find((el) =>
            cleanPlanName(plan.name).toLowerCase() === el.plan.toLowerCase()
        );

        const conversatiosInPlan = Number(plan.metadata?.conversations_credit) || 0;
        const seatsTotal = seats > 1 ? Number(seats - 1) * (planType === "Annual" ? 120 : 10) : 0;
        const extra = (conversationsMeta?.conversations || 0) - conversatiosInPlan;
        const planPrice = Number(plan.name.toLowerCase() === 'business' && discBusinessMonthlyPrice ? discBusinessMonthlyPrice : plan.price);
        console.log("conversationsMeta?.conversations ", conversationsMeta?.conversations)
        console.log("conversatiosInPlan ", conversatiosInPlan)
        console.log("planPrice ", planPrice);
        console.log("extra ", extra);
        const { unitPrice, discountPercent, totalAmount } = calculateVolumePricing(extra);
        let conversationsPrice = totalAmount || 0;
        const grandTotal = Math.floor(Number(planPrice) + Number(conversationsPrice));

        return {
            totalPrice: Number(grandTotal) + Number(seatsTotal),
            seatsPrice: seatsTotal,
            conversationsBreakdown: {
                included: conversatiosInPlan,
                extra: extra,
                unitPrice: unitPrice,
                discount: discountPercent,
                total: conversationsPrice,
            },
            planBreakdown: {
                originalPrice: planType === "Annual" ? Number(plan.price / 10) * 12 : plan.price,
                discount: plan.name.toLowerCase() === 'business' && couponOff && couponOff.percent_off ? couponOff.percent_off : planType === "Annual" ? 20 : 0,
                total: planPrice,
            },
        };
    };

    const getPlanOptions = (plan) => {
        const initialConversations = {
            Personal: 200,
            Pro: 500,
            Business: 1000,
        };

        const planConversation = conversations.find((el) => cleanPlanName(plan.name) === el.plan)?.conversations;

        const metadataCredit = Number(plan.metadata?.conversations_credit) || 0;

        const combinedOptions = [
            ...(metadataCredit ? [{ value: metadataCredit, label: String(metadataCredit) }] : []),
            ...(initialConversations[cleanPlanName(plan.name)]
                ? [{ value: initialConversations[cleanPlanName(plan.name)], label: String(initialConversations[cleanPlanName(plan.name)]) }]
                : []),
            ...(planConversation
                ? [{ value: planConversation, label: String(planConversation) }]
                : []),
            ...creditsOptions,
        ];

        const uniqueOptions = combinedOptions.reduce((acc, option) => {
            if (!acc.find((item) => Number(item.value) === Number(option.value))) {
                acc.push(option);
            }
            return acc;
        }, []);

        return uniqueOptions.sort((a, b) => Number(a.value) - Number(b.value));
    };

    const cleanPlanName = (name) => {
        return name.replace(/annual|monthly/gi, "").trim();
    };


    const handleChange = (selectedOption, plan) => {
        const input = selectedOption ? selectedOption.value : "";

        setConversations((prev) =>
            prev.map((el) =>
                cleanPlanName(plan.name).toLowerCase() === el.plan.toLowerCase()
                    ? { ...el, conversations: input || null }
                    : el
            )
        );
    };

    const renderPlanFeatures = (planName) => {
        // Clean the plan name to match plansData
        const cleanedPlanName = cleanPlanName(planName);
        // Find the matching plan
        const plan = plansData.find((p) => cleanPlanName(p.plan) === cleanedPlanName);
        if (!plan) {
            console.error("Plan not found for cleanedPlanName:", cleanedPlanName);
            return null;
        }
        // Filter features based on non-empty values
        const filteredFeatures = featureLabels.filter((feature) => {
            const value = plan.features[feature.key];
            return (typeof value === "boolean" && value) || (typeof value === "string" && value.trim() !== "");
        });

        return (
            <Box component="ul" sx={{ paddingLeft: "0px" }}>
                {filteredFeatures.map((feature, index) => (
                    <Box component="li" key={index} sx={{ marginLeft: "16px" }}>
                        <Typography variant="body1">
                            {feature.label}: {typeof plan.features[feature.key] === "boolean" ?
                                (plan.features[feature.key] ? <IoIosCheckmarkCircle /> : "✖") :
                                plan.features[feature.key]}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    };

    const renderPlanCard = (plan, id) => {
        const pricingDetails = calcPlanPrice(plan, conversations);
        const isOpen = cardOpenState[id] || false;
        if (plan.name === "Free") {
            return (
                <Grid item key={`${plan.name}-${id}`} xs={12} sm={12} md={12} lg={3.0} className={`plan-card ${showCards ? "visible" : "hidden"}`}>
                    <Stack direction="column" spacing={2} sx={{ marginTop: "32px" }}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h4">
                                {t("PLANS.AI_FIRST_CX")}
                            </Typography>
                            <Box className="plan-note">
                                <Typography variant="body1">
                                    {t("PLANS.TRY_FREE_NO_CC")}
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <TextField
                                variant="outlined"
                                value={seats}
                                className="seats-input"
                                onChange={handleSeatsChange}
                                type="number"  // Using number type for the input field
                                inputProps={{
                                    min: 1, // Set minimum value to 1
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                height: "100%",

                                                backgroundColor: '#6257FF', // Background color of the adornment
                                                borderTopRightRadius: '8px', // Rounded corners for the adornment
                                                borderBottomRightRadius: '8px',
                                                maxHeight: "unset",
                                                width: "160px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                '& .MuiTypography-body1': {
                                                    color: 'white',
                                                    textAlign: "center"
                                                }
                                            }}>
                                            {t("PLANS.TEAM_SEATS")}
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '200px',
                                    borderRadius: '8px',
                                    '& .MuiOutlinedInput-root': {
                                        height: "40px",
                                        borderRadius: '8px',
                                        backgroundColor: 'white', // Background of the input part
                                        paddingRight: "0",
                                        display: "flex",
                                        justifyContent: "space-between"

                                    },
                                    '& .MuiInputBase-input': {
                                        backgroundColor: 'white', // Ensures the input field has a white background
                                    },
                                    // Make sure the adornment stays aligned properly
                                    '& .MuiInputBase-inputAdornedEnd': {
                                        backgroundColor: 'white',
                                        borderTopLeftRadius: '8px', // Rounded left corners for the input
                                        borderBottomLeftRadius: '8px',
                                        padding: "0 16px",
                                        width: "50px"
                                    },
                                }}
                            />
                            {/* <Stack direction="row" className="plan-switch-container" sx={{
                                borderRadius: "8px",
                                height: "40px",
                                border: "1px solid lightgrey",
                                width: "200px",
                                alignItems: "center"
                            }}>
                                <Box
                                    className="plan-switch"
                                >
                                    <CustomSwitch
                                        checked={planType === "Annual"}
                                        onChange={(e) => setPlanType(e.target.checked ? "Annual" : "Monthly")}
                                        name="planType"
                                    />
                                </Box>
                                <Box className="plan-switch-label">
                                    <Typography className="switch-container-label" variant="subtitle1">Billed annually</Typography>
                                </Box>
                            </Stack> */}

                        </Stack>
                    </Stack>
                </Grid>
            )
        }
        return (
            <Grid item key={`${plan.name}-${id}`} xs={12} sm={12} md={12} lg={3.0} className={`plan-card ${showCards ? "visible" : "hidden"}`}>
                <Card key={plan.name} variant="gradient" className={`card-plan card-plan-${id}`}>
                    <div className={`plan-header-gradient-${id}`}></div>
                    <div className="plan-header">
                        <CardHeader
                            title={
                                <>
                                    <Typography variant="h5" className={`plan-name plan-name-${id}`}>
                                    {t(`PLANS.PLAN_NAME_${calcPlanName(plan.name).toUpperCase()}`)}
                                    </Typography>
                                    <Typography variant="subtitle1" className="plan-price">
                                        ${pricingDetails.totalPrice}
                                        <span className="plan-month">
                                        {t("PLANS.PER")} {planType === "Annual" ? t("PLANS.PER_YEAR_NO_SLASH") : t("PLANS.PER_MONTH_NO_SLASH")}
                                        </span>
                                    </Typography>
                                </>
                            }
                        />
                        <Box className="plan-subtitle-wrapper">
                            <Typography variant="subtitle2" className="plan-subtitle">
                                {plan.name.includes("Personal") && t("PLANS.PERSONAL_DESCRIPTION")}
                                {plan.name.includes("Pro") && t("PLANS.PRO_DESCRIPTION")}
                                {plan.name.includes("Business") && t("PLANS.BUSINESS_DESCRIPTION")}
                            </Typography>
                        </Box>
                        {plan.name.toLowerCase() !== "free" && (
                            <Stack sx={{ alignItems: "center", marginBottom: "10px" }}>
                                <CreatableSelect
                                    placeholder="Select conversations"
                                    options={getPlanOptions(plan)}
                                    onChange={(selectedOption) => handleChange(selectedOption, plan)}
                                    value={{
                                        value: conversations.find((el) => el.plan.toLowerCase() === cleanPlanName(plan.name).toLowerCase())?.conversations || "",
                                        label: conversations.find((el) => el.plan.toLowerCase() === cleanPlanName(plan.name).toLowerCase())?.conversations || "",
                                    }}
                                    formatOptionLabel={({ label }, { context }) =>
                                        context === "value" ? (
                                            <>
                                                <span>{t("PLANS.CONVERSATIONS")}: </span>
                                                <span>{label}</span>
                                                <span>{planType === "Annual" ? t("PLANS.PER_YEAR") : t("PLANS.PER_MONTH")}</span>
                                            </>
                                        ) : (
                                            label
                                        )
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: "black",
                                        }),
                                        indicatorSeparator: () => ({ display: "none" }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                            padding: "18px",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: provided.borderColor,
                                            borderRadius: "8px",
                                            "&:hover": {
                                                borderColor: provided.borderColor,
                                            },
                                        }),
                                    }}
                                    isDisabled={false}
                                />
                                <Stack direction="column" spacing={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            const url = window.location.hostname.includes("stage.intelswift.com") ? "https://stage-app.intelswift.com" : "https://app.intelswift.com"
                                            window.open(url, "_blank");
                                        }}
                                    >
                                        {t("PLANS.START_TRIAL")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            window.open("https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo", "_blank");
                                        }}
                                    >
                                        {t("PLANS.GET_DEMO")}
                                    </Button>
                                    <Button
                                        variant="text"
                                        onClick={() => toggleCard(id)}
                                    >
                                        {isOpen ? t("PLANS.HIDE_BREAKDOWN") : t("PLANS.SHOW_BREAKDOWN")}
                                    </Button>
                                </Stack>
                                <Collapse timeout="auto" unmountOnExit in={isOpen} sx={{ width: "100%" }}>
                                    <Stack direction="column" spacing={2}>
                                        {planType === "Annual" && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.BREAKDOWN_PLAN")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ORIGINAL_PRICE")} ${pricingDetails.planBreakdown.originalPrice}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ANNUAL_DISCOUNT")} <span style={{ fontWeight: "bold" }}>{t("PLANS.DISCOUNT_PERCENT", { percent: pricingDetails.planBreakdown.discount })}</span>
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.SUBSCRIPTION_PRICE")} ${pricingDetails.planBreakdown.total}
                                                </Typography>
                                            </Stack>
                                        )}
                                        {planType !== "Annual" && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.BREAKDOWN_PLAN")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.SUBSCRIPTION_PRICE")} ${pricingDetails.planBreakdown.total}
                                                </Typography>
                                            </Stack>
                                        )}
                                        {seats > 1 && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.TEAM_SEATS")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.INCLUDED_IN_PLAN_1")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ADDITIONAL_PURCHASED")} {Number(seats - 1)}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.PRICE_PER_SEAT")} {planType === "Annual" ? "$120" : "$10"}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.FINAL_SEAT_PRICE")} ${pricingDetails.seatsPrice}
                                                </Typography>
                                            </Stack>
                                        )}
                                        <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                {t("PLANS.CONVERSATIONS")}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.INCLUDED_IN_PLAN")} {pricingDetails.conversationsBreakdown.included}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.ADDITIONAL_PURCHASED")} {pricingDetails.conversationsBreakdown.extra}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.PRICE_PER_CONVERSATION")} {pricingDetails.conversationsBreakdown.unitPrice}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.VOLUME_DISCOUNT")} <span style={{ fontWeight: "bold" }}>{t("PLANS.DISCOUNT_PERCENT", { percent: pricingDetails.conversationsBreakdown.discount })}</span>
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.FINAL_CONVERSATION_PRICE")} ${pricingDetails.conversationsBreakdown.total}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Collapse>
                            </Stack>
                        )}
                        {isMediumScreenOrSmaller && (
                            <CardContent>
                                {renderPlanFeatures(plan.name)}
                            </CardContent>
                        )}
                    </div>
                </Card>
            </Grid>
        );
    };

    const filteredPlans = getFilteredPlans();

    const renderStickyHeaderPlan = (plan, id) => {
        const pricingDetails = calcPlanPrice(plan, conversations);
        const isOpen = cardHeaderOpenState[id] || false;
        if (plan.name === "Free") {
            return (
                <div className="card-sticky" key={id}>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h4">
                                {t("PLANS.AI_FIRST_CX")}
                            </Typography>
                            <Box className="plan-note">
                                <Typography variant="body1">
                                    {t("PLANS.TRY_FREE_NO_CC")}
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <TextField
                                variant="outlined"
                                value={seats}
                                className="seats-input"
                                onChange={handleSeatsChange}
                                type="number"  // Using number type for the input field
                                inputProps={{
                                    min: 1, // Set minimum value to 1
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                height: "100%",

                                                backgroundColor: '#6257FF', // Background color of the adornment
                                                borderTopRightRadius: '8px', // Rounded corners for the adornment
                                                borderBottomRightRadius: '8px',
                                                maxHeight: "unset",
                                                width: "160px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                '& .MuiTypography-body1': {
                                                    color: 'white',
                                                    textAlign: "center"
                                                }
                                            }}>
                                            {t("PLANS.TEAM_SEATS")}
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '200px',
                                    borderRadius: '8px',
                                    '& .MuiOutlinedInput-root': {
                                        height: "40px",
                                        borderRadius: '8px',
                                        backgroundColor: 'white', // Background of the input part
                                        paddingRight: "0",
                                        display: "flex",
                                        justifyContent: "space-between"

                                    },
                                    '& .MuiInputBase-input': {
                                        backgroundColor: 'white', // Ensures the input field has a white background
                                    },
                                    // Make sure the adornment stays aligned properly
                                    '& .MuiInputBase-inputAdornedEnd': {
                                        backgroundColor: 'white',
                                        borderTopLeftRadius: '8px', // Rounded left corners for the input
                                        borderBottomLeftRadius: '8px',
                                        padding: "0 16px",
                                        width: "50px"
                                    },
                                }}
                            />
                            {/* <Stack direction="row" className="plan-switch-container" sx={{
                                borderRadius: "8px",
                                height: "40px",
                                border: "1px solid lightgrey",
                                width: "200px",
                                alignItems: "center"
                            }}>
                                <Box
                                    className="plan-switch"
                                >
                                    <CustomSwitch
                                        checked={planType === "Annual"}
                                        onChange={(e) => setPlanType(e.target.checked ? "Annual" : "Monthly")}
                                        name="planType"
                                    />
                                </Box>
                                <Box className="plan-switch-label">
                                    <Typography className="switch-container-label" variant="subtitle1">Billed annually</Typography>
                                </Box>
                            </Stack> */}

                        </Stack>
                    </Stack>
                </div>
            )
        }
        return (
            <div className="card-sticky" key={id}>
                <Typography variant="h6">{t(`PLANS.PLAN_NAME_${calcPlanName(plan.name).toUpperCase()}`)}</Typography>
                <div className={`price-header-container ${showPriceHeader ? "visible" : ""}`}>
                    <Typography variant="subtitle1" className="plan-price-header">
                        ${pricingDetails.totalPrice}
                        <span className="plan-month">
                        {t("PLANS.PER")} {planType === "Annual" ? t("PLANS.PER_YEAR_NO_SLASH") : t("PLANS.PER_MONTH_NO_SLASH")}
                        </span>
                    </Typography>
                    {plan.name.toLowerCase() !== "free" && (
                        <Stack sx={{ alignItems: "center", marginBottom: "10px" }}>
                            <CreatableSelect
                                placeholder="Select conversations"
                                options={getPlanOptions(plan)}
                                onChange={(selectedOption) => handleChange(selectedOption, plan)}
                                value={{
                                    value: conversations.find((el) => el.plan.toLowerCase() === cleanPlanName(plan.name).toLowerCase())?.conversations || "",
                                    label: conversations.find((el) => el.plan.toLowerCase() === cleanPlanName(plan.name).toLowerCase())?.conversations || "",
                                }}
                                formatOptionLabel={({ label }, { context }) =>
                                    context === "value" ? (
                                        <>
                                            <span>{t("PLANS.CONVERSATIONS")}: </span>
                                            <span>{label}</span>
                                            <span>{planType === "Annual" ? t("PLANS.PER_YEAR") : t("PLANS.PER_MONTH")}</span>
                                        </>
                                    ) : (
                                        label
                                    )
                                }
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: "black",
                                    }),
                                    indicatorSeparator: () => ({ display: "none" }),
                                    container: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: provided.borderColor,
                                        borderRadius: "8px",
                                        "&:hover": {
                                            borderColor: provided.borderColor,
                                        },
                                    }),
                                }}
                                isDisabled={false}
                            />
                            <Stack direction="column" spacing={1} sx={{ marginTop: "10px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const url = window.location.hostname.includes("stage.intelswift.com") ? "https://stage-app.intelswift.com" : "https://app.intelswift.com"
                                        window.open(url, "_blank");
                                    }}
                                >
                                    {t("PLANS.START_TRIAL")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        window.open("https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo", "_blank");
                                    }}
                                >
                                    {t("PLANS.GET_DEMO")}
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={() => toggleHeaderCard(id)}
                                >
                                    {isOpen ? t("PLANS.HIDE_BREAKDOWN") : t("PLANS.SHOW_BREAKDOWN")}
                                </Button>
                            </Stack>

                            <Collapse
                                in={isOpen}
                                timeout="auto"
                                unmountOnExit
                                sx={{ width: "100%" }}
                            >
                                     <Stack direction="column" spacing={2}>
                                        {planType === "Annual" && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.BREAKDOWN_PLAN")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ORIGINAL_PRICE")} ${pricingDetails.planBreakdown.originalPrice}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ANNUAL_DISCOUNT")} <span style={{ fontWeight: "bold" }}>{t("PLANS.DISCOUNT_PERCENT", { percent: pricingDetails.planBreakdown.discount })}</span>
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.SUBSCRIPTION_PRICE")} ${pricingDetails.planBreakdown.total}
                                                </Typography>
                                            </Stack>
                                        )}
                                        {planType !== "Annual" && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.BREAKDOWN_PLAN")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.SUBSCRIPTION_PRICE")} ${pricingDetails.planBreakdown.total}
                                                </Typography>
                                            </Stack>
                                        )}
                                        {seats > 1 && (
                                            <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                    {t("PLANS.TEAM_SEATS")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.INCLUDED_IN_PLAN_1")}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.ADDITIONAL_PURCHASED")} {Number(seats - 1)}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.PRICE_PER_SEAT")} {planType === "Annual" ? "$120" : "$10"}
                                                </Typography>
                                                <Typography component="li" variant="body1">
                                                    {t("PLANS.FINAL_SEAT_PRICE")} ${pricingDetails.seatsPrice}
                                                </Typography>
                                            </Stack>
                                        )}
                                        <Stack direction="column" spacing={0} sx={{ padding: "18px" }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: "1rem !important" }}>
                                                {t("PLANS.CONVERSATIONS")}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.INCLUDED_IN_PLAN")} {pricingDetails.conversationsBreakdown.included}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.ADDITIONAL_PURCHASED")} {pricingDetails.conversationsBreakdown.extra}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.PRICE_PER_CONVERSATION")} {pricingDetails.conversationsBreakdown.unitPrice}
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.VOLUME_DISCOUNT")} <span style={{ fontWeight: "bold" }}>{t("PLANS.DISCOUNT_PERCENT", { percent: pricingDetails.conversationsBreakdown.discount })}</span>
                                            </Typography>
                                            <Typography component="li" variant="body1">
                                                {t("PLANS.FINAL_CONVERSATION_PRICE")} ${pricingDetails.conversationsBreakdown.total}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                            </Collapse>
                        </Stack>
                    )}
                    <Typography variant="subtitle2" className="plan-subtitle">
                        {plan.name.includes("Free") && "No credit card required! Explore all the features risk-free and see how we can help you succeed"}
                    </Typography>
                </div>
            </div>
        );
    };

    return (
        <Box>
            {/* {renderPricingPlanContainerCard()} */}
            <Grid container spacing={1}>
                {filteredPlans.length > 0 && filteredPlans.map((plan, index) => renderPlanCard(plan, index))}
            </Grid>
            {!isMediumScreenOrSmaller && <PlansComparisonTable />}
            {!isMediumScreenOrSmaller && (
                <div className={`header-sticky ${showStickyHeader ? "visible" : "hidden"}`}>
                    <div className="header-container-sticky">
                        {/* <Box className="header-title-container">
                            <Typography className="header-title" variant="h2">Compare plans</Typography>
                            <Box className="switch-container">
                                <Typography className="switch-container-label" variant="subtitle1">Monthly</Typography>
                                <CustomSwitch
                                    checked={planType === "Annual"}
                                    onChange={(e) => setPlanType(e.target.checked ? "Annual" : "Monthly")}
                                    name="planType"
                                />
                                <Typography className="switch-container-label" variant="subtitle1">Annual -20%</Typography>
                            </Box>
                        </Box> */}
                        <Box display="flex" flexDirection="row">
                            {filteredPlans.slice(0, 4).map((plan, index) => renderStickyHeaderPlan(plan, index))}
                        </Box>
                    </div>
                </div>
            )}
        </Box>
    );
}