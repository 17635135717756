// --- Third Party Libraries ---
import React, { useEffect, useRef } from "react";
import { Typography, Box, Grid, Card, CardContent, CardHeader, CardActions, Avatar, Button } from "@mui/material";
import { IoIosStar } from "react-icons/io";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next"; // Import translation hook
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// --- Image Utilities ---
import { testimonialsImages } from "../../utils/imageUtils";

// --- Constants ---
import {
    PRODUCT_HUNT_URL
  } from "../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

const Testimonials = () => {
    const { t } = useTranslation(); // Initialize translation hook

    // --- Refs for Animations ---
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none reverse",
                once: false
            }
        });

        tl.fromTo(
            sectionRef.current,
            { opacity: 0, y: 80 },
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power1.out"
            }
        ).addLabel("startCards")
            .fromTo(
                cardsRef.current,
                { opacity: 0, y: 60 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    stagger: 0.3,
                    ease: "power1.out"
                },
                "startCards+=0.5"
            );

        return () => {
            tl.kill();
        };
    }, []);

    // --- Testimonials Data ---
    const testimonials = [
        { key: "TESTIMONIAL1", logo: testimonialsImages.apple_logo, avatar: testimonialsImages.testimonial_avatar_1 },
        { key: "TESTIMONIAL2", logo: testimonialsImages.google_logo, avatar: testimonialsImages.testimonial_avatar_2 },
        { key: "TESTIMONIAL3", logo: testimonialsImages.open_ai_logo, avatar: testimonialsImages.testimonial_avatar_3 }
    ];

    // --- Render Component ---
    return (
        <Box ref={sectionRef} className="testimonials-section">
            <Typography variant="h3" align="center" gutterBottom>
                {t("HOME_PAGE.TESTIMONIALS.TITLE")}
            </Typography>
            <Grid container spacing={4}>
                {testimonials.map((testimonial, index) => (
                    <Grid
                        item
                        xs={12} sm={6} md={4}
                        key={testimonial.key}
                        ref={(el) => (cardsRef.current[index] = el)}
                    >
                        <motion.div whileHover={{ scale: 1.05 }}>
                            <Card className="testimonials-card">
                                <CardHeader
                                    className="testimonials-card-header"
                                    avatar={
                                        <img src={testimonial.logo} alt={t(`HOME_PAGE.TESTIMONIALS.${testimonial.key}.LOGO_ALT`)} />
                                    }
                                    subheader={
                                        <div>
                                            {Array.from({ length: 5 }).map((_, i) => (
                                                <IoIosStar key={i} color="gold" />
                                            ))}
                                        </div>
                                    }
                                />
                                <CardContent className="testimonials-card-content">
                                    <Typography variant="body2">
                                        {t(`HOME_PAGE.TESTIMONIALS.${testimonial.key}.TEXT`)}
                                    </Typography>
                                </CardContent>
                                <CardActions className="testimonials-card-actions">
                                    <Avatar src={testimonial.avatar} alt={t(`HOME_PAGE.TESTIMONIALS.${testimonial.key}.AVATAR_ALT`)} />
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Typography variant="body2" color="textSecondary">{t(`HOME_PAGE.TESTIMONIALS.${testimonial.key}.NAME`)}</Typography>
                                        <Typography variant="body1">{t(`HOME_PAGE.TESTIMONIALS.${testimonial.key}.JOB`)}</Typography>
                                    </Box>
                                </CardActions>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
            <Button href={PRODUCT_HUNT_URL} target="_blank" variant="contained">{t("HOME_PAGE.TESTIMONIALS.READ_MORE_BUTTON")}</Button>
        </Box>
    );
}

export default Testimonials;
