import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    SIGN_UP_URL
  } from "./../../utils/constants";
// --- CSS ---
import "./index.css";

const SmoothConversations = () => {
    const { t } = useTranslation();

    return (
        <Box className="smooth-conversations-container">
            <Typography variant="h3">
            {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.TITLE")}
            </Typography>
            <Typography variant="subtitle1">
            {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.DESCRIPTION")}
                        </Typography>
            <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                <Button variant="outlined">
                {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.BUTTON")}
                </Button>
            </Link>
        </Box>
    );
};

export default SmoothConversations;