import React, { useMemo } from "react";
import { Box, Typography, Button, Grid, Card, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { CiSquareCheck } from "react-icons/ci";
import { useTranslation } from "react-i18next";

import {
  SIGN_UP_URL,
  GET_DEMO_URL
} from "./../../utils/constants";

const KnowledgeAutomation = () => {
  const { t } = useTranslation();

  const features = useMemo(() => [
    {
      title: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.SINGLE_SOURCE.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.SINGLE_SOURCE.DESCRIPTION"),
      icon: <BiSupport className="knowledge-card-icon" />,
      bgColor: "rgba(87, 179, 255, 1)"
    },
    {
      title: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.CONTENT_CREATION.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.CONTENT_CREATION.DESCRIPTION"),
      icon: <IoDocumentTextOutline className="knowledge-card-icon" />,
      bgColor: "rgba(148, 87, 255, 1)"
    },
    {
      title: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.SEO_CONTENT.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.FEATURES.SEO_CONTENT.DESCRIPTION"),
      icon: <CiSquareCheck className="knowledge-card-icon" />,
      bgColor: "rgba(223, 119, 156, 1)"
    }
  ], [t]);

  return (
    <Box className="knowledge-section">
      <Grid container spacing={4}>
        <Grid item xs={12} md={5} className="knowledge-left-section">
          <Typography variant="h3">
            {t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.TITLE")}
          </Typography>
          <Typography variant="subtitle1">
            {t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.DESCRIPTION")}
          </Typography>
          <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
            <Button className="demo-button">{t("COMMON.REQUEST_DEMO_BUTTON")}</Button>
          </Link>
        </Grid>
        <Grid item xs={12} md={7} className="knowledge-right-section">
          <Typography variant="subtitle1" className="knowledge-right-section-title">
          {t("KNOWLEDGE_BASE_PAGE.AUTOMATION_SECTION.LIST_TITLE")}
          </Typography>
          {features.map((feature, index) => (
            <Card key={index} elevation={3} className="knowledge-card">
              <Box className="knowledge-card-content">
                <Box
                  className="knowledge-card-circle"
                  style={{ background: feature.bgColor }}
                >
                  {feature.icon}
                </Box>
                <Box className="knowledge-card-text">
                  <Typography variant="h5" className="knowledge-feature-title">
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" className="knowledge-feature-description">
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeAutomation;