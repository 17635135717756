// --- Third Party Libraries ---
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {FramedContextWithLogo} from "../HomePage/BoostClientChats"
import { Box, Typography, Grid, Stack } from "@mui/material";

// --- Companion Component ---
const About = () => {

    return (
        <>
            <Header/>
            <Box className="blog-page-container">
                {FramedContextWithLogo("Every relationship begins with a conversation", "We make it last", false)}
                <Stack className="our-story" direction="column" spacing={2}>
                    <Typography className="liveChat-title gradient-text" variant="h2">
                        <span className="black-text">Our </span> Story
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            
                        </Grid>
                        <Grid item md={8} xs={12} >
                            <Typography variant="body1">
                                After over a decade spent working for tech giants like Apple, Cisco, and Wells Fargo, and teaching CS course at UC Berkeley, our CEO embarked on a mission to bring Silicon Valley innovative spirit to Ukraine. The goal was to empower individuals from different backgrounds to transition into the software development field.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Upon launching our CS courses, we immediately ran into numerous challenges in customer service. Many leads who filled out our contact forms never responded to our follow ups. It's worth mentioning that at times, it took us up to three days to follow up with everyone using just a single sales representative. Hiring and training sales reps proved to be another hurdle. Even with pre-written sales scripts, and 1:1 training our representatives often struggled to provide immediate answers to some IT specific questions. And let's not forget, we were targeting students from multiple countries, so the language barrier became a significant bottleneck.                            
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Soon, this wasn’t anymore just about solving our own problem; it became a higher mission to help businesses automate their customer service and scale without hiring additional workforce. Our platform, born from necessity, transformed into a solution for startups and small businesses to connect with their audience, nurture leads, and ultimately, thrive.                            
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <Footer/>
        </>
    );
}

export default About;