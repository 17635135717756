import React, { useRef } from "react";
import { Box, Typography, Card, CardMedia, CardContent, Button, Grid, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

// --- Image Utilities ---
import { blogHeaderImages } from "../../utils/imageUtils";

// --- Blog Slugs Mapping ---
const blogSlugs = {
    1: "ai-sales",
    2: "ai-customer-service-2025",
    3: "chatgpt-vs-gemini",
    4: "intercom-alternatives",
    5: "best-customer-service-automation-software",
    6: "freshworks-alternative",
    7: "zendesk-alternative",
    8: "tidio-alternative",
    9: "helpcrunch-alternatives",
    10: "liveperson-competitors",
    11: "chatbot-alternatives"
};

gsap.registerPlugin(ScrollTrigger);

// --- Blog Component ---
const Blog = (isLiveChatPage = false) => {
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);
    const buttonsRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none reverse",
                once: false,
            },
        });

        tl.fromTo(
            sectionRef.current,
            { opacity: 0, y: 0 },
            {
                opacity: 1,
                y: 0,
                duration: 1.0,
                ease: "power1.out",
            }
        ).addLabel("startCards")
            .fromTo(
                cardsRef.current,
                { opacity: 0, y: 30 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    stagger: 0.2,
                    ease: "power1.out",
                },
                "startCards+=0"
            )
            .fromTo(
                buttonsRef.current,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: "power1.out",
                },
                "+=0.4"
            );
    }, { scope: sectionRef });

    // Articles array
    const articles = useMemo(
        () => t("BLOG_PAGE.ARTICLES", { returnObjects: true }),
        [t]
    );

    const latestThreeArticles = useMemo(() => {
        return Object.entries(articles)
            .sort(([a], [b]) => {
                const aNum = parseInt(a.replace("ARTICLE_", ""));
                const bNum = parseInt(b.replace("ARTICLE_", ""));
                return bNum - aNum; // Descending
            })
            .slice(0, 3);
    }, [articles]);
    console.log("latestThreeArticles", latestThreeArticles)

    return (
        <Box className={`blog-section ${isLiveChatPage ? "blog-section-live-chat-background" : ""}`} ref={sectionRef}>
            <Typography variant="h3">
                {t("BLOG_PAGE.MAIN_TITLE")}
            </Typography>
            <Typography variant="subtitle1">
                {t("BLOG_PAGE.SUBTITLE")}
            </Typography>
            <Grid container spacing={4}>
                {latestThreeArticles.map(([id, article], index) => {
                    const numericId = id.replace("ARTICLE_", "");
                    return (
                        <Grid item xs={12} sm={6} md={4} key={id} ref={(el) => (cardsRef.current[index] = el)}>
                            <Card className="blog-card" onClick={() => navigate(`/blog/${blogSlugs[numericId]}`)}>
                                <CardMedia
                                    component="img"
                                    src={blogHeaderImages[article.CARD_MEDIA]}
                                    alt={article.TITLE}
                                />
                                <CardContent className="blog-content">
                                    <Chip label={article.TYPE} />
                                    <Typography variant="subtitle1">{article.TITLE}</Typography>
                                    <Typography variant="body1">{article.SUBTITLE}</Typography>
                                    <Typography variant="caption">{article.DATE}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
            <Button 
            ref={buttonsRef} 
            onClick={() => {
                navigate("/blog")
            }}>
                {t("BLOG_PAGE.BLOG_BUTTON")}
            </Button>
        </Box>
    );
};

export default Blog;
