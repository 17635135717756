// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

// --- Image Utilities ---
import { workflowsImages } from "../../utils/imageUtils";
import {
    SIGN_UP_URL,
    GET_DEMO_URL
} from "./../../utils/constants";

// --- Features Component ---
const Features = () => {
    const { t } = useTranslation();

    return (
        <Box className="workflows-features-section">
            <Typography variant="h3" className="workflows-features-section-title">
                {t("WORKFLOWS_PAGE.FEATURES_SECTION.TITLE")}
            </Typography>
            <Typography variant="subtitle1" className="workflows-features-section-subtitle">
                {t("WORKFLOWS_PAGE.FEATURES_SECTION.SUBTITLE")}
            </Typography>
            <Box className="workflows-features-container">
                <Grid container spacing={4} className="workflows-features-grid-container">
                    <Grid item xs={12} md={5} className="workflows-features-left">
                        <Typography variant="h3">
                            {t("WORKFLOWS_PAGE.FEATURES_SECTION.READY_TEMPLATES.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("WORKFLOWS_PAGE.FEATURES_SECTION.READY_TEMPLATES.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} className="workflows-features-image-container">
                        <img
                            className="workflows-features-image"
                            src={workflowsImages.workflows_img_2}
                            alt={t("WORKFLOWS_PAGE.FEATURES_SECTION.READY_TEMPLATES.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="workflows-features-container">
                <Grid container spacing={4} className="workflows-features-grid-container">
                    <Grid item xs={12} md={7} className="workflows-features-image-container">
                        <img
                            className="workflows-features-image"
                            src={workflowsImages.workflows_img_3}
                            alt={t("WORKFLOWS_PAGE.FEATURES_SECTION.ESCALATION.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="workflows-features-left">
                        <Typography variant="h3">
                        {t("WORKFLOWS_PAGE.FEATURES_SECTION.ESCALATION.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                        {t("WORKFLOWS_PAGE.FEATURES_SECTION.ESCALATION.DESCRIPTION")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className="workflows-features-container">
                <Grid container spacing={4} className="workflows-features-grid-container">
                    <Grid item xs={12} md={5} className="workflows-features-left">
                        <Typography variant="h3">
                        {t("WORKFLOWS_PAGE.FEATURES_SECTION.VISUAL_BUILDER.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                        {t("WORKFLOWS_PAGE.FEATURES_SECTION.VISUAL_BUILDER.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} className="workflows-features-image-container">
                        <img
                            className="workflows-features-image"
                            src={workflowsImages.workflows_img_4}
                            alt={t("WORKFLOWS_PAGE.FEATURES_SECTION.VISUAL_BUILDER.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Stack direction="row" spacing={2} className="workflows-features-section-stack-buttons">
                <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
                    <Button variant="contained" className="workflows-demo-button">
                        {t("COMMON.REQUEST_DEMO_BUTTON")}
                    </Button>
                </Link>
                <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                    <Button variant="outlined" className="workflows-trial-button">
                        {t("COMMON.START_TRIAL_BUTTON")}
                    </Button>
                </Link>
            </Stack>
        </Box>
    );
};

export default Features;
