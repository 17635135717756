import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GET_DEMO_URL } from "./../../utils/constants";

const Partnerships = () => {
  const { t } = useTranslation();

  return (
    <Box className="partnerships-section">
      <Typography variant="h3">
        {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.TITLE")}
      </Typography>
      <Typography variant="subtitle1">
        {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.SUBTITLE")}
      </Typography>
      <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
        <Button variant="contained" className="live-chat-features-demo-button">
          {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.BUTTON")}
        </Button>
      </Link>
    </Box>
  );
};

export default Partnerships;
