// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import ConversationalAgents from "./ConversationalAgents.jsx";
import VerticalAgents from "./VerticalAgents.jsx";
import Control from "./Control.jsx";
import HireAgents from "./HireAgenst.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- Agents Component ---
export default function Agents() {
  const { t } = useTranslation();

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("AGENTS_PAGE.META.TITLE")}
        description={t("AGENTS_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/ai-agents"
      />
      <Header />
      <HeroSection />
      <ConversationalAgents/>
      <VerticalAgents/>
      <Control/>
      <HireAgents/>
      <Footer />
    </>
  );
}
