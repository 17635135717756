// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Button, Grid, Stack, Box } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";

// --- GSAP Setup ---
gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

// --- Hero Component ---
const Hero = () => {
  // --- State Hooks ---
  const sectionRef = useRef(null);
  const overlayRef = useRef(null);
  const textRef = useRef(null);
  const { t } = useTranslation();

  // --- Animations ---
  useGSAP(() => {
    const text = textRef.current;
    const content = text.innerText;
    text.innerText = "";
    const chars = content.split("");
    chars.forEach((char) => {
      const span = document.createElement("span");
      span.innerText = char;
      text.appendChild(span);
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top",
        scrub: true,
        pin: true,
      },
    });
    tl.fromTo(
      overlayRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    );
    tl.fromTo(
      text.querySelectorAll("span"),
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.02,
        duration: 0.05,
      },
      "-=0.5"
    );
    return () => {
      tl.scrollTrigger?.kill();
      tl.kill();
    };
  }, { scope: sectionRef });

  // --- Render Component ---
  return (
    <Box ref={sectionRef} className="agents-hero-section">
      <Box className="agents-hero-content">
        <Typography variant="h1">
          {t("AGENTS_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="h3">
          {t("AGENTS_PAGE.HERO_SECTION.DESCRIPTION")}
        </Typography>
        <Stack direction="row" spacing={2} className="agents-stack-buttons">
          <Button
            className="conversational-button"
            onClick={() => document.getElementById("conversational-ai")?.scrollIntoView({ behavior: "smooth" })}
          >
            {t("COMMON.CONVERSATIONAL_BUTTON")}
          </Button>
          <Button
            className="vertical-button"
            onClick={() => document.getElementById("vertical-ai")?.scrollIntoView({ behavior: "smooth" })}
          >
            {t("COMMON.VERTICAL_BUTTON")}
          </Button>
        </Stack>
      </Box>
      <Box
        ref={overlayRef}
        className="black-overlay"
      >
        <Typography variant="h2" ref={textRef}>
          {t("AGENTS_PAGE.HERO_SECTION.OVERLAY_TEXT")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;

// return (
//   <Box className="agents-hero-section">
//     <Box className="agents-hero-content">
//       <Typography variant="h1">AI Agents</Typography>
//       <Typography variant="h3">Proactive AI to automate your entire business space</Typography>
//       <Stack direction="row" spacing={2}>
//         <Button
//           className="conversational-button"
//           onClick={() => document.getElementById("conversational-ai")?.scrollIntoView({ behavior: "smooth" })}
//         >
//           Conversational AI
//         </Button>
//         <Button
//           className="vertical-button"
//           onClick={() => document.getElementById("vertical-ai")?.scrollIntoView({ behavior: "smooth" })}
//         >
//           Vertical AI
//         </Button>
//       </Stack>
//       <br />
//       <Typography variant="h2" sx={{
//         maxWidth: "700px"
//       }}>
//         Automate all verticals of your business from customer communication to proactive analysis of your team performance, churn, customer satisfaction and beyond
//       </Typography>
//     </Box>
//   </Box>
// );