// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Card } from "@mui/material";
import { useTranslation } from "react-i18next"; 
import { Link } from "react-router-dom";
import { BsBoxArrowInRight, BsGraphUpArrow } from "react-icons/bs";
import { PiCurrencyCircleDollarLight } from "react-icons/pi";

// --- Steps Component ---
const Steps = () => {
    const { t } = useTranslation(); 

    const features = [
        {
            key: "JOIN",
            icon: <BsBoxArrowInRight className="steps-icon" />,
            bgColor: "rgba(64, 117, 221, 1)"
        },
        {
            key: "PROMOTE",
            icon: <BsGraphUpArrow className="steps-icon" />,
            bgColor: "rgba(148, 87, 255, 1)"
        },
        {
            key: "GET_PAID",
            icon: <PiCurrencyCircleDollarLight className="steps-icon" />,
            bgColor: "rgba(129, 201, 109, 1)"
        }
    ];

    return (
        <Box className="steps-container">
            <Typography variant="h3">
                {t("PARTNER_PAGE.STEPS.TITLE")}
            </Typography>
            <Grid container spacing={4}>
                {features.map((feature, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="steps-card"
                    >
                        <Card className="steps-card-content">
                            <Box
                                className="steps-circle"
                                style={{ background: feature.bgColor }}
                            >
                                {feature.icon}
                            </Box>
                            <Typography variant="h5" className="steps-title">
                                {t(`PARTNER_PAGE.STEPS.${feature.key}.TITLE`)}
                            </Typography>
                            <Typography variant="body1" className="steps-description">
                                {feature.key === "JOIN" ? (
                                    <>
                                       <Link to="https://intelswift.firstpromoter.com" target="_blank" className="steps-signup-link">
                                            {t("PARTNER_PAGE.STEPS.JOIN.SIGN_UP")}
                                        </Link>
                                        {" "}{t("PARTNER_PAGE.STEPS.JOIN.DESCRIPTION")}
                                    </>
                                ) : (
                                     t(`PARTNER_PAGE.STEPS.${feature.key}.DESCRIPTION`)
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Steps;
