import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { liveChatImages } from "../../utils/imageUtils";

const Channels = () => {
  const { t } = useTranslation();

  return (
    <Box className="channels-section">
      <Grid container spacing={4} className="channels-section-grid-container">
        <Grid item xs={12} md={6} className="channels-section-left">
          <Typography variant="h3">
            {t("LIVE_CHAT_PAGE.CHANNELS_SECTION.TITLE")}
          </Typography>
          <Typography variant="subtitle1">
            {t("LIVE_CHAT_PAGE.CHANNELS_SECTION.DESCRIPTION")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="channels-section-image-container">
          <img
            className="channels-section-image"
            src={liveChatImages.liveChat_img_6}
            alt={t("LIVE_CHAT_PAGE.CHANNELS_SECTION.ALT")} 
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Channels;
