import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Stack, Grid, Tooltip } from "@mui/material";
import { IoIosCheckmarkCircle } from "react-icons/io";
import i18next from "i18next";

export const plansData = [
    {
        plan: "Personal",
        price: "$19",
        features: {
            aiAssistants: true,
            total: "1",
            llms: "GPT",
            knowledgeBase: "400K characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "4 segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: false,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: false,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: false,
            externalData: false,
            coPilot: false,
            knowledgeBaseAI: "",
            summaries: false,
            dataPredictions: false,
            integrations: false,
            totalActive: "",
            team: false,
            rolesPermissions: false,
            supports: true,
            slack: true,
            live: false
        }
    },
    {
        plan: "Pro",
        price: "$49",
        features: {
            aiAssistants: true,
            total: "2",
            llms: "Gemini, GPT, Llama",
            knowledgeBase: "2M characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "6 segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: true,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: false,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: true,
            externalData: false,
            coPilot: true,
            knowledgeBaseAI: "400 characters",
            summaries: false,
            dataPredictions: false,
            integrations: true,
            totalActive: 5,
            team: true,
            rolesPermissions: false,
            supports: true,
            slack: true,
            live: false
        }
    },
    {
        plan: "Business",
        price: "$125",
        features: {
            aiAssistants: true,
            total: "5",
            llms: "Gemini, GPT, Llama",
            knowledgeBase: "10M characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "Unlimited segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: true,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: true,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: true,
            externalData: true,
            coPilot: true,
            knowledgeBaseAI: "2M characters",
            summaries: true,
            dataPredictions: true,
            integrations: true,
            totalActive: "Unlimited",
            team: true,
            rolesPermissions: true,
            supports: true,
            slack: true,
            live: true
        }
    }
];

export const products = [
    {
      name: i18next.t("PLANS.HELPDESK"),
      description: i18next.t("PLANS.HELP_DESK_DESCRIPTION"),
      personal: {
        features: {
          shared_inbox: true,
          basic_chatbots_and_automations: true,
          ai_compose: true,
          ticketing_system: true,
          workflows_for_advanced_automation: false,
          multiple_help_centers: false,
          articles_and_collections: false,
        },
        coming_soon: {
          
        },
      },
      pro: {
        features: {
          shared_inbox: true,
          basic_chatbots_and_automations: true,
          ai_compose: true,
          ticketing_system: true,
          workflows_for_advanced_automation: true,
          multiple_help_centers: false,
          articles_and_collections: false,
        },
        coming_soon: {
          
        },
      },
      business: {
        features: {
          shared_inbox: true,
          basic_chatbots_and_automations: true,
          ai_compose: true,
          ticketing_system: true,
          workflows_for_advanced_automation: true,
          multiple_help_centers: true,
          articles_and_collections: true,
        },
        coming_soon: {
          
        },
      },
    },
    {
      name: i18next.t("PLANS.AI_AGENTS"),
      description: i18next.t("PLANS.AI_AGENTS_DESCRIPTION"),
      personal: {
        features: {
          llms: ["Google Gemini, OpenAI GPT"],
          total_included: ['1'],
          knowledge_center: true,
          personalized_answers: true,
          multilingual: true,
          human_handoff: true,
        },
        coming_soon: {
          image_replies: false,
        },
      },
      pro: {
        features: {
          llms: ["Google Gemini, OpenAI GPT"],
          total_included: ['2'],
          knowledge_center: true,
          personalized_answers: true,
          multilingual: true,
          human_handoff: true,
        },
        coming_soon: {
          image_replies: true,
        },
      },
      business: {
        features: {
          llms: ["Google Gemini, OpenAI GPT"],
          total_included: ['3'],
          knowledge_center: true,
          personalized_answers: true,
          multilingual: true,
          human_handoff: true,
        },
        coming_soon: {
          image_replies: true,
        },
      },
    },
    {
      name: i18next.t("PLANS.SWIFT"),
      description: i18next.t("PLANS.SWIFT_DESCRIPTION"),
      personal: {
        features: {
          conversation_history: false,
          multilingual: false,
          knowledge_center: false,
          reporting: false,
        },
        coming_soon: {
          image_replies: false,
        },
      },
      pro: {
        features: {
          conversation_history: true,
          multilingual: true,
          knowledge_center: true,
          reporting: false,
        },
        coming_soon: {
          image_replies: true,
        },
      },
      business: {
        features: {
          conversation_history: true,
          multilingual: true,
          knowledge_center: true,
          reporting: true,
        },
        coming_soon: {
          image_replies: true,
        },
      },
    },
    {
      name: i18next.t("PLANS.CHANNELS"),
      description: i18next.t("PLANS.CHANNELS_DESCRIPTION"),
      personal: {
        features: {
          live_chat: true,
          web: true,
          telegram: true,
          whatsapp: true,
          messanger: true,
        },
        coming_soon: {
          instagram: true,
          inbound_email: false,
        },
      },
      pro: {
        features: {
          live_chat: true,
          web: true,
          telegram: true,
          whatsapp: true,
          messanger: true,
        },
        coming_soon: {
          instagram: true,
          inbound_email: true,
        },
      },
      business: {
        features: {
          live_chat: true,
          web: true,
          telegram: true,
          whatsapp: true,
          messanger: true,
        },
        coming_soon: {
          instagram: true,
          inbound_email: true,
        },
      },
    },
    {
      name: i18next.t("PLANS.PLATFORM"),
      description: i18next.t("PLANS.PLATFORM_DESCRIPTION"),
      personal: {
        features: {
          custom_objects: true,
          report_builder: true,
          pre_built_reports: true,
          custom_reports: false,
          projects: false,
          roles_and_permissions: false,
          
        },
        coming_soon: null,
      },
      pro: {
        features: {
          custom_objects: true,
          report_builder: true,
          pre_built_reports: true,
          custom_reports: true,
          projects: false,
          roles_and_permissions: false,
          
        },
        coming_soon: null,
      },
      business: {
        features: {
          custom_objects: true,
          report_builder: true,
          pre_built_reports: true,
          custom_reports: true,
          projects: true,
          roles_and_permissions: true,
          
        },
        coming_soon: null,
      },
    },
  ];
  
  export const featureLabels = [
    { label: i18next.t("PLANS.SHARED_INBOXES"), key: "shared_inbox" },
    { label: i18next.t("PLANS.SHARED_INBOXES_DESCRIPTION"), key: "shared_inbox_description" },
    { label: i18next.t("PLANS.BASIC_CHATS_AND_AUTOMATIONS"), key: "basic_chatbots_and_automations" },
    { label: i18next.t("PLANS.BASIC_CHATS_AND_AUTOMATIONS_DESCRIPTION"), key: "basic_chatbots_and_automations_description" },
    { label: i18next.t("PLANS.AI_COMPOSE"), key: "ai_compose" },
    { label: i18next.t("PLANS.AI_COMPOSE_DESCRIPTION"), key: "ai_compose_description" },
    { label: i18next.t("PLANS.TICKETING_SYSTEM"), key: "ticketing_system" },
    { label: i18next.t("PLANS.TICKETING_SYSTEM_DESCRIPTION"), key: "ticketing_system_description" },
    { label: i18next.t("PLANS.WORKFLOWS_ADVANCED_AUTOMATION"), key: "workflows_for_advanced_automation" },
    { label: i18next.t("PLANS.WORKFLOWS_ADVANCED_AUTOMATION_DESCRIPTION"), key: "workflows_for_advanced_automation_description" },
    { label: i18next.t("PLANS.MULTIPLE_HELP_CENTERS"), key: "multiple_help_centers" },
    { label: i18next.t("PLANS.MULTIPLE_HELP_CENTERS_DESCRIPTION"), key: "multiple_help_centers_description" },
    { label: i18next.t("PLANS.ARTICLES_AND_COLLECTIONS"), key: "articles_and_collections" },
    { label: i18next.t("PLANS.ARTICLES_AND_COLLECTIONS_DESCRIPTION"), key: "articles_and_collections_description" },
    { label: i18next.t("PLANS.LLMS"), key: "llms" },
    { label: i18next.t("PLANS.LLMS_DESCRIPTION"), key: "llms_description" },
    { label: i18next.t("PLANS.TOTAL_INCLUDED"), key: "total_included" },
    { label: i18next.t("PLANS.TOTAL_INCLUDED_DESCRIPTION"), key: "total_included_description" },
    { label: i18next.t("PLANS.KNOWLEDGE_CENTER"), key: "knowledge_center" },
    { label: i18next.t("PLANS.KNOWLEDGE_CENTER_DESCRIPTION"), key: "knowledge_center_description" },
    { label: i18next.t("PLANS.MULTILINGUAL"), key: "multilingual" },
    { label: i18next.t("PLANS.MULTILINGUAL_DESCRIPTION"), key: "multilingual_description" },
    { label: i18next.t("PLANS.HUMAN_HANDOFF"), key: "human_handoff" },
    { label: i18next.t("PLANS.HUMAN_HANDOFF_DESCRIPTION"), key: "human_handoff_description" },
    { label: i18next.t("PLANS.PERSONILIZED_ANSWERS"), key: "personalized_answers" },
    { label: i18next.t("PLANS.PERSONILIZED_ANSWERS_DESCRIPTION"), key: "personalized_answers_description" },
    { label: i18next.t("PLANS.CONVERSATION_HISTORY"), key: "conversation_history" },
    { label: i18next.t("PLANS.CONVERSATION_HISTORY_DESCRIPTION"), key: "conversation_history_description" },
    { label: i18next.t("PLANS.REPORTING"), key: "reporting" },
    { label: i18next.t("PLANS.REPORTING_DESCRIPTION"), key: "reporting_description" },
    { label: i18next.t("PLANS.IMAGE_REPLIES"), key: "image_replies" },
    { label: i18next.t("PLANS.IMAGE_REPLIES_DESCRIPTION"), key: "image_replies_description" },
    { label: i18next.t("PLANS.LIVE_CHAT"), key: "live_chat" },
    { label: i18next.t("PLANS.WEB"), key: "web" },
    { label: i18next.t("PLANS.TELEGRAM"), key: "telegram" },
    { label: i18next.t("PLANS.WHATSAPP"), key: "whatsapp" },
    { label: i18next.t("PLANS.MESSANGER"), key: "messanger" },
    { label: i18next.t("PLANS.INBOUND_EMAIL"), key: "inbound_email" },
    { label: i18next.t("PLANS.INBOUND_EMAIL_DESCRIPTION"), key: "inbound_email_description" },
    { label: i18next.t("PLANS.INSTAGRAM"), key: "instagram" },
    { label: i18next.t("PLANS.PROJECTS_DESCRIPTION"), key: "projects_description" },
    { label: i18next.t("PLANS.CUSTOM_OBJECTS"), key: "custom_objects" },
    { label: i18next.t("PLANS.CUSTOM_OBJECTS_DESCRIPTION"), key: "custom_objects_description" },
    { label: i18next.t("PLANS.PRE_BUILT_REPORTS"), key: "pre_built_reports" },
    { label: i18next.t("PLANS.REPORT_BUILDER"), key: "report_builder" },
    { label: i18next.t("PLANS.REPORT_BUILDER_DESCRIPTION"), key: "report_builder_description" },
    { label: i18next.t("PLANS.PRE_BUILT_REPORTS_DESCRIPTION"), key: "pre_built_reports_description" },
    { label: i18next.t("PLANS.CUSTOM_REPORTS"), key: "custom_reports" },
    { label: i18next.t("PLANS.CUSTOM_REPORTS_DESCRIPTION"), key: "custom_reports_description" },
    { label: i18next.t("PLANS.ROLES_AND_PERMISSION"), key: "roles_and_permissions" },
    { label: i18next.t("PLANS.ROLES_AND_PERMISSION_DESCRIPTION"), key: "roles_and_permissions_description" },
    { label: i18next.t("PLANS.PROJECTS"), key: "projects" },
  
  ];
  
  const PlansComparisonTable = () => (
    <div>
      {products.map((product) => {
        const { name, description, personal, pro, business } = product;
  
        // Check which features are available in this product
        const productFeatures = Object.keys(personal?.features || {});
        const productComingSoon = Object.keys(personal?.coming_soon || {});
  
        // If there are no features or coming soon items, skip this product
        if (productFeatures.length === 0 && productComingSoon.length === 0) return null;
  
        return (
          <Stack key={name} spacing={2} sx={{marginBottom: "30px"}}>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>{name}</Typography>
            <Typography variant="body1">{description}</Typography>
  
            {/* Features Table */}
            {productFeatures.length > 0 && (
              <TableContainer component={Paper} className="comparison-table">
                <Table aria-label="features table">
                  <TableBody>
                    {featureLabels
                      .filter((label) => productFeatures.includes(label.key))
                      .map((feature) => {
                        const description = featureLabels.find(el => el.key === `${feature.key}_description`)
                        return (
                          <TableRow key={feature.key}>
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={3}>
                                  {description ? (
                                      <Tooltip title={description.label} placement="right">
                                        <span style={{ cursor: "pointer", textDecoration: "underline" }}>
                                          {feature.label}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      feature.label
                                    )}
                                </Grid>
                                {feature.key === "llms" && (
                                  <Grid item xs={9} sx={{textAlign: "center"}}>
                                    <Typography>{pro.features.llms.join(', ')}</Typography>
                                  </Grid>
                                )}
                                {feature.key !== "llms" && (
                                  <>
                                    <Grid item xs={3} sx={{textAlign: "center"}}>
                                      {feature.key === "total_included" && (
                                        <Typography>{personal.features.total_included.join(', ')}</Typography>
                                      )}
                                      {feature.key !== "total_included" && (
                                        <>
                                           {personal.features[feature.key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                              <IoIosCheckmarkCircle />
                                            </Typography> : ""}
                                        </>
                                      )}
                                     
                                    </Grid>
                                    <Grid item xs={3} sx={{textAlign: "center"}}>
                                      {feature.key === "total_included" && (
                                          <Typography>{pro.features.total_included.join(', ')}</Typography>
                                        )}
                                        {feature.key !== "total_included" && (
                                          <>
                                            {pro.features[feature.key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                                <IoIosCheckmarkCircle />
                                              </Typography> : ""}
                                          </>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} sx={{textAlign: "center"}}>
                                      {feature.key === "total_included" && (
                                          <Typography>{business.features.total_included.join(', ')}</Typography>
                                        )}
                                        {feature.key !== "total_included" && (
                                          <>
                                            {business.features[feature.key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                                <IoIosCheckmarkCircle />
                                              </Typography> : ""}
                                          </>
                                        )}
                                    </Grid>
                                  </>
                                )}
                                
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
  
            {/* Coming Soon Table */}
            {productComingSoon.length > 0 && (
              <>
                <Typography variant="body1" sx={{fontWeight: "bold"}}>{i18next.t("PLANS.COMING_SOON")}</Typography>
                <TableContainer component={Paper} className="comparison-table">
                  <Table aria-label="coming soon table">
                    <TableBody>
                      {Object.keys(personal.coming_soon).map((key) => {
                        const description = featureLabels.find(el => el.key === `${key}_description`)
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={3}>
                                  {description ? (
                                      <Tooltip title={description.label} placement="right">
                                        <span style={{ cursor: "pointer", textDecoration: "underline" }}>
                                          {i18next.t(`PLANS.${key.toUpperCase()}`)}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <>{i18next.t(`PLANS.${key.toUpperCase()}`)}</>
                                    )}
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: "center"}}>
                                  {personal.coming_soon[key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                                                <IoIosCheckmarkCircle />
                                                              </Typography> : ""}
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: "center"}}>
                                  {pro.coming_soon[key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                                                <IoIosCheckmarkCircle />
                                                              </Typography> : ""}
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: "center"}}>
                                  {business.coming_soon[key] ? <Typography sx={{ fontSize: "16px", display: "inline-block" }}>
                                                                <IoIosCheckmarkCircle />
                                                              </Typography> : ""}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Stack>
        );
      })}
    </div>
  );
  
  export default PlansComparisonTable;
  