import React, { useState, useRef } from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const FAQs = ({ faqs }) => {
    const sectionRef = useRef(null);
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 100%",
                toggleActions: "play none none reverse",
                once: true,
            },
        });
        tl.fromTo(
            sectionRef.current,
            { opacity: 0, y: 0 },
            {
                opacity: 1,
                y: 0,
                duration: 2.5,
                ease: "power2.out",
            }
        );
    }, { scope: sectionRef });

    return (
        <Box className="faqs-section" ref={sectionRef}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h3" gutterBottom className="faqs-title">
                        {t("FAQS_COMPONENT.TITLE")}
                    </Typography>
                    <Typography variant="body1" className="faqs-description">
                        {t("FAQS_COMPONENT.DESCRIPTION")}{" "}
                        <Link href="mailto:support@intelswift.com" className="faqs-email">
                            {t("FAQS_COMPONENT.EMAIL")}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {faqs.map((faq, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className="faqs-accordion"
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="faqs-question">
                                {faq.question}
                            </AccordionSummary>
                            <AccordionDetails className="faqs-answer">
                                {faq.answer}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default FAQs;
