import React from "react";
import { useMemo } from "react";
import { Box, Grid, Typography, Card, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const WorkflowTimeline = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const { t } = useTranslation();

  const workflowSteps = useMemo(() => [
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MESSAGES.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MESSAGES.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.BUTTONS.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.BUTTONS.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TAGS.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TAGS.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TICKET.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TICKET.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.DATA.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.DATA.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MEDIA.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MEDIA.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.AI.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.AI.DESCRIPTION")
    }
  ], [t]);

  return (
    <Box className="workflow-container">
      <Typography variant="h3" className="workflow-title">
        {t("WORKFLOWS_PAGE.TIMELINE_SECTION.TITLE")}
      </Typography>
      <Typography variant="subtitle1" className="workflow-subtitle">
        {t("WORKFLOWS_PAGE.TIMELINE_SECTION.SUBTITLE")}
      </Typography>
      {isMobile ? (
        <>
          {workflowSteps.map((step, index) => (
            <Card className="card-container">
              <Typography variant="h5">{step.title}</Typography>
              <Typography variant="body1">{step.description}</Typography>
            </Card>
          ))}
        </>
      ) : (
        <Grid container spacing={4} className="timeline-grid">
          <Box className="timeline-line" />
          {workflowSteps.map((step, index) => (
            <Grid container item key={index} className="timeline-step">
              {index % 2 === 0 ? (
                <>
                  <Grid item xs={5} className="left-align">
                    <Card className="card-container">
                      <Typography variant="h5">{step.title}</Typography>
                      <Typography variant="body1">{step.description}</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={2} className="timeline-dot-container">
                    <Box className="timeline-dot">
                      <Box className="timeline-inner-dot" />
                    </Box>
                  </Grid>
                  <Grid item xs={5} />
                </>
              ) : (
                <>
                  <Grid item xs={5} />
                  <Grid item xs={2} className="timeline-dot-container">
                    <Box className="timeline-dot">
                      <Box className="timeline-inner-dot" />
                    </Box>
                  </Grid>
                  <Grid item xs={5} className="right-align">
                    <Card className="card-container">
                      <Typography variant="h5">{step.title}</Typography>
                      <Typography variant="body1">{step.description}</Typography>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default WorkflowTimeline;