// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import Features from "./Features.jsx";
import WorkflowTimeline from "./WorkflowTimeline.jsx";
import SmoothConversations from "./SmoothConversations.jsx";
import LevelUp from "../../components/LevelUp/index.jsx";
import WhyChooseIntelswift from "../../components/WhyChooseIntelswift/index.jsx";
import Blog from "../../components/Blog/index.jsx";
import FAQs from "../../components/FAQs/index.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- Workflows Component ---
export default function Workflows() {
  const { t } = useTranslation();

  const whyChooseData = useMemo(() => ({
    title: t("HOME_PAGE.WHY_CHOOSE.TITLE"),
    benefits: [
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.MULTILINGUAL_SUPPORT.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.MULTILINGUAL_SUPPORT.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.HUMAN_HANDOFF.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.HUMAN_HANDOFF.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.OMNICHANNEL_SERVICE.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.OMNICHANNEL_SERVICE.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.WORKFLOW_TEMPLATES.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.WORKFLOW_TEMPLATES.DESCRIPTION")
      }
    ]
  }), [t]);

  const workflowsFAQs = useMemo(() => [
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q1.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q1.ANSWER"),
    },
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q2.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q2.ANSWER"),
    },
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q3.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q3.ANSWER"),
    },
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q4.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q4.ANSWER"),
    },
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q5.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q5.ANSWER"),
    },
    {
      question: t("WORKFLOWS_PAGE.FAQS.Q6.QUESTION"),
      answer: t("WORKFLOWS_PAGE.FAQS.Q6.ANSWER"),
    }
  ], [t]);

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("WORKFLOWS_PAGE.META.TITLE")}
        description={t("WORKFLOWS_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/workflows"
      />
      <Header />
      <HeroSection />
      <Features />
      <WorkflowTimeline />
      <SmoothConversations />
      <LevelUp
        title={t("WORKFLOWS_PAGE.LEVEL_UP.TITLE")}
        subtitle={t("WORKFLOWS_PAGE.LEVEL_UP.SUBTITLE")}
        button={t("WORKFLOWS_PAGE.LEVEL_UP.BUTTON")}
      />
      <WhyChooseIntelswift
        title={whyChooseData.title}
        benefits={whyChooseData.benefits}
        showButtons={whyChooseData.showButtons}
        isHomepage={true}
      />
      <LevelUp
        title={t("WORKFLOWS_PAGE.LEVEL_UP_ALT.TITLE")}
        subtitle={t("WORKFLOWS_PAGE.LEVEL_UP_ALT.SUBTITLE")}
        button={t("WORKFLOWS_PAGE.LEVEL_UP_ALT.BUTTON")}
        isWorkflowsPage={true}
      />
      <Blog />
      <FAQs
        faqs={workflowsFAQs}
      />
      <Footer />
    </>
  );
}
