// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// --- Image Utilities ---
import { learnMoreImages } from "../../utils/imageUtils";

// --- LearnMore Component ---
const LearnMore = () => {
    const { t } = useTranslation();
  const navigate = useNavigate();

    return (
        <Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.AI_AGENTS.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.AI_AGENTS.DESCRIPTION")}
                        </Typography>
                        <Button onClick={() => { navigate('/ai-agents') }}>{t("COMMON.LEARN_MORE_BUTTON")}</Button>
                    </Grid>
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.ai_agents_img}
                            alt={t("HOME_PAGE.LEARN_MORE.AI_AGENTS.AI_AGENTS_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.ai_copilot_img}
                            alt={t("HOME_PAGE.LEARN_MORE.AI_COPILOT.AI_COPILOT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.AI_COPILOT.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.AI_COPILOT.DESCRIPTION")}
                        </Typography>
                        <Button onClick={() => { navigate('/ai-copilot') }}>{t("COMMON.LEARN_MORE_BUTTON")}</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.LIVE_CHAT.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.LIVE_CHAT.DESCRIPTION")}
                        </Typography>
                        <Button onClick={() => { navigate('/ai-helpdesk') }}>{t("COMMON.LEARN_MORE_BUTTON")}</Button>
                    </Grid>
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.live_chat_img}
                            alt={t("HOME_PAGE.LEARN_MORE.LIVE_CHAT.LIVE_CHAT_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.workflows_img}
                            alt={t("HOME_PAGE.LEARN_MORE.WORKFLOWS.WORKFLOWS_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.WORKFLOWS.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.WORKFLOWS.DESCRIPTION")}
                        </Typography>
                        <Button onClick={() => { navigate('/workflows') }}>{t("COMMON.LEARN_MORE_BUTTON")}</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.KNOWLEDGE_BASE.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.KNOWLEDGE_BASE.DESCRIPTION")}
                        </Typography>
                        <Button onClick={() => { navigate('/ai-knowledge-base') }}>{t("COMMON.LEARN_MORE_BUTTON")}</Button>
                    </Grid>
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.knowledge_base_img}
                            alt={t("HOME_PAGE.LEARN_MORE.KNOWLEDGE_BASE.KNOWLEDGE_BASE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="learn-more-container">
                <Grid container spacing={4} className="learn-more-grid-container">
                    <Grid item xs={12} md={7} className="learn-more-image-container">
                        <img
                            className="learn-more-image"
                            src={learnMoreImages.workspace_img}
                            alt={t("HOME_PAGE.LEARN_MORE.WORKSPACE.WORKSPACE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="learn-more-left">
                        <Typography variant="h3">
                            {t("HOME_PAGE.LEARN_MORE.WORKSPACE.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("HOME_PAGE.LEARN_MORE.WORKSPACE.DESCRIPTION")}
                        </Typography>
                        {/* <Button href="" target="_blank">{t("COMMON.LEARN_MORE_BUTTON")}</Button> */}
                    </Grid>
                </Grid>
            </Box>
            {/* <Box className="reports-container">
                <Typography variant="h3">
                    {t("HOME_PAGE.LEARN_MORE.REPORTS.TITLE")}
                </Typography>
                <Typography variant="subtitle1">
                    {t("HOME_PAGE.LEARN_MORE.REPORTS.DESCRIPTION")}
                </Typography>
                <img
                    className="reports-image"
                    src={learnMoreImages.reports_img}
                    alt={t("HOME_PAGE.LEARN_MORE.REPORTS.REPORTS_ALT")}
                />
                <Button className="learn-more-button">{t("COMMON.LEARN_MORE_BUTTON")}</Button>
            </Box> */}
        </Box>
    );
};

export default LearnMore;
