// --- Header Component Images ---
import logo from "../assets/img/horizontal-logo-for-black-background.png";
import swift_ai_copilot_img from "../assets/img/swift_ai_copilot_img.png";
import ai_customer_service_agent_img from "../assets/img/ai_agents.png";
// import live_chat_img from "../assets/img/placeholder-image.png";
import channels_img from "../assets/img/placeholder-image.png";
// import workflows_img from "../assets/img/placeholder-image.png";
import advanced_ai_analytics_img from "../assets/img/placeholder-image.png";
import copilot_img from "../assets/img/placeholder-image.png";

// --- Solutions Component Images ---
import solutions_img from "../assets/img/solution_img.png";
import hero_img from "../assets/img/hero_img.png";
import hero_ai_avatar from "../assets/img/hero_ai_avatar.png"

// --- Learn More Component Images ---
import ai_agents_img from "../assets/img/ai_agent.png";
import ai_copilot_img from "../assets/img/ai_copilot_img.png";
import live_chat_img from "../assets/img/live_chat_img.png";
import workflows_img from "../assets/img/workflows_img.png";
import knowledge_base_img from "../assets/img/knowledge_base_img.png";
import workspace_img from "../assets/img/workspace_img.png";
import reports_img from "../assets/img/placeholder-image.png";

// --- Integrations Component Images ---
import calendly_logo from "../assets/img/calendly_logo.svg";
import stripe_logo from "../assets/img/stripe_logo.png";
import zendesk_logo from "../assets/img/zendesk_logo.svg";
import mailchimp_logo from "../assets/img/mailchimp_logo.png";
import jira_logo from "../assets/img/atlassian_logo.svg";
import salesforce_logo from "../assets/img/salesforce_logo.svg";
import zoho_logo from "../assets/img/zoho_logo.png";
import piperdrive_logo from "../assets/img/piperdrive_logo.png";
import hubspot_logo from "../assets/img/hubspot_logo.svg";

// --- Business Outcomes Component Images ---
import startup_wise_guys from "../assets/img/startup_wise_guys.png";
import nvidia from "../assets/img/nvidia.png";
import microsoft from "../assets/img/microsoft.png";
import product_hunt from "../assets/img/product_hunt.png";

// --- Product Ecosystem Component Images ---
import swift_copilot_logo from "../assets/img/swift_copilot_logo.png";
import ai_agent_logo from "../assets/img/ai_agent_logo.png";
import live_chat_logo from "../assets/img/live_chat_logo.png";
import workflow_logo from "../assets/img/workflow_logo.png";
import analytics_logo from "../assets/img/analytics_logo.png";
import knowledge_base_logo from "../assets/img/knowledge_base_logo.png";

// --- Testimonials Component Images ---
import apple_logo from "../assets/img/apple_logo.svg";
import google_logo from "../assets/img/google_logo.svg";
import open_ai_logo from "../assets/img/open_ai_logo.svg";
import testimonial_avatar_1 from "../assets/img/testimonial_avatar_1.png";
import testimonial_avatar_2 from "../assets/img/testimonial_avatar_2.png";
import testimonial_avatar_3 from "../assets/img/testimonial_avatar_3.png";

// --- Copilot Page Images ---
import copilot_img_1 from "../assets/img/copilot_img_1.png";
import copilot_img_2 from "../assets/img/copilot_img_2.png";
import copilot_img_3 from "../assets/img/copilot_img_3.png";
import copilot_img_4 from "../assets/img/copilot_img_4.png";
import copilot_img_5 from "../assets/img/placeholder-image.png";
import copilot_img_6 from "../assets/img/placeholder-image.png";
import copilot_video_1 from "../assets/img/placeholder-image.png";

// --- LiveChat Page Images ---
import liveChat_img_1 from "../assets/img/liveChat_img_1.png";
import liveChat_img_2 from "../assets/img/liveChat_img_2.png";
import liveChat_img_3 from "../assets/img/liveChat_img_3.png";
import liveChat_img_4 from "../assets/img/liveChat_img_4.png";
import liveChat_img_5 from "../assets/img/liveChat_img_5.png";
import liveChat_img_6 from "../assets/img/liveChat_img_6.png";
import liveChat_video_1 from "../assets/img/placeholder-image.png";

// --- Partnership Page Images ---
import partnership_img_1 from "../assets/img/partnership_img_1.png";

// --- AdvancedAnalytics Page Images ---
import advancedAnalytics_img_1 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_2 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_3 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_4 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_5 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_6 from "../assets/img/placeholder-image.png";
import advancedAnalytics_img_7 from "../assets/img/placeholder-image.png";

// --- Workflows Page Images ---
import workflows_img_1 from "../assets/img/workflows_img_1.png";
import workflows_img_2 from "../assets/img/workflows_img_2.png";
import workflows_img_3 from "../assets/img/workflows_img_3.png";
import workflows_img_4 from "../assets/img/workflows_img_4.png";

// --- KnowledgeBase Page Images ---
import knowledge_base_img_1 from "../assets/img/knowledge_base_img_1.png";
import knowledge_base_img_2 from "../assets/img/knowledge_base_img_2.png";
import knowledge_base_img_3 from "../assets/img/knowledge_base_img_3.png";
import knowledge_base_img_4 from "../assets/img/knowledge_base_img_4.png";
import knowledge_base_img_5 from "../assets/img/knowledge_base_img_5.png";

// --- AI Agents Page Images ---
import gpt_logo from "../assets/img/gpt_logo.png";
import leaf_img from "../assets/img/leaf_img.png";
import ai_agents_hero_img from "../assets/img/ai_agents_hero_img.png";
import sand_clock from "../assets/img/sand_clock.png";
import agents_img_1 from "../assets/img/agents_img_1.png";
import agents_img_2 from "../assets/img/agents_img_2.png";
import agents_img_3 from "../assets/img/agents_img_3.png";
import agents_img_4 from "../assets/img/agents_img_4.png";
import agents_img_5 from "../assets/img/agents_img_5.png";
import agents_img_11 from "../assets/img/agents_img_11.png";
import agents_img_12 from "../assets/img/agents_img_12.png";
import agents_img_13 from "../assets/img/agents_img_13.png";
import agents_img_14 from "../assets/img/agents_img_14.png";
import agents_img_15 from "../assets/img/agents_img_15.png";
import agents_img_16 from "../assets/img/agents_img_16.png";
import agents_img_17 from "../assets/img/agents_img_17.png";
import agents_img_18 from "../assets/img/web_messenger_channel.png";
import agents_img_19 from "../assets/img/email_channel.png";
import agents_img_20 from "../assets/img/telegram_channel.png";
import agents_img_21 from "../assets/img/whatsapp_channel.png";
import agents_img_22 from "../assets/img/instagram_channel.png";
import agents_img_23 from "../assets/img/messenger_channel.png";
import agents_img_24 from "../assets/img/slack_channel.png";
import agents_img_25 from "../assets/img/ms_teams_channel.png";

// --- TryForFree Component Images ---
import Logomark from "../assets/img/Logomark.png";

// --- Blog Header Component Images ---
import header_img_article_1 from "./../assets/img/picture-article_1.png";
import header_img_article_2 from "./../assets/img/picture-article_2.png";
import header_img_article_3 from "./../assets/img/picture-article_3.png";
import header_img_article_4 from "./../assets/img/picture-article_4.png";
import header_img_article_5 from "./../assets/img/picture-article_5.png";
import header_img_article_6 from "./../assets/img/picture-article_6.png";
import header_img_article_7 from "./../assets/img/picture-article_7.png";
import header_img_article_8 from "./../assets/img/picture-article_8.png";
import header_img_article_9 from "./../assets/img/picture-article_9.png";
import header_img_article_10 from "./../assets/img/picture-article-10.png";
import header_img_article_11 from "./../assets/img/picture-article-11.png";

// --- Blog Authors Images ---
import oksana_author from "./../assets/img/oksana-author.jpg"

// --- Blog Second Article ---
import article_2_img_1 from "../assets/img/table-for-second-article.png";

// --- Blog Third Article ---
import article_3_img_1 from "../assets/img/first_img_third_blog.png";
import article_3_img_2 from "../assets/img/second_img_third_blog.png";
import article_3_img_3 from "../assets/img/third__img_third_blog.png";

// --- Blog Fourth Article ---
import article_4_img_1 from "../assets/img/first_img_fourth_blog.png";
import article_4_img_2 from "../assets/img/second_img_fourth_blog.png";
import article_4_img_3 from "../assets/img/third_img_fourth_blog.png";
import article_4_img_4 from "../assets/img/fourth_img_fourth_blog.png";
import article_4_img_5 from "../assets/img/fifth_img_fourth_blog.png";
import article_4_img_6 from "../assets/img/sixth_img_fourth_blog.png";
import article_4_img_7 from "../assets/img/seventh_img_fourth_blog.png";
import article_4_img_8 from "../assets/img/eighth_img_fourth_blog.png";
import article_4_img_9 from "../assets/img/ninth_img_fourth_blog.png";
import article_4_img_10 from "../assets/img/tenth_img_fourth_blog.png";
import article_4_img_11 from "../assets/img/eleventh_img_fourth_blog.png";
import article_4_img_12 from "../assets/img/twelfth_img_fourth_blog.png";
import article_4_img_13 from "../assets/img/thirteenth_img_fourth_blog.png";
import article_4_img_14 from "../assets/img/fourteenth_img_fourth_blog.png";
import article_4_img_15 from "../assets/img/fifteenth_img_fourth_blog.png";
import article_4_img_16 from "../assets/img/sixteenth_img_fourth_blog.png";
import article_4_img_17 from "../assets/img/seventeenth_img_fourth_blog.png";
import article_4_img_18 from "../assets/img/eighteenth_img_fourth_blog.png";
import article_4_img_19 from "../assets/img/nineteenth_img_fourth_blog.png";
import article_4_img_20 from "../assets/img/twentieth_img_fourth_blog.png";
import article_4_img_21 from "../assets/img/twenty_first_img_fourth_blog.png";
import article_4_img_22 from "../assets/img/twenty_second_img_fourth_blog.png";
import article_4_img_23 from "../assets/img/twenty_third_img_fourth_blog.png";
import article_4_img_24 from "../assets/img/twenty_fourth_img_fourth_blog.png";

// --- Blog Fifth Article ---
import article_5_img_1 from "../assets/img/first_img_fourth_blog.png";
import article_5_img_2 from "../assets/img/second_img_fifth_blog.png";
import article_5_img_3 from "../assets/img/third_img_fifth_blog.png";
import article_5_img_4 from "../assets/img/fourth_img_fifth_blog.png";
import article_5_img_5 from "../assets/img/fifth_img_fifth_blog.png";
import article_5_img_6 from "../assets/img/sixth_img_fifth_blog.png";
import article_5_img_7 from "../assets/img/seventh_img_fifth_blog.png";
import article_5_img_8 from "../assets/img/eighth_img_fifth_blog.png";
import article_5_img_9 from "../assets/img/ninth_img_fifth_blog.png";
import article_5_img_10 from "../assets/img/tenth_img_fifth_blog.png";
import article_5_img_11 from "../assets/img/eleventh_img_fifth_blog.png";
import article_5_img_12 from "../assets/img/twelfth_img_fifth_blog.png";
import article_5_img_13 from "../assets/img/thirteenth_img_fifth_blog.png";
import article_5_img_14 from "../assets/img/fourteenth_img_fifth_blog.png";
import article_5_img_15 from "../assets/img/fifteenth_img_fifth_blog.png";
import article_5_img_16 from "../assets/img/sixteenth_img_fifth_blog.png";
import article_5_img_17 from "../assets/img/seventeenth_img_fifth_blog.png";
import article_5_img_18 from "../assets/img/eighteenth_img_fifth_blog.png";
import article_5_img_19 from "../assets/img/nineteenth_img_fifth_blog.png";
import article_5_img_20 from "../assets/img/twentieth_img_fifth_blog.png";
import article_5_img_21 from "../assets/img/twenty_first_img_fifth_blog.png";
import article_5_img_22 from "../assets/img/twenty_second_img_fifth_blog.png";
import article_5_img_23 from "../assets/img/twenty_third_img_fifth_blog.png";
import article_5_img_24 from "../assets/img/twenty_fourth_img_fifth_blog.png";
import article_5_img_25 from "../assets/img/twenty_fifth_img_fifth_blog.png";
import article_5_img_26 from "../assets/img/twenty_sixth_img_fifth_blog.png";
import article_5_img_27 from "../assets/img/twenty_seventh_img_fifth_blog.png";
import article_5_img_28 from "../assets/img/twenty_eighth_img_fifth_blog.png";
import article_5_img_29 from "../assets/img/twenty_ninth_img_fifth_blog.png";
import article_5_img_30 from "../assets/img/thirtieth_img_fifth_blog.png";
import article_5_img_31 from "../assets/img/thirty_first_img_fifth_blog.png";
import article_5_img_32 from "../assets/img/thirty_second_img_fifth_blog.png";
import article_5_img_33 from "../assets/img/thirty_third_img_fifth_blog.png";
import article_5_img_34 from "../assets/img/thirty_fourth_img_fifth_blog.png";
import article_5_img_35 from "../assets/img/thirty_fifth_img_fifth_blog.png";
import article_5_img_36 from "../assets/img/thirty_sixth_img_fifth_blog.png";
import article_5_img_37 from "../assets/img/thirty_seventh_img_fifth_blog.png";
import article_5_img_38 from "../assets/img/thirty_eighth_img_fifth_blog.png";
import article_5_img_39 from "../assets/img/thirty_ninth_img_fifth_blog.png";
import article_5_img_40 from "../assets/img/fortieth_img_fifth_blog.png";
import article_5_img_41 from "../assets/img/forty_first_img_fifth_blog.png";
import article_5_img_42 from "../assets/img/forty_second_img_fifth_blog.png";
import article_5_img_43 from "../assets/img/forty_third_img_fifth_blog.png";
import article_5_img_44 from "../assets/img/forty_fourth_img_fifth_blog.png";
import article_5_img_45 from "../assets/img/forty_fifth_img_fifth_blog.png";

// --- Blog Sixth Article ---
import article_6_img_3 from "../assets/img/article_6_img_3.png";
import article_6_img_4 from "../assets/img/article_6_img_4.png";
import article_6_img_5 from "../assets/img/article_6_img_5.png";
import article_6_img_6 from "../assets/img/article_6_img_6.png";
import article_6_img_7 from "../assets/img/article_6_img_7.png";
import article_6_img_8 from "../assets/img/article_6_img_8.png";
import article_6_img_9 from "../assets/img/article_6_img_9.png";
import article_6_img_10 from "../assets/img/article_6_img_10.png";
import article_6_img_11 from "../assets/img/article_6_img_11.png";
import article_6_img_12 from "../assets/img/article_6_img_12.png";
import article_6_img_13 from "../assets/img/article_6_img_13.png";
import article_6_img_14 from "../assets/img/article_6_img_14.png";
import article_6_img_15 from "../assets/img/article_6_img_15.png";
import article_6_img_16 from "../assets/img/article_6_img_16.png";
import article_6_img_17 from "../assets/img/article_6_img_17.png";
import article_6_img_18 from "../assets/img/article_6_img_18.png";
import article_6_img_19 from "../assets/img/article_6_img_19.png";
import article_6_img_20 from "../assets/img/article_6_img_20.png";

// --- Blog Seventh Article ---
import article_7_img_3 from "../assets/img/article_7_img_3.png";
import article_7_img_4 from "../assets/img/article_7_img_4.png";
import article_7_img_5 from "../assets/img/article_7_img_5.png";
import article_7_img_6 from "../assets/img/article_7_img_6.png";
import article_7_img_7 from "../assets/img/article_7_img_7.png";
import article_7_img_8 from "../assets/img/article_7_img_8.png";
import article_7_img_9 from "../assets/img/article_7_img_9.png";
import article_7_img_10 from "../assets/img/article_7_img_10.png";
import article_7_img_11 from "../assets/img/article_7_img_11.png";
import article_7_img_12 from "../assets/img/article_7_img_12.png";
import article_7_img_13 from "../assets/img/article_7_img_13.png";
import article_7_img_14 from "../assets/img/article_7_img_14.png";
import article_7_img_15 from "../assets/img/article_7_img_15.png";
import article_7_img_16 from "../assets/img/article_7_img_16.png";
import article_7_img_17 from "../assets/img/article_7_img_17.png";
import article_7_img_18 from "../assets/img/article_7_img_18.png";
import article_7_img_19 from "../assets/img/article_7_img_19.png";
import article_7_img_20 from "../assets/img/article_7_img_20.png";
import article_7_img_21 from "../assets/img/article_7_img_21.png";
import article_7_img_22 from "../assets/img/article_7_img_22.png";
import article_7_img_23 from "../assets/img/article_7_img_23.png";
import article_7_img_24 from "../assets/img/article_7_img_24.png";

// --- Blog Eighth Article ---
import article_8_img_4 from "../assets/img/article_8_img_4.png";
import article_8_img_5 from "../assets/img/article_8_img_5.png";
import article_8_img_6 from "../assets/img/article_8_img_6.png";
import article_8_img_7 from "../assets/img/article_8_img_7.png";
import article_8_img_8 from "../assets/img/article_8_img_8.png";
import article_8_img_9 from "../assets/img/article_8_img_9.png";
import article_8_img_10 from "../assets/img/article_8_img_10.png";
import article_8_img_11 from "../assets/img/article_8_img_11.png";
import article_8_img_12 from "../assets/img/article_8_img_12.png";
import article_8_img_13 from "../assets/img/article_8_img_13.png";
import article_8_img_14 from "../assets/img/article_8_img_14.png";
import article_8_img_15 from "../assets/img/article_8_img_15.png";

// --- Blog Ninth Article ---
import article_9_img_4 from "../assets/img/article_9_img_4.png";
import article_9_img_5 from "../assets/img/article_9_img_5.png";
import article_9_img_6 from "../assets/img/article_9_img_6.png";
import article_9_img_7 from "../assets/img/article_9_img_7.png";
import article_9_img_8 from "../assets/img/article_9_img_8.png";
import article_9_img_9 from "../assets/img/article_9_img_9.png";
import article_9_img_10 from "../assets/img/article_9_img_10.png";
import article_9_img_11 from "../assets/img/article_9_img_11.png";
import article_9_img_12 from "../assets/img/article_9_img_12.png";
import article_9_img_13 from "../assets/img/article_9_img_13.png";
import article_9_img_14 from "../assets/img/article_9_img_14.png";
import article_9_img_15 from "../assets/img/article_9_img_15.png";
import article_9_img_16 from "../assets/img/article_9_img_16.png";
import article_9_img_17 from "../assets/img/article_9_img_17.png";
import article_9_img_18 from "../assets/img/article_9_img_18.png";
import article_9_img_19 from "../assets/img/article_9_img_19.png";
import article_9_img_20 from "../assets/img/article_9_img_20.png";
import article_9_img_21 from "../assets/img/article_9_img_21.png";
import article_9_img_22 from "../assets/img/article_9_img_22.png";
import article_9_img_23 from "../assets/img/article_9_img_23.png";
import article_9_img_24 from "../assets/img/article_9_img_24.png";
import article_9_img_25 from "../assets/img/article_9_img_25.png";
import article_9_img_26 from "../assets/img/article_9_img_26.png";
import article_9_img_27 from "../assets/img/article_9_img_27.png";
import article_9_img_28 from "../assets/img/article_9_img_28.png";
import article_9_img_29 from "../assets/img/article_9_img_29.png";
import article_9_img_30 from "../assets/img/article_9_img_30.png";
import article_9_img_31 from "../assets/img/article_9_img_31.png";

// --- Blog Tenth Article ---
import article_10_img_4 from "../assets/img/article_10_img_4.png";
import article_10_img_5 from "../assets/img/article_10_img_5.png";
import article_10_img_6 from "../assets/img/article_10_img_6.png";
import article_10_img_7 from "../assets/img/article_10_img_7.png";
import article_10_img_8 from "../assets/img/article_10_img_8.png";
import article_10_img_9 from "../assets/img/article_10_img_9.png";
import article_10_img_10 from "../assets/img/article_10_img_10.png";
import article_10_img_11 from "../assets/img/article_10_img_11.png";
import article_10_img_12 from "../assets/img/article_10_img_12.png";
import article_10_img_13 from "../assets/img/article_10_img_13.png";
import article_10_img_14 from "../assets/img/article_10_img_14.png";
import article_10_img_15 from "../assets/img/article_10_img_15.png";
import article_10_img_16 from "../assets/img/article_10_img_16.png";
import article_10_img_17 from "../assets/img/article_10_img_17.png";
import article_10_img_18 from "../assets/img/article_10_img_18.png";

// --- Blog Eleventh Article ---
import article_11_img_3 from "../assets/img/article_11_img_3.png";
import article_11_img_4 from "../assets/img/article_11_img_4.png";
import article_11_img_5 from "../assets/img/article_11_img_5.png";
import article_11_img_6 from "../assets/img/article_11_img_6.png";
import article_11_img_7 from "../assets/img/article_11_img_7.png";
import article_11_img_8 from "../assets/img/article_11_img_8.png";
import article_11_img_9 from "../assets/img/article_11_img_9.png";
import article_11_img_10 from "../assets/img/article_11_img_10.png";
import article_11_img_11 from "../assets/img/article_11_img_11.png";
import article_11_img_12 from "../assets/img/article_11_img_12.png";
import article_11_img_13 from "../assets/img/article_11_img_13.png";

// --- Awards Component Images ---
import product_of_the_week from "../assets/img/product_of_the_week.png";

// --- Export Images ---
export const headerImages = {
    logo,
    swift_ai_copilot_img,
    ai_customer_service_agent_img,
    live_chat_img: liveChat_img_1,
    workflows_img,
    advanced_ai_analytics_img,
    ai_knowledge_base: knowledge_base_img_1,
    copilot_img
};

export const solutionsImages = {
    solutions_img,
    hero_img,
    hero_ai_avatar
};

export const learnMoreImages = {
    ai_agents_img,
    ai_copilot_img,
    live_chat_img,
    workflows_img,
    knowledge_base_img,
    workspace_img,
    reports_img
};

export const integrationsImages = {
    calendly_logo,
    stripe_logo,
    zendesk_logo,
    mailchimp_logo,
    jira_logo,
    salesforce_logo,
    zoho_logo,
    piperdrive_logo,
    hubspot_logo
};

export const businessImages = {
    startup_wise_guys,
    nvidia,
    microsoft,
    product_hunt
};

export const ecosystemImages = {
    swift_copilot_logo,
    ai_agent_logo,
    live_chat_logo,
    workflow_logo,
    analytics_logo,
    knowledge_base_logo
};


export const testimonialsImages = {
    apple_logo,
    google_logo,
    open_ai_logo,
    testimonial_avatar_1,
    testimonial_avatar_2,
    testimonial_avatar_3,
};

export const copilotImages = {
    copilot_img_1,
    copilot_img_2,
    copilot_img_3,
    copilot_img_4,
    copilot_img_5,
    copilot_img_6,
    copilot_video_1
}

export const liveChatImages = {
    liveChat_img_1,
    liveChat_img_2,
    liveChat_img_3,
    liveChat_img_4,
    liveChat_img_5,
    liveChat_img_6,
    liveChat_video_1
}

export const partnershipImages = {
    partnership_img_1
}

export const advancedAnalyticsImages = {
    advancedAnalytics_img_1,
    advancedAnalytics_img_2,
    advancedAnalytics_img_3,
    advancedAnalytics_img_4,
    advancedAnalytics_img_5,
    advancedAnalytics_img_6,
    advancedAnalytics_img_7
}

export const workflowsImages = {
    workflows_img_1,
    workflows_img_2,
    workflows_img_3,
    workflows_img_4
}

export const knowledgeBaseImages = {
    knowledge_base_img_1,
    knowledge_base_img_2,
    knowledge_base_img_3,
    knowledge_base_img_4,
    knowledge_base_img_5,
}

export const agentsImages = {
    intelswift_logo: hero_ai_avatar,
    gpt_logo,
    leaf_img,
    zendesk_logo,
    hubspot_logo,
    ai_agents_hero_img,
    sand_clock,
    agents_img_1,
    agents_img_2,
    agents_img_3,
    agents_img_4,
    agents_img_5,
    agents_img_11,
    agents_img_12,
    agents_img_13,
    agents_img_14,
    agents_img_15,
    agents_img_16,
    agents_img_17,
    agents_img_18,
    agents_img_19,
    agents_img_20,
    agents_img_21,
    agents_img_22,
    agents_img_23,
    agents_img_24,
    agents_img_25,
}

export const tryForFreeImages = {
    Logomark,
};

export const blogHeaderImages = {
    header_img_article_1,
    header_img_article_2,
    header_img_article_3,
    header_img_article_4,
    header_img_article_5,
    header_img_article_6,
    header_img_article_7,
    header_img_article_8,
    header_img_article_9,
    header_img_article_10,
    header_img_article_11
};

export const authors = {
    oksana_author
}

export const secondArticle = {
    article_2_img_1,
};

export const thirdArticle = {
    article_3_img_1,
    article_3_img_2,
    article_3_img_3,
};

export const fourthArticle = {
    article_4_img_1,
    article_4_img_2,
    article_4_img_3,
    article_4_img_4,
    article_4_img_5,
    article_4_img_6,
    article_4_img_7,
    article_4_img_8,
    article_4_img_9,
    article_4_img_10,
    article_4_img_11,
    article_4_img_12,
    article_4_img_13,
    article_4_img_14,
    article_4_img_15,
    article_4_img_16,
    article_4_img_17,
    article_4_img_18,
    article_4_img_19,
    article_4_img_20,
    article_4_img_21,
    article_4_img_22,
    article_4_img_23,
    article_4_img_24,
};

export const fifthArticle = {
    article_5_img_1,
    article_5_img_2,
    article_5_img_3,
    article_5_img_4,
    article_5_img_5,
    article_5_img_6,
    article_5_img_7,
    article_5_img_8,
    article_5_img_9,
    article_5_img_10,
    article_5_img_11,
    article_5_img_12,
    article_5_img_13,
    article_5_img_14,
    article_5_img_15,
    article_5_img_16,
    article_5_img_17,
    article_5_img_18,
    article_5_img_19,
    article_5_img_20,
    article_5_img_21,
    article_5_img_22,
    article_5_img_23,
    article_5_img_24,
    article_5_img_25,
    article_5_img_26,
    article_5_img_27,
    article_5_img_28,
    article_5_img_29,
    article_5_img_30,
    article_5_img_31,
    article_5_img_32,
    article_5_img_33,
    article_5_img_34,
    article_5_img_35,
    article_5_img_36,
    article_5_img_37,
    article_5_img_38,
    article_5_img_39,
    article_5_img_40,
    article_5_img_41,
    article_5_img_42,
    article_5_img_43,
    article_5_img_44,
    article_5_img_45,
};

export const sixthArticle = {
    article_6_img_1: article_5_img_1,
    article_6_img_2: article_5_img_2,
    article_6_img_3,
    article_6_img_4,
    article_6_img_5,
    article_6_img_6,
    article_6_img_7,
    article_6_img_8,
    article_6_img_9,
    article_6_img_10,
    article_6_img_11,
    article_6_img_12,
    article_6_img_13,
    article_6_img_14,
    article_6_img_15,
    article_6_img_16,
    article_6_img_17,
    article_6_img_18,
    article_6_img_19,
    article_6_img_20,
};

export const seventhArticle = {
    article_7_img_1: article_5_img_1,
    article_7_img_2: article_5_img_2,
    article_7_img_3,
    article_7_img_4,
    article_7_img_5,
    article_7_img_6,
    article_7_img_7,
    article_7_img_8,
    article_7_img_9,
    article_7_img_10,
    article_7_img_11,
    article_7_img_12,
    article_7_img_13,
    article_7_img_14,
    article_7_img_15,
    article_7_img_16,
    article_7_img_17,
    article_7_img_18,
    article_7_img_19,
    article_7_img_20,
    article_7_img_21,
    article_7_img_22,
    article_7_img_23,
    article_7_img_24,
};

export const eighthArticle = {
    article_8_img_1: article_5_img_1,
    article_8_img_2: article_5_img_2,
    article_8_img_3: article_7_img_3,
    article_8_img_4,
    article_8_img_5,
    article_8_img_6,
    article_8_img_7,
    article_8_img_8,
    article_8_img_9,
    article_8_img_10,
    article_8_img_11,
    article_8_img_12,
    article_8_img_13,
    article_8_img_14,
    article_8_img_15,
};

export const ninthArticle = {
    article_9_img_1: article_5_img_1,
    article_9_img_2: article_5_img_2,
    article_9_img_3: article_7_img_3,
    article_9_img_4,
    article_9_img_5,
    article_9_img_6,
    article_9_img_7,
    article_9_img_8,
    article_9_img_9,
    article_9_img_10,
    article_9_img_11,
    article_9_img_12,
    article_9_img_13,
    article_9_img_14,
    article_9_img_15,
    article_9_img_16,
    article_9_img_17,
    article_9_img_18,
    article_9_img_19,
    article_9_img_20,
    article_9_img_21,
    article_9_img_22,
    article_9_img_23,
    article_9_img_24,
    article_9_img_25,
    article_9_img_26,
    article_9_img_27,
    article_9_img_28,
    article_9_img_29,
    article_9_img_30,
    article_9_img_31,
};

export const tenthArticle = {
    article_10_img_1: article_5_img_1,
    article_10_img_2: article_5_img_2,
    article_10_img_3: article_7_img_3,
    article_10_img_4,
    article_10_img_5,
    article_10_img_6,
    article_10_img_7,
    article_10_img_8,
    article_10_img_9,
    article_10_img_10,
    article_10_img_11,
    article_10_img_12,
    article_10_img_13,
    article_10_img_14,
    article_10_img_15,
    article_10_img_16,
    article_10_img_17,
    article_10_img_18,
};

export const eleventhArticle = {
    article_11_img_1: article_5_img_1,
    article_11_img_2: article_5_img_2,
    article_11_img_3,
    article_11_img_4,
    article_11_img_5,
    article_11_img_6,
    article_11_img_7,
    article_11_img_8,
    article_11_img_9,
    article_11_img_10,
    article_11_img_11,
    article_11_img_12,
    article_11_img_13,
    // article_11_img_14,
};

export const awardsImages = {
    product_of_the_week,
};