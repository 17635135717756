import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { businessImages } from "../../utils/imageUtils";

gsap.registerPlugin(ScrollTrigger);

const BusinessOutcomes = () => {
    const sectionRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 100%",
                toggleActions: "play none none reverse",
                once: true,
            },
        });
        tl.fromTo(
            sectionRef.current,
            { opacity: 0, y: 0 },
            {
                opacity: 1,
                y: 0,
                duration: 2.5,
                ease: "power2.out",
            }
        );
    }, []);

    return (
        <Box ref={sectionRef}>
            <Box className="business-metrics">
                <Typography variant="h3">
                    {t("HOME_PAGE.BUSINESS_OUTCOMES.TITLE")}
                </Typography>
                <Grid container spacing={4} className="metrics-grid">
                    {[
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.LOWER_COSTS.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.LOWER_COSTS.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.AI_RESOLVED.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.AI_RESOLVED.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_SATISFACTION.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_SATISFACTION.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_CONVERSIONS.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_CONVERSIONS.LABEL"), },
                    ].map((item, index) => (
                        <>
                            <Grid key={index} item xs={12} sm={6} md={3} className="metric-item">
                                <Typography variant="h2" className="metric-value">
                                    {item.value}
                                </Typography>
                                <Typography variant="subtitle1" className="metric-label">
                                    {item.label}
                                </Typography>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Box>
            <Box className="partners-section">
                <Grid container spacing={4} justifyContent="center">
                    {[
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.BACKED_BY.LABEL"), image: businessImages.startup_wise_guys, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.BACKED_BY.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_NVIDIA.LABEL"), image: businessImages.nvidia, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_NVIDIA.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_MICROSOFT.LABEL"), image: businessImages.microsoft, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_MICROSOFT.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.PRODUCT_OF_THE_DAY.LABEL"), image: businessImages.product_hunt, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.PRODUCT_OF_THE_DAY.ALT") },
                    ].map((partner, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} className="partner-item">
                            <Typography variant="body2" className="partner-label">
                                {partner.label}
                            </Typography>
                            <img src={partner.image} alt={partner.alt} className="partner-logo" />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default BusinessOutcomes;
