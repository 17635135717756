import React from "react";
import { Typography, Button, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { workflowsImages } from "../../utils/imageUtils";

import {
    SIGN_UP_URL
  } from "./../../utils/constants";

  
const Hero = () => {
    const { t } = useTranslation();

    return (
        <Box className="workflows-hero-section">
            <Box className="workflows-hero-content">
                <Typography variant="h1">
                {t("WORKFLOWS_PAGE.HERO_SECTION.TITLE")}
                </Typography>
                <Typography variant="h3">
                {t("WORKFLOWS_PAGE.HERO_SECTION.DESCRIPTION")}               
                 </Typography>
                 <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                    <Button variant="contained" className="live-chat-features-demo-button">
                    {t("COMMON.SIGN_UP_FREE_BUTTON")}
                    </Button>
                </Link>
                <Typography variant="subtitle1">
                {t("WORKFLOWS_PAGE.HERO_SECTION.NO_CREDIT_CARD")}
                </Typography>
                <img
                    src={workflowsImages.workflows_img_1}
                    alt="Intelswift - Improve Support With AI Customer Service Workflow Automation"
                    className="workflows-image"
                />
            </Box>
        </Box>
    );
}

export default Hero;
