// --- Third Party Libraries ---
import React, { useState, useEffect, useRef, useMemo } from "react";
import { gsap } from "gsap";
import { Typography, Box, Grid, Tabs, Tab, IconButton, Switch } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IoMdSend } from "react-icons/io";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

// --- Constants ---
import {
    GET_DEMO_URL
} from "../../utils/constants";

// --- Custom Switch ---
const AntSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    display: "flex",
    alignItems: "center",
    '& .MuiSwitch-switchBase': {
        padding: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)',
                opacity: 1,
                border: 0,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 12,
        backgroundColor: '#D7DBEC',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'background'], {
            duration: 500,
        }),
    },
}));

// --- ConversationalAgents Component ---
const ConversationalAgents = () => {
    // --- State Hooks ---
    const [mainTab, setMainTab] = useState(0);
    const [activeInnerTab, setActiveInnerTab] = useState(0);
    const [messages, setMessages] = useState([]);
    const [typingText, setTypingText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTypingUser, setIsTypingUser] = useState(false);
    const [activeSequence, setActiveSequence] = useState([]);
    const [isWidgetInView, setIsWidgetInView] = useState(false);
    const chatContainerRef = useRef(null);
    const messagesEndRef = useRef(null);
    const widgetWrapperRef = useRef(null);
    const widgetScopeRef = useRef(null);
    const isMobile = useMediaQuery("(max-width:768px)");
    const { t } = useTranslation();

    // --- Tab Handlers ---
    const handleMainTabChange = (_event, newValue) => {
        setMainTab(newValue);
        setActiveInnerTab(0);
    };

    const handleInnerTabChangeLeft = (_event, newValue) => {
        setActiveInnerTab(newValue);
    };

    const handleInnerTabChangeRight = (_event, newValue) => {
        setActiveInnerTab(newValue + 2);
    };

    // Tab Data ---
    const innerTabData = useMemo(() => [
        {
            title: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.KNOWLEDGE.TITLE"),
            description: [
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.KNOWLEDGE.DESCRIPTION"),
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.KNOWLEDGE.DESCRIPTION")
            ]
        },
        {
            title: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.TOOLS.TITLE"),
            description: [
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.TOOLS.DESCRIPTION"),
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.TOOLS.DESCRIPTION")
            ]
        },
        {
            title: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.INTEGRATIONS.TITLE"),
            description: [
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.INTEGRATIONS.DESCRIPTION"),
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.INTEGRATIONS.DESCRIPTION")
            ]
        },
        {
            title: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.DATA_INSIGHTS.TITLE"),
            description: [
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.DATA_INSIGHTS.DESCRIPTION"),
                t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.INNER_TABS.DATA_INSIGHTS.DESCRIPTION")
            ]
        }
    ], [t]);

    const messageSequencesByTab = useMemo(() => ({
        0: {
            0: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_0_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_0_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_0_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_0_3"), technologyTooltip: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_0_3_TOOLTIP") }
            ],
            1: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_1_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_1_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_1_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_1_3"), technologyTooltip: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_1_3_TOOLTIP") }
            ],
            2: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_2_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_2_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_2_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_2_3"), technologyTooltip: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_2_3_TOOLTIP") }
            ],
            3: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_3_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_3_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_3_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.0_3_3") }
            ]
        },
        1: {
            0: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_0_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_0_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_0_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_0_3"), technologyTooltip: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_0_3_TOOLTIP") }
            ],
            1: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_3") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_4") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_1_5"), isDemoLink: true }
            ],
            2: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_3") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_4") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_5"), technologyTooltip: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_2_5_TOOLTIP") }
            ],
            3: [
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_3_0") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_3_1") },
                { sender: "user", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_3_2") },
                { sender: "agent", text: t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.MESSAGES.1_3_3") }
            ]
        }
    }), [t]);

    // --- Animations ---
    useEffect(() => {
        if (messages.length > 0) {
            const chatRows = gsap.utils.toArray(chatContainerRef.current.querySelectorAll(".conversational-chat-row"));
            gsap.fromTo(
                chatRows[chatRows.length - 1],
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: 0.6, ease: "power2.out" }
            );
        }
    }, [messages]);

    useEffect(() => {
        if (messages.length > 0 && chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [messages]);

    useEffect(() => {
        const widget = widgetWrapperRef.current;
        gsap.fromTo(
            widget,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: widget,
                    start: "top 80%",
                    toggleActions: "play none none none"
                }
            }
        );
    }, [mainTab, activeInnerTab]);

    useEffect(() => {
        const sequence = (messageSequencesByTab[mainTab] || {})[activeInnerTab] || [];
        setMessages([]);
        setTypingText("");
        setCurrentIndex(0);
        setIsTypingUser(false);
        setActiveSequence(sequence);
    }, [mainTab, activeInnerTab]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1, // 10% visibility triggers typing
        };
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsWidgetInView(true);
                }
            });
        }, observerOptions);
    
        const widget = widgetWrapperRef.current;
        if (widget) {
            observer.observe(widget);
        }
    
        return () => {
            if (widget) {
                observer.unobserve(widget);
            }
        };
    }, [mainTab, activeInnerTab]);    

    useEffect(() => {
        if (!isWidgetInView) return;
        if (activeSequence.length === 0 || currentIndex >= activeSequence.length) return;
        const currentMsg = activeSequence[currentIndex];
        if (currentMsg.sender === "user") {
            const typingDelay = setTimeout(() => {
                setIsTypingUser(true);
                let charIndex = 0;
                let tempText = "";
                const typingInterval = setInterval(() => {
                    if (charIndex < currentMsg.text.length) {
                        tempText += currentMsg.text.charAt(charIndex);
                        setTypingText(tempText);
                        charIndex++;
                    } else {
                        clearInterval(typingInterval);
                        setMessages(prev => [...prev, currentMsg]);
                        setTypingText("");
                        setIsTypingUser(false);
                        setCurrentIndex(prev => prev + 1);
                    }
                }, 30);
            }, 1000);
            return () => clearTimeout(typingDelay);
        } else {
            const agentDelay = setTimeout(() => {
                setMessages(prev => [...prev, currentMsg]);
                setCurrentIndex(prev => prev + 1);
            }, 1000);
            return () => clearTimeout(agentDelay);
        }
    }, [currentIndex, activeSequence, isWidgetInView]);

    // --- Render Main Tabs
    const renderMainTabs = () => (
        isMobile ? (
            <Box sx={{ display: "flex", gap: 2 }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: mainTab === 0 ? 700 : 400,
                        fontSize: "16px",
                        background: mainTab === 0
                            ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                            : "none",
                        WebkitBackgroundClip: mainTab === 0 ? "text" : "unset",
                        WebkitTextFillColor: mainTab === 0 ? "transparent" : "#6B737E",
                    }}
                >
                    {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.TABS.SUPPORT")}
                </Typography>
                <AntSwitch checked={mainTab === 1} onChange={(_, checked) => setMainTab(checked ? 1 : 0)} />
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: mainTab === 1 ? 700 : 400,
                        fontSize: "16px",
                        background: mainTab === 1
                            ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                            : "none",
                        WebkitBackgroundClip: mainTab === 1 ? "text" : "unset",
                        WebkitTextFillColor: mainTab === 1 ? "transparent" : "#6B737E",
                    }}
                >
                    {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.TABS.SALES")}
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    width: "500px",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "1px solid",
                    borderImageSource:
                        "linear-gradient(90deg, rgba(215, 219, 236, 0) 0%, #D7DBEC 49%, rgba(215, 219, 236, 0) 100%)",
                    borderImageSlice: 1,
                }}
            >
                <Tabs
                    value={mainTab}
                    onChange={handleMainTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    TabIndicatorProps={{
                        sx: {
                            height: "2px",
                            borderRadius: "2px",
                            background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                        },
                    }}
                    sx={{
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#6B737E",
                            "&.Mui-selected": {
                                background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontWeight: 700,
                            },
                        },
                    }}
                >
                    <Tab label={t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.TABS.SUPPORT")} />
                    <Tab label={t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.TABS.SALES")} />
                </Tabs>
            </Box>
        )
    );

    // --- Render Inner Tabs (Mobile)
    const renderInnerTabsMobile = () => (
        <>
            <Box
                sx={{
                    textAlign: "left",
                    width: "100%",
                    borderBottom: "1px solid rgba(204, 210, 231, 1)",
                    overflowX: "auto",
                }}
            >
                <Tabs
                    value={activeInnerTab}
                    onChange={(_event, newValue) => setActiveInnerTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        sx: {
                            height: "2px",
                            borderRadius: "2px",
                            background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                        },
                    }}
                    sx={{
                        minWidth: "max-content",
                        "& .MuiTabs-flexContainer": {
                            gap: "8px",
                        },
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#6B737E",
                            minWidth: "fit-content",
                            paddingX: 2,
                            "&.Mui-selected": {
                                background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontWeight: 700,
                            },
                        },
                    }}
                >
                    {innerTabData.map((tab, index) => (
                        <Tab key={index} label={tab.title} />
                    ))}
                </Tabs>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        color: "#6B737E",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "180%",
                    }}
                >
                    {innerTabData[activeInnerTab]?.description[mainTab]}
                </Typography>
            </Box>
            <Box className="conversational-widget-grid">
                {renderChat()}
            </Box>
        </>
    )

    // --- Render Inner Tabs
    const renderInnerTabs = () => (
        <Grid container spacing={4} className="conversational-agents-grid">
            <Grid item xs={12} md={4}>
                <Tabs
                    value={activeInnerTab < 2 ? activeInnerTab : false}
                    onChange={handleInnerTabChangeLeft}
                    orientation="vertical"
                    TabIndicatorProps={{ style: { display: "none" } }}
                    sx={{
                        "& .MuiTab-root": {
                            alignItems: "flex-start",
                            textTransform: "none",
                            padding: "8px 0",
                            minHeight: "auto",
                            justifyContent: "flex-start",
                            marginBottom: "80px"
                        },
                    }}
                >
                    {innerTabData.slice(0, 2).map((tab, index) => (
                        <Tab
                            key={index}
                            disableRipple
                            label={
                                <Box
                                    sx={{
                                        textAlign: "left",
                                        width: "100%",
                                        borderTop: "1px solid",
                                        borderImageSource: "linear-gradient(89.8deg, #CCD2E7 0.17%, rgba(204, 210, 231, 0) 101.02%)",
                                        borderImageSlice: 1,
                                    }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            background: activeInnerTab === index
                                                ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                                                : "none",
                                            WebkitBackgroundClip: activeInnerTab === index ? "text" : "unset",
                                            WebkitTextFillColor: activeInnerTab === index ? "transparent" : "#1F2430",
                                            borderTop: activeInnerTab === index
                                                ? "2px solid #8D2BFF"
                                                : "2px solid transparent",
                                            paddingTop: "8px",
                                            display: "inline-block",
                                            mb: 1,
                                        }}
                                    >
                                        {tab.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "#6B737E",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            maxWidth: "90%"
                                        }}
                                    >
                                        {tab.description[mainTab]}
                                    </Typography>
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={12} md={4} className="conversational-widget-grid">
                {renderChat()}
            </Grid>
            <Grid item xs={12} md={4}>
                <Tabs
                    value={activeInnerTab >= 2 ? activeInnerTab - 2 : false}
                    onChange={handleInnerTabChangeRight}
                    orientation="vertical"
                    TabIndicatorProps={{ style: { display: "none" } }}
                    sx={{
                        "& .MuiTab-root": {
                            alignItems: "flex-start",
                            textTransform: "none",
                            padding: "8px 0",
                            minHeight: "auto",
                            justifyContent: "flex-start",
                            marginBottom: "80px"
                        },
                    }}
                >
                    {innerTabData.slice(2, 4).map((tab, index) => (
                        <Tab
                            key={index + 2}
                            disableRipple
                            label={
                                <Box
                                    sx={{
                                        textAlign: "left",
                                        width: "100%",
                                        borderTop: "1px solid",
                                        borderImageSource: "linear-gradient(89.8deg, #CCD2E7 0.17%, rgba(204, 210, 231, 0) 101.02%)",
                                        borderImageSlice: 1,
                                    }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            background: activeInnerTab === index + 2
                                                ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                                                : "none",
                                            WebkitBackgroundClip: activeInnerTab === index + 2 ? "text" : "unset",
                                            WebkitTextFillColor: activeInnerTab === index + 2 ? "transparent" : "#1F2430",
                                            borderTop: activeInnerTab === index + 2
                                                ? "2px solid #8D2BFF"
                                                : "2px solid transparent",
                                            paddingTop: "8px",
                                            display: "inline-block",
                                            mb: 1,
                                        }}
                                    >
                                        {tab.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "#6B737E",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            maxWidth: "90%"
                                        }}
                                    >
                                        {tab.description[mainTab]}
                                    </Typography>
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
            </Grid>
        </Grid>
    )

    // --- Render Input for Chat
    const renderChatInput = () => (
        <Box className="conversational-chat-input-wrapper">
            <Typography variant="body1" className={`conversational-chat-input-text ${!isTypingUser ? "placeholder-text" : ""}`}>
                {isTypingUser ? typingText : t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.PLACEHOLDER")}
            </Typography>
            <IconButton
                className={`conversational-send-icon ${!isTypingUser ? "send-icon-disabled" : ""}`}
                disabled={!isTypingUser}
            >
                <IoMdSend />
            </IconButton>
        </Box>
    )

    // --- Render Chat
    const renderChat = () => (
        <Box className="conversational-chat-widget-wrapper" ref={widgetWrapperRef}>
            <Box className="conversational-chat-messages-container" ref={chatContainerRef}>
                {messages.map((msg, idx) => (
                    <Box
                        key={idx}
                        className={`conversational-chat-row ${msg.sender === "agent" ? "left" : "right"}`}
                    >
                        {msg.sender === "agent" && (
                            <Typography variant="body2" className="conversational-chat-name">
                                {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.AGENT_NAME")}
                            </Typography>
                        )}
                        <Box className={`conversational-chat-bubble ${msg.sender === "agent" ? "white" : "dark"}`}>
                            <Typography variant="body1">
                                {msg.isDemoLink ? (
                                    <>
                                        {msg.text}{" "}
                                        <a
                                            href={GET_DEMO_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#8D2BFF", textDecoration: "underline" }}
                                        >
                                            {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.CHAT.DEMO_LINK")}
                                        </a>
                                    </>
                                ) : (
                                    msg.text
                                )}
                            </Typography>
                        </Box>
                        {msg.technologyTooltip && idx === messages.length - 1 && (
                            <Box className="agent-tooltip">
                                <Typography variant="caption" sx={{ color: "#6B737E", mt: 1 }}>
                                    {msg.technologyTooltip}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ))}
                        <div ref={messagesEndRef} />
            </Box>
            {renderChatInput()}
        </Box>
    )

    // --- Render Component ---
    return (
        <Box className="conversational-agents-section" id="conversational-ai">
            <Typography variant="h3" className="conversational-agents-title">
                {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.TITLE")}
            </Typography>
            <Typography variant="subtitle1" className="conversational-agents-subtitle">
                {t("AGENTS_PAGE.CONVERSATIONAL_AGENTS.SUBTITLE")}
            </Typography>
            {renderMainTabs()}
            {isMobile ? (
                renderInnerTabsMobile()
            ) : (
                renderInnerTabs()
            )}
        </Box>
    );
};

export default ConversationalAgents;