// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import Advantages from "./Advantages.jsx";
import Benefit from "./Benefit.jsx";
import CallToAction from "../../components/CallToAction/index.jsx";
import CopilotFeatures from "./CopilotFeatures.jsx"
import Guide from "./Guide.jsx";
import Blog from "../../components/Blog/index.jsx";
import FAQs from "../../components/FAQs/index.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- Copilot Component ---
export default function Copilot() {
  const { t } = useTranslation();

  const copilotFAQs = [
    { question: t("COPILOT_PAGE.FAQS.Q1.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q1.ANSWER") },
    { question: t("COPILOT_PAGE.FAQS.Q2.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q2.ANSWER") },
    { question: t("COPILOT_PAGE.FAQS.Q3.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q3.ANSWER") },
    { question: t("COPILOT_PAGE.FAQS.Q4.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q4.ANSWER") },
    { question: t("COPILOT_PAGE.FAQS.Q5.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q5.ANSWER") },
    { question: t("COPILOT_PAGE.FAQS.Q6.QUESTION"), answer: t("COPILOT_PAGE.FAQS.Q6.ANSWER") }
  ];

  // --- Render Component ---
  return (
    <>
      <SEO
        title=""
        description=""
        url="https://intelswift.com/ai-copilot"
      />
      <Header />
      <HeroSection />
      <Advantages />
      {/* <Benefit /> */}
      
      <CopilotFeatures />
      {/* <Guide /> */}
      <CallToAction
        title={t("COPILOT_PAGE.CALL_TO_ACTION.TITLE")}
        showButton={false}
      />
      <Blog />
      <FAQs faqs={copilotFAQs} />
      <Footer />
    </>
  );
}
