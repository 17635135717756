const calculateVolumePricing = (quantity) => {
    // Define pricing tiers
    const pricingTiers = [
      { min: 1, max: 10000, unitPrice: 0.10 },
      { min: 10001, max: 20000, unitPrice: 0.09 },
      { min: 20001, max: 30000, unitPrice: 0.08 },
      { min: 30001, max: 40000, unitPrice: 0.07 },
      { min: 40001, max: 100000, unitPrice: 0.06 },
      { min: 100001, max: Infinity, unitPrice: 0.05 },
    ];
  
    // Base unit price for discount calculation
    const baseUnitPrice = 0.10;
  
    // Determine the appropriate unit price based on quantity
    let unitPrice = baseUnitPrice;
    for (const tier of pricingTiers) {
      if (quantity >= tier.min && quantity <= tier.max) {
        unitPrice = tier.unitPrice;
        break;
      }
    }
  
    // Calculate total amount and discount percentage
    const totalAmount = quantity * unitPrice;
    const discountPercent = ((baseUnitPrice - unitPrice) / baseUnitPrice) * 100;
  
    // Return the results
    return {
      unitPrice: unitPrice.toFixed(3), // Format to 3 decimal places
      discountPercent: discountPercent.toFixed(2), // Format to 2 decimal places
      totalAmount: totalAmount.toFixed(2), // Format to 2 decimal places
    };
  }

  export {
    calculateVolumePricing
}