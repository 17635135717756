import React from "react";
import { Typography, Button, Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { partnershipImages } from "../../utils/imageUtils";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} className="partnership-hero-section">
      <Grid item xs={12} md={5} className="partnership-hero-left">
        <Typography variant="h1">
          {t("PARTNERSHIP_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="subtitle1">
          {t("PARTNERSHIP_PAGE.HERO_SECTION.SUBTITLE_1")}
        </Typography>
        <Typography variant="subtitle1">
          {t("PARTNERSHIP_PAGE.HERO_SECTION.SUBTITLE_2")}
        </Typography>
        <Link
          component={RouterLink}
          to={"https://intelswift.firstpromoter.com"}
          target="_blank"
          underline="none"
        >
          <Button className="free-button" target="_blank">
            {t("PARTNERSHIP_PAGE.HERO_SECTION.CTA")}
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} md={7}>
        <img
          src={partnershipImages.partnership_img_1}
          alt={t("PARTNERSHIP_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
