import React from "react";
import { Typography, Button, Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { liveChatImages } from "../../utils/imageUtils";
import { useTranslation } from "react-i18next";

import {
  SIGN_UP_URL,
} from "./../../utils/constants";

const Hero = () => {
  const { t } = useTranslation(); 

  return (
    <Box className="live-chat-hero-section">
      <Box className="live-chat-hero-content">
        <Typography variant="h1">
          {t("LIVE_CHAT_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="h3">
        {t("LIVE_CHAT_PAGE.HERO_SECTION.DESCRIPTION")}
                </Typography>
        <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
          <Button className="free-button" target="_blank">
            {t("COMMON.SIGN_UP_FREE_BUTTON")}
          </Button>
        </Link>
        <Typography variant="subtitle1">
        {t("LIVE_CHAT_PAGE.HERO_SECTION.NO_CREDIT_CARD")}
        </Typography>
        <img 
          src={liveChatImages.liveChat_img_1} 
          alt={t("LIVE_CHAT_PAGE.HERO_SECTION.IMAGE_ALT")} 
          className="live-chat-image" 
        />
      </Box>
    </Box>
  );
}

export default Hero;
