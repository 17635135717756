// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import Visibility from "./Visibility.jsx";
import LevelUp from "../../components/LevelUp/index.jsx";
import WhyChooseIntelswift from "../../components/WhyChooseIntelswift/index.jsx";
import ProductEcosystem from "../../components/ProductEcosystem/index.jsx";
import FAQs from "../../components/FAQs/index.jsx"
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- AdvancedAnalytics Component ---
export default function AdvancedAnalytics() {
  const { t } = useTranslation();

  const whyChooseData = {
    title: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.TITLE"),
    subtitle: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.SUBTITLE"),
    benefits: [
      {
        title: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.TEAM_ANALYTICS.TITLE"),
        description: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.TEAM_ANALYTICS.DESCRIPTION"),
      },
      {
        title: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.CUSTOMER_SENTIMENT.TITLE"),
        description: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.CUSTOMER_SENTIMENT.DESCRIPTION")
      },
      {
        title: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.TITLE"),
        description: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.DESCRIPTION")
      },
      {
        title: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.TITLE"),
        description: t("ADVANCED_ANALYTICS_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.DESCRIPTION")
      }
    ],
    showButtons: false
  };

  const advancedAnalyticsFAQs = [
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q1.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q1.ANSWER") },
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q2.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q2.ANSWER") },
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q3.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q3.ANSWER") },
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q4.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q4.ANSWER") },
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q5.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q5.ANSWER") },
    { question: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q6.QUESTION"), answer: t("ADVANCED_ANALYTICS_PAGE.FAQS.Q6.ANSWER") }
  ];

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("ADVANCED_ANALYTICS_PAGE.META.TITLE")}
        description={t("ADVANCED_ANALYTICS_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/ai-analytics"
      />
      <Header />
      <HeroSection />
      <Visibility />
      <LevelUp
        title={t("ADVANCED_ANALYTICS_PAGE.CALL_TO_ACTION.TITLE")}
        subtitle={t("ADVANCED_ANALYTICS_PAGE.CALL_TO_ACTION.SUBTITLE")}
        button={t("COMMON.TRY_INTELSWIFT_FREE_BUTTON")}
      />
      <WhyChooseIntelswift
        title={whyChooseData.title}
        subtitle={whyChooseData.subtitle}
        benefits={whyChooseData.benefits}
        showButtons={whyChooseData.showButtons}
        gridMd={6}
        isProductsPage={true}
      />
      <ProductEcosystem
        isAdvancedAnalyticsPage={true}
      />
      <FAQs
        faqs={advancedAnalyticsFAQs}
      />
      <Footer />
    </>
  );
}
