import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { ecosystemImages } from "../../utils/imageUtils";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const ProductEcosystem = ({ isHomePage = false, isAdvancedAnalyticsPage = false }) => {
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

    useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%", // Adjusted start position
        toggleActions: "play none none reverse",
        once: false, // Set to false if you want the effect to replay when scrolling up
      },
    });
  
    tl.fromTo(
      sectionRef.current,
      { opacity: 0, y: 0 }, // Increased initial y value for better visibility
      {
        opacity: 1,
        y: 0,
        duration: 1.0, // Increased duration
        ease: "power1.out",
      }
    ).addLabel("startCards") // Label to better control animations
     .fromTo(
        cardsRef.current,
        { opacity: 0, y: 30 }, // Increased initial y value
        {
          opacity: 1,
          y: 0,
          duration: 1, // Increased duration for smoother appearance
          stagger: 0.2, // Increased stagger time
          ease: "power1.out",
        },
        "startCards+=0" // Delayed by 0.5s after the section appears
      );
  
    return () => {
      tl.kill(); // Clean up GSAP animations on component unmount
    };
  }, { scope: sectionRef });
  

  const products = [
    {
      title: t("PRODUCT_ECOSYSTEM.PRODUCTS.SWIFT_AI_COPILOT.TITLE"),
      description: t("PRODUCT_ECOSYSTEM.PRODUCTS.SWIFT_AI_COPILOT.DESCRIPTION"),
      icon: ecosystemImages.swift_copilot_logo,
      path: "/ai-copilot"
    },
    {
      title: t("PRODUCT_ECOSYSTEM.PRODUCTS.AI_AGENT.TITLE"),
      description: t("PRODUCT_ECOSYSTEM.PRODUCTS.AI_AGENT.DESCRIPTION"),
      icon: ecosystemImages.ai_agent_logo,
      path: "/ai-agents"
    },
    {
      title: t("PRODUCT_ECOSYSTEM.PRODUCTS.WORKFLOW.TITLE"),
      description: t("PRODUCT_ECOSYSTEM.PRODUCTS.WORKFLOW.DESCRIPTION"),
      icon: ecosystemImages.workflow_logo,
      path: "/workflows"
    },
    {
      title: t("PRODUCT_ECOSYSTEM.PRODUCTS.AI_ANALYTICS.TITLE"),
      description: t("PRODUCT_ECOSYSTEM.PRODUCTS.AI_ANALYTICS.DESCRIPTION"),
      icon: ecosystemImages.analytics_logo,
      path: ""
    },
    {
      title: t("PRODUCT_ECOSYSTEM.PRODUCTS.KNOWLEDGE_BASE.TITLE"),
      description: t("PRODUCT_ECOSYSTEM.PRODUCTS.KNOWLEDGE_BASE.DESCRIPTION"),
      icon: ecosystemImages.knowledge_base_logo,
      path: "/ai-knowledge-base"
    },
    {
      title: t(`PRODUCT_ECOSYSTEM.PRODUCTS.${isHomePage ? "HELPDESK" : "LIVE_CHAT"}.TITLE`),
      description: t(`PRODUCT_ECOSYSTEM.PRODUCTS.${isHomePage ? "HELPDESK" : "LIVE_CHAT"}.DESCRIPTION`),
      icon: ecosystemImages.live_chat_logo,
      path: "/ai-helpdesk"
    }
  ];

  return (
    <Box ref={sectionRef} className={`product-ecosystem ${isAdvancedAnalyticsPage ? "advanced-analytics-background" : ""}`}>
      <Typography variant="h3">{t("PRODUCT_ECOSYSTEM.TITLE")}</Typography>
      <Button href="/pricing" target="_blank">{t("PRODUCT_ECOSYSTEM.BUTTON")}</Button>
      <Grid container spacing={4} className="product-grid">
        {products.map((product, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            className="product-card"
            ref={(el) => (cardsRef.current[index] = el)}
          >
            <motion.div
              className="product-card-content"
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                whileHover={{ scale: 1.1, rotate: 5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <img src={product.icon} alt={product.title} className="product-icon" />
              </motion.div>
              <Typography variant="h5" className="product-title">{product.title}</Typography>
              <Typography variant="body1" className="product-desc">{product.description}</Typography>
              <motion.div
                className="learn-more"
                whileHover={{ scale: 1.05 }}
              >
                <Typography 
                  variant="body2" 
                  className="learn-more" 
                  onClick={()=>{
                    navigate(product.path)
                  }}
                >{t("PRODUCT_ECOSYSTEM.LEARN_MORE")}</Typography>
              </motion.div>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductEcosystem;