import React, { useState } from "react";
import { Box, Typography, Slider, SliderThumb } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import SyncAltIcon from '@mui/icons-material/SyncAlt'; // Custom thumb icon

// Styled Slider for custom appearance
const CustomSlider = styled(Slider)({
    color: "#A460F9",
    height: 1,
    "& .MuiSlider-thumb": {
        width: 44,
        height: 44,
        backgroundColor: "rgba(148, 87, 255, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        "&:hover, &.Mui-focusVisible": {
            boxShadow: "0px 0px 8px rgba(164, 96, 249, 0.8)",
        },
    },
    "& .MuiSlider-mark": {
        height: 12,
        width: 1,
    },
    "& .MuiSlider-markLabel": {
        fontSize: "14px",
        fontWeight: 400,
        color: "rgba(255, 255, 255, 0.6)",
        top: "40px",
    },
    "& .MuiSlider-valueLabel": {
        backgroundColor: "transparent",
        color: "rgba(255, 255, 255, 1)",
        fontSize: "36px",
        fontWeight: 700,
        "&:before": {
            display: "none",
        },
    },
});

const ThumbWithIcon = (props) => {
    const { children, ...other } = props;
  
    return (
      <SliderThumb {...other}>
        <SyncAltIcon sx={{ color: "white", fontSize: 20 }} />
        {children}
      </SliderThumb>
    );
  };

const CommissionSlider = () => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(19);

    const commissionData = [
        { contracts: 1, incomePerContract: 300, total: 300, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STARTER"), commission: "20%" },
        { contracts: 2, incomePerContract: 300, total: 600, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STARTER"), commission: "20%" },
        { contracts: 5, incomePerContract: 300, total: 1500, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STARTER"), commission: "20%" },
        { contracts: 7, incomePerContract: 300, total: 2100, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STARTER"), commission: "20%" },
        { contracts: 9, incomePerContract: 300, total: 2700, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STARTER"), commission: "20%" },
        { contracts: 10, incomePerContract: 375, total: 3075, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.GROWTH"), commission: "25%" },
        { contracts: 12, incomePerContract: 375, total: 3825, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.GROWTH"), commission: "25%" },
        { contracts: 15, incomePerContract: 375, total: 4950, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.GROWTH"), commission: "25%" },
        { contracts: 17, incomePerContract: 375, total: 5700, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.GROWTH"), commission: "25%" },
        { contracts: 19, incomePerContract: 375, total: 6450, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.GROWTH"), commission: "25%" },
        { contracts: 20, incomePerContract: 450, total: 6900, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 25, incomePerContract: 450, total: 9150, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 30, incomePerContract: 450, total: 11400, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 35, incomePerContract: 450, total: 13650, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 40, incomePerContract: 450, total: 15900, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 49, incomePerContract: 450, total: 19950, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.BOOSTER"), commission: "30%" },
        { contracts: 50, incomePerContract: 525, total: 20475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 60, incomePerContract: 525, total: 25725, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 70, incomePerContract: 525, total: 30975, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 80, incomePerContract: 525, total: 36225, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 90, incomePerContract: 525, total: 41475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 99, incomePerContract: 525, total: 46200, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.EXPERT"), commission: "35%" },
        { contracts: 100, incomePerContract: 600, total: 47475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 150, incomePerContract: 600, total: 77475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 200, incomePerContract: 600, total: 107475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 300, incomePerContract: 600, total: 167475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 400, incomePerContract: 600, total: 227475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 499, incomePerContract: 600, total: 286875, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.LEADER"), commission: "40%" },
        { contracts: 500, incomePerContract: 600, total: 287475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 600, incomePerContract: 600, total: 354975, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 700, incomePerContract: 675, total: 422475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 800, incomePerContract: 675, total: 489975, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 900, incomePerContract: 675, total: 557475, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 999, incomePerContract: 675, total: 624300, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.STAR"), commission: "45%" },
        { contracts: 1000, incomePerContract: 750, total: 625050, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.SPACE"), commission: "50%" },
        { contracts: 1200, incomePerContract: 600, total: 745050, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.SPACE"), commission: "50%" },
        { contracts: 1500, incomePerContract: 600, total: 925050, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.SPACE"), commission: "50%" },
        { contracts: 1800, incomePerContract: 600, total: 1105050, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.SPACE"), commission: "50%" },
        { contracts: 2000, incomePerContract: 600, total: 1225050, category: t("PARTNERSHIP_PAGE.SLIDER_SECTION.CATEGORIES.SPACE"), commission: "50%" },
    ];

    const currentData = commissionData[index];

    const seen = new Set();
    const marks = commissionData
        .map((item, idx) => {
            if (!seen.has(item.commission)) {
                seen.add(item.commission);
                return {
                    value: idx,
                    label: item.category,
                };
            }
            return null;
        })
        .filter(Boolean);

    return (
        <Box className="commission-section">
            <Box className="commission-container">
                {/* Header */}
                <Typography variant="h3">
                {t("PARTNERSHIP_PAGE.SLIDER_SECTION.TITLE")}
                </Typography>
                <Typography variant="subtitle1">
                {t("PARTNERSHIP_PAGE.SLIDER_SECTION.DESCRIPTION")}
                </Typography>
                <Box position="relative" width="100%" className="slider-box">
                    {/* Commission labels above */}
                    <Box position="absolute" top="-35px" left={0} right={0} display="flex" justifyContent="space-between">
                        {marks.map((mark, i) => (
                            <Typography
                                key={i}
                                variant="caption"
                                sx={{
                                    fontSize: "14px",
                                    color: "rgba(255, 255, 255, 0.6)",
                                    transform: "translateX(-50%)",
                                    position: "absolute",
                                    left: `${(mark.value / (commissionData.length - 1)) * 100}%`,
                                }}
                            >
                                {commissionData[mark.value].commission}
                            </Typography>
                        ))}
                    </Box>

                    {/* The actual slider */}
                    <CustomSlider
                        value={index}
                        onChange={(e, newValue) => setIndex(newValue)}
                        step={1}
                        min={0}
                        max={commissionData.length - 1}
                        marks={marks}
                        valueLabelDisplay="on"
                        valueLabelFormat={() => `$${currentData?.total ?? 0}`}
                        components={{ Thumb: ThumbWithIcon }}
                    />
                </Box>
            </Box>
            <Typography variant="h5">
            {t("PARTNERSHIP_PAGE.SLIDER_SECTION.PROMO_NOTE")}
                </Typography>
        </Box>
    );
};

export default CommissionSlider;
