import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { advancedAnalyticsImages } from "../../utils/imageUtils";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Box className="advanced-analytics-hero-section">
      <Box className="advanced-analytics-hero-content">
        <Typography variant="h1">
          {t("ADVANCED_ANALYTICS_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="h3">
          {t("ADVANCED_ANALYTICS_PAGE.HERO_SECTION.DESCRIPTION")}
        </Typography>
        <Button target="_blank">
          {t("COMMON.SIGN_UP_FREE_BUTTON")}
        </Button>
        <Typography variant="subtitle1">
          {t("ADVANCED_ANALYTICS_PAGE.HERO_SECTION.NO_CREDIT_CARD")}
        </Typography>
        <img
          src={advancedAnalyticsImages.advancedAnalytics_img_1}
          alt={t("ADVANCED_ANALYTICS_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
          className="advanced-analytics-image"
        />
      </Box>
    </Box>
  );
}

export default Hero;
