// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Card } from "@mui/material";
import { useTranslation } from "react-i18next"; 
import { BsBag } from "react-icons/bs";
import { IoIosLaptop } from "react-icons/io";
import { CiCamera } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { BsGraphUpArrow } from "react-icons/bs";

// --- PartnerProgram Component ---
const PartnerProgram = () => {
    const { t } = useTranslation(); 

    const features = [
        {
          title: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.BUSINESS_CONSULTANTS.TITLE"),
          description: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.BUSINESS_CONSULTANTS.DESCRIPTION"),
          icon: <BsBag className="steps-icon" />,
          bgColor: "rgba(165, 87, 255, 1)"
        },
        {
          title: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.SOFTWARE_EXPERTS.TITLE"),
          description: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.SOFTWARE_EXPERTS.DESCRIPTION"),
          icon: <IoIosLaptop className="steps-icon" />,
          bgColor: "rgba(64, 117, 221, 1)"
        },
        {
          title: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.CONTENT_CREATORS.TITLE"),
          description: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.CONTENT_CREATORS.DESCRIPTION"),
          icon: <CiCamera className="steps-icon" />,
          bgColor: "rgba(223, 119, 156, 1)"
        },
        {
          title: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.REVIEWERS.TITLE"),
          description: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.REVIEWERS.DESCRIPTION"),
          icon: <CiStar className="steps-icon" />,
          bgColor: "rgba(87, 179, 255, 1)"
        },
        {
          title: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.AGENCIES.TITLE"),
          description: t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.FEATURES.AGENCIES.DESCRIPTION"),
          icon: <BsGraphUpArrow className="steps-icon" />,
          bgColor: "rgba(202, 133, 237, 1)"
        }
      ];

    return (
        <Box className="partner-program-container">
            <Typography variant="h3">
            {t("PARTNERSHIP_PAGE.PARTNER_PROGRAM.TITLE")}
            </Typography>
            <Grid container spacing={4}>
                {features.map((feature, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="partner-program-card"
                    >
                        <Card className="partner-program-card-content">
                            <Box
                                className="partner-program-circle"
                                style={{ background: feature.bgColor }}
                            >
                                {feature.icon}
                            </Box>
                            <Typography variant="h5" className="partner-program-title">
                                {feature.title}
                            </Typography>
                            <Typography variant="body1" className="partner-program-description">
                                {feature.description}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PartnerProgram;
