// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { copilotImages } from "../../utils/imageUtils";
import {
    SIGN_UP_URL,
    GET_DEMO_URL,
  } from "./../../utils/constants";

// --- CopilotFeatures Component ---
const CopilotFeatures = () => {
    const { t } = useTranslation();

    return (
        <Box className="copilot-features-container">
            <Typography variant="h3">
                {t("COPILOT_PAGE.FEATURES.TITLE")}
            </Typography>
            <Grid container spacing={4} className="copilot-features-grid-container">
                <Grid item xs={12} md={5} className="copilot-features-left">
                    <Typography variant="h3">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.GENERATE_PROMPTS.TITLE")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.GENERATE_PROMPTS.DESCRIPTION")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} className="copilot-features-image-container">
                    <img
                        className="copilot-features-image"
                        src={copilotImages.copilot_img_1}
                        alt={t("COPILOT_PAGE.FEATURES.FEATURES_LIST.GENERATE_PROMPTS.IMAGE_ALT")}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} className="copilot-features-grid-container">
                <Grid item xs={12} md={7} className="copilot-features-image-container">
                    <img
                        className="copilot-features-image"
                        src={copilotImages.copilot_img_2}
                        alt={t("COPILOT_PAGE.FEATURES.FEATURES_LIST.DATA_VISUALIZATION.IMAGE_ALT")}
                    />
                </Grid>
                <Grid item xs={12} md={5} className="copilot-features-left">
                    <Typography variant="h3">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.DATA_VISUALIZATION.TITLE")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.DATA_VISUALIZATION.DESCRIPTION")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4} className="copilot-features-grid-container">
                <Grid item xs={12} md={7} className="copilot-features-image-container">
                    <img
                        className="copilot-features-image"
                        src={copilotImages.copilot_img_3}
                        alt={t("COPILOT_PAGE.FEATURES.FEATURES_LIST.DATA_VISUALIZATION.IMAGE_ALT")}
                    />
                </Grid>
                <Grid item xs={12} md={5} className="copilot-features-left">
                    <Typography variant="h3">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.AI_SUMMARY.TITLE")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.AI_SUMMARY.DESCRIPTION")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4} className="copilot-features-grid-container">
                <Grid item xs={12} md={5} className="copilot-features-left">
                    <Typography variant="h3">
                    {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.AI_ONBOARDING.TITLE")}
                    </Typography>
                    <Typography variant="subtitle1">
                    {t("COPILOT_PAGE.FEATURES.FEATURES_LIST.AI_ONBOARDING.DESCRIPTION")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} className="copilot-features-image-container">
                    <img
                        className="copilot-features-image"
                        src={copilotImages.copilot_img_4}
                        alt={t("COPILOT_PAGE.FEATURES.FEATURES_LIST.AI_ONBOARDING.IMAGE_ALT")}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" spacing={2} className="copilot-features-stack-buttons">
                <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
                    <Button variant="contained" className="copilot-features-demo-button">
                    {t("COMMON.REQUEST_DEMO_BUTTON")}
                    </Button>
                </Link>

                <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                    <Button variant="outlined" className="copilot-features-trial-button">
                    {t("COMMON.START_TRIAL_BUTTON")}
                    </Button>
                </Link>
            </Stack>
        </Box>
    );
};

export default CopilotFeatures;
