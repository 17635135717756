// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import {
    SIGN_UP_URL,
    GET_DEMO_URL
  } from "./../../utils/constants";

// --- Image Utilities ---
import { liveChatImages } from "../../utils/imageUtils";

// --- Features Component ---
const Features = () => {
    const { t } = useTranslation();

    return (
        <Box className="live-chat-features-section">
            <Box className="live-chat-features-container">
                <Grid container spacing={4} className="live-chat-features-grid-container">
                    <Grid item xs={12} md={6} className="live-chat-features-left">
                        <Typography variant="h3">
                            {t("LIVE_CHAT_PAGE.FEATURES.HUMAN_HANDOFF.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("LIVE_CHAT_PAGE.FEATURES.HUMAN_HANDOFF.DESCRIPTION")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="live-chat-features-image-container">
                        <img
                            className="live-chat-features-image"
                            src={liveChatImages.liveChat_img_2}
                            alt={t("LIVE_CHAT_PAGE.FEATURES.HUMAN_HANDOFF.IMAGE_ALT")}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="live-chat-features-container">
                <Grid container spacing={4} className="live-chat-features-grid-container">
                    <Grid item xs={12} md={6} className="copilot-features-image-container">
                        <img
                            className="live-chat-features-image"
                            src={liveChatImages.liveChat_img_3}
                            alt={t("LIVE_CHAT_PAGE.FEATURES.ALL_CHANNELS.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="live-chat-features-left">
                        <Typography variant="h3">
                            {t("LIVE_CHAT_PAGE.FEATURES.ALL_CHANNELS.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("LIVE_CHAT_PAGE.FEATURES.ALL_CHANNELS.DESCRIPTION")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className="live-chat-features-container">
            <Grid container spacing={4} className="live-chat-features-grid-container">
                <Grid item xs={12} md={6} className="live-chat-features-left">
                    <Typography variant="h3">
                        {t("LIVE_CHAT_PAGE.FEATURES.AI_HUMAN_EXPERTISE.TITLE")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("LIVE_CHAT_PAGE.FEATURES.AI_HUMAN_EXPERTISE.DESCRIPTION")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className="live-chat-features-image-container">
                    <img
                        className="live-chat-features-image"
                        src={liveChatImages.liveChat_img_4}
                        alt={t("LIVE_CHAT_PAGE.FEATURES.AI_HUMAN_EXPERTISE.IMAGE_ALT")}
                    />
                </Grid>
            </Grid>
            </Box>
            <Box className="live-chat-features-container">
                <Grid container spacing={4} className="live-chat-features-grid-container">
                    <Grid item xs={12} md={6} className="copilot-features-image-container">
                        <img
                            className="live-chat-features-image"
                            src={liveChatImages.liveChat_img_5}
                            alt={t("LIVE_CHAT_PAGE.FEATURES.CUSTOM_FIELDS.IMAGE_ALT")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="live-chat-features-left">
                        <Typography variant="h3">
                            {t("LIVE_CHAT_PAGE.FEATURES.CUSTOM_FIELDS.TITLE")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("LIVE_CHAT_PAGE.FEATURES.CUSTOM_FIELDS.DESCRIPTION")}
                        </Typography>
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={2} className="live-chat-features-stack-buttons">
                    <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
                        <Button variant="contained" className="live-chat-features-demo-button">
                            {t("COMMON.REQUEST_DEMO_BUTTON")}
                        </Button>
                    </Link>
                    <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                        <Button variant="outlined" className="live-chat-features-trial-button">
                            {t("COMMON.START_TRIAL_BUTTON")}
                        </Button>
                    </Link>
            </Stack>
            </Box>
        </Box>
    );
};

export default Features;
