import React from "react";
import { Typography, Button, Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { knowledgeBaseImages } from "../../utils/imageUtils";
import {
    SIGN_UP_URL,
    GET_DEMO_URL
  } from "./../../utils/constants";

const Hero = () => {
    const { t } = useTranslation();

    return (
        <Box className="knowledge-base-hero-section">
            <Box className="knowledge-base-hero-content">
                <Typography variant="h1">
                {t("KNOWLEDGE_BASE_PAGE.HERO_SECTION.TITLE")}
                </Typography>
                <Typography variant="h3">
                {t("KNOWLEDGE_BASE_PAGE.HERO_SECTION.DESCRIPTION")}
                </Typography>
                <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                    <Button target="_blank">
                        {t("COMMON.SIGN_UP_FREE_BUTTON")}
                    </Button>
                </Link>
                <Typography variant="subtitle1">
                {t("KNOWLEDGE_BASE_PAGE.HERO_SECTION.NO_CREDIT_CARD")}
                </Typography>
                <img
                    src={knowledgeBaseImages.knowledge_base_img_1}
                    alt={t("KNOWLEDGE_BASE_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
                    className="knowledge-base-image"
                />
            </Box>
        </Box>
    );
}

export default Hero;
