// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Grid, Card } from "@mui/material";
import { useTranslation } from "react-i18next"; 
import { BsGraphUpArrow } from "react-icons/bs";
import { LuTrendingDown } from "react-icons/lu";
import { HiOutlineFaceSmile } from "react-icons/hi2";

// --- Advantages Component ---
const Advantages = () => {
    const { t } = useTranslation();

    const advantages = [
        { 
            key: "BOOST_EFFICIENCY",
            icon: <BsGraphUpArrow className="advantages-icon" />,
            bgColor: "rgba(64, 117, 221, 1)" 
        },
        { 
            key: "CUSTOMER_EXPERIENCE",
            icon: <HiOutlineFaceSmile className="advantages-icon" />,
            bgColor: "rgba(148, 87, 255, 1)" 
        },
        { 
            key: "LESS_BURNOUT",
            icon: <LuTrendingDown className="advantages-icon" />,
            bgColor: "rgba(87, 179, 255, 1)"
        },
    ];

    return (
        <Box className="advantages-container">
            <Typography variant="h3">
                {t("COPILOT_PAGE.ADVANTAGES.TITLE")}
            </Typography>
            <Typography variant="subtitle1">
                {t("COPILOT_PAGE.ADVANTAGES.SUBTITLE")}
            </Typography>
            <Grid container spacing={4}>
                {advantages.map((advantage, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="advantages-card"
                    >
                        <Card className="advantages-card-content">
                            <Box 
                                className="advantages-circle"
                                style={{ background: advantage.bgColor }}
                            >
                                {advantage.icon}
                            </Box>
                            <Typography variant="h5" className="advantages-title">
                                {t(`COPILOT_PAGE.ADVANTAGES.${advantage.key}.TITLE`)}
                            </Typography>
                            <Typography variant="body1" className="advantages-description">
                                {t(`COPILOT_PAGE.ADVANTAGES.${advantage.key}.DESCRIPTION`)}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Advantages;