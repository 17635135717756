// --- Third Party Libraries ---
import React, { useState, useMemo } from "react";
import { Typography, Box, Button, Grid, Tabs, Tab, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { agentsImages } from "../../utils/imageUtils";

// --- Render VerticalAgents ---
const VerticalAgents = () => {
    // --- State Hooks ---
    const [activeIndex, setActiveIndex] = useState(0);
    const isMobile = useMediaQuery("(max-width:768px)");
    const { t } = useTranslation();

    // --- Tab Data ---
    const agentsData = useMemo(() => [
        {
            title: t("AGENTS_PAGE.VERTICAL.AGENTS.DATA_ANALYST.TITLE"),
            description: t("AGENTS_PAGE.VERTICAL.AGENTS.DATA_ANALYST.DESCRIPTION"),
            icons: [agentsImages.gpt_logo, agentsImages.intelswift_logo, agentsImages.leaf_img],
            img: agentsImages.agents_img_1,
        },
        {
            title: t("AGENTS_PAGE.VERTICAL.AGENTS.SENTIMENT_ANALYST.TITLE"),
            description: t("AGENTS_PAGE.VERTICAL.AGENTS.SENTIMENT_ANALYST.DESCRIPTION"),
            icons: [agentsImages.gpt_logo, agentsImages.intelswift_logo, agentsImages.leaf_img],
            img: agentsImages.agents_img_2,
        },
        {
            title: t("AGENTS_PAGE.VERTICAL.AGENTS.SLA_ENFORCER.TITLE"),
            description: t("AGENTS_PAGE.VERTICAL.AGENTS.SLA_ENFORCER.DESCRIPTION"),
            icons: [agentsImages.gpt_logo, agentsImages.intelswift_logo, agentsImages.leaf_img, agentsImages.zendesk_logo],
            img: agentsImages.agents_img_3
        },
        {
            title: t("AGENTS_PAGE.VERTICAL.AGENTS.LEAD_SCORING.TITLE"),
            description: t("AGENTS_PAGE.VERTICAL.AGENTS.LEAD_SCORING.DESCRIPTION"),
            icons: [agentsImages.gpt_logo, agentsImages.intelswift_logo, agentsImages.leaf_img, agentsImages.hubspot_logo],
            img: agentsImages.agents_img_4
        },
    ], [t]);

    const activeAgent = agentsData[activeIndex];

    // --- Tab Handlers ---
    const handleTabChange = (_e, newValue) => {
        setActiveIndex(newValue);
    };

    return (
        <Box className="vertical-agents-section" id="vertical-ai">
            <Typography variant="h3" className="vertical-agents-title">
                {t("AGENTS_PAGE.VERTICAL.TITLE")}
            </Typography>
            <Typography variant="subtitle1" className="vertical-agents-subtitle">
                {t("AGENTS_PAGE.VERTICAL.SUBTITLE")}
            </Typography>
            {isMobile ? (
                <>
                    <Box
                        sx={{
                            textAlign: "left",
                            width: "100%",
                            borderBottom: "1px solid rgba(204, 210, 231, 1)",
                            overflowX: "auto",
                        }}
                    >
                        <Tabs
                            value={activeIndex}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                sx: {
                                    height: "2px",
                                    borderRadius: "2px",
                                    background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                                },
                            }}
                            sx={{
                                minWidth: "max-content",
                                "& .MuiTabs-flexContainer": {
                                    gap: "8px",
                                },
                                "& .MuiTab-root": {
                                    textTransform: "none",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#6B737E",
                                    minWidth: "fit-content",
                                    paddingX: 2,
                                    "&.Mui-selected": {
                                        background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        fontWeight: 700,
                                    },
                                },
                            }}
                        >
                            {agentsData.map((agent, index) => (
                                <Tab key={index} label={agent.title} />
                            ))}
                        </Tabs>
                    </Box>
                    <Box>
                        <Typography
                            variant="body2"
                            className="vertical-agent-description"
                        >
                            {agentsData[activeIndex]?.description}
                        </Typography>
                    </Box>
                    <img
                        src={activeAgent.img}
                        alt={activeAgent.title}
                        className="vertical-agent-right-img"
                    />
                </>
            ) : (
                <Grid container spacing={4} className="vertical-agents-grid-container">
                    <Grid item xs={12} md={4}>
                        <Tabs
                            value={activeIndex}
                            onChange={handleTabChange}
                            orientation="vertical"
                            TabIndicatorProps={{ style: { display: "none" } }}
                            sx={{
                                "& .MuiTab-root": {
                                    alignItems: "flex-start",
                                    textTransform: "none",
                                    padding: "0",
                                    minHeight: "auto",
                                    justifyContent: "flex-start",
                                },
                            }}
                        >
                            {agentsData.map((agent, index) => (
                                <Tab
                                    key={index}
                                    disableRipple
                                    label={
                                        <Box
                                            sx={{
                                                textAlign: "left",
                                                width: "100%",
                                                borderBottom: "1px solid",
                                                borderImageSource: "linear-gradient(89.8deg, #CCD2E7 0.17%, rgba(204, 210, 231, 0) 101.02%)",
                                                borderImageSlice: 1,
                                                minHeight: "61px"
                                            }}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                    background: activeIndex === index
                                                        ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                                                        : "none",
                                                    WebkitBackgroundClip: activeIndex === index ? "text" : "unset",
                                                    WebkitTextFillColor: activeIndex === index ? "transparent" : "#1F2430",
                                                    borderTop: activeIndex === index
                                                        ? "2px solid #8D2BFF"
                                                        : "2px solid transparent",
                                                    paddingTop: "8px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {agent.title}
                                            </Typography>
                                            {activeIndex === index && (
                                                <>
                                                    <Box className="vertical-agent-description-wrapper">
                                                        <Typography variant="body2" className="vertical-agent-description">
                                                            {agent.description}
                                                        </Typography>
                                                    </Box>
                                                    <Stack direction="row" className="circle-stack">
                                                        {agent.icons.map((iconSrc, iconIdx) => (
                                                            <Box key={iconIdx} className={`circle circle-${iconIdx + 1}`}>
                                                                <img src={iconSrc} alt={`Icon ${iconIdx + 1}`} width={28} height={28} />
                                                            </Box>
                                                        ))}
                                                    </Stack>
                                                </>
                                            )}
                                        </Box>
                                    }
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <img
                            src={activeAgent.img}
                            alt={activeAgent.title}
                            className="vertical-agent-right-img"
                        />
                    </Grid>
                </Grid>
            )}
            {/* <Button className="marketplace-button" target="_blank">
                Visit Marketplace
            </Button> */}
        </Box>
    );
};

export default VerticalAgents;
