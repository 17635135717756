// --- Third Party Libraries ---
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import Features from "./Features.jsx";
import DistributeContent from "./DistributeContent.jsx";
import KnowledgeAutomation from "./KnowledgeAutomation.jsx";
import LevelUp from "../../components/LevelUp/index.jsx";
import ProductEcosystem from "../../components/ProductEcosystem/index.jsx";
import Planet from "../../components/Planet/index.jsx";
import Blog from "../../components/Blog/index.jsx";
import FAQs from "../../components/FAQs/index.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- KnowledgeBase Component ---
export default function KnowledgeBase() {
  const { t } = useTranslation();

  const knowledgeBaseFAQs = useMemo(() => [
    {
      question: t("KNOWLEDGE_BASE_PAGE.FAQS.Q1.QUESTION"),
      answer: t("KNOWLEDGE_BASE_PAGE.FAQS.Q1.ANSWER")
    }
  ], [t]);

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("KNOWLEDGE_BASE_PAGE.META.TITLE")}
        description={t("KNOWLEDGE_BASE_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/ai-knowledge-base"
      />
      <Header />
      <HeroSection />
      <Features />
      <DistributeContent />
      <KnowledgeAutomation />
      <LevelUp
        title={t("KNOWLEDGE_BASE_PAGE.CALL_TO_ACTION.TITLE")}
        button={t("COMMON.FREE_TRIAL_BUTTON")}
        isWorkflowsPage={true}
      />
      <ProductEcosystem />
      <Planet />
      <Blog />
      <FAQs
        faqs={knowledgeBaseFAQs}
      />
      <Footer />
    </>
  );
}
