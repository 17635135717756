import React, { useState } from "react";
import { Typography, Box, Tabs, Tab, Grid, Card, CardMedia, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { agentsImages } from "../../utils/imageUtils";

const HireAgents = () => {
    const [activeTab, setActiveTab] = useState(0);
    const isMobile = useMediaQuery("(max-width:768px)");
    const { t } = useTranslation();

    const agentsData = [
        {
            label: t("AGENTS_PAGE.HIRE_AGENTS.TABS.CHANNELS"),
            cards: [
              {
                title: "Web Messenger",
                subtitle: "Deliver real-time, AI-assisted support directly on your website for instant customer engagement.",
                img: agentsImages.agents_img_18,
                alt: "Web Messenger"
              },
              {
                style: {height: "100% !important"},
                title: "Email",
                subtitle: "Ensure every customer email gets a fast, intelligent response—seamlessly managed by AI Agents.",
                img: agentsImages.agents_img_19,
                alt: "Email"
              },
              {
                title: "Telegram",
                subtitle: "Meet your customers where they are. AI Agents provide consistent support via Telegram chats.",
                img: agentsImages.agents_img_20,
                alt: "Telegram"
              },
              {
                title: "WhatsApp",
                subtitle: "Engage users on WhatsApp with smart, always-on assistance—same great experience as any other channel.",
                img: agentsImages.agents_img_21,
                alt: "WhatsApp"
              },
              {
                title: "Instagram",
                subtitle: "Offer automated DM replies, brand-consistent support on Instagram with ease.",
                img: agentsImages.agents_img_22,
                alt: "Instagram"
              },
              {
                title: "FB Messenger",
                subtitle: "Handle Messenger conversations with the same AI-driven quality as your other support channels.",
                img: agentsImages.agents_img_23,
                alt: "FB Messenger"
              },
              {
                title: "Slack (Beta)",
                subtitle: "Empower internal or B2B clients through AI-powered support inside Slack—consistent and reliable.",
                img: agentsImages.agents_img_24,
                alt: "Slack (Beta)"
              },
              {
                title: "MS Teams (Beta)",
                subtitle: "Bring intelligent support to your enterprise workflows with AI Agents active right inside Teams.",
                img: agentsImages.agents_img_25,
                alt: "MS Teams"
              }
            ]
          },
        {
            label: t("AGENTS_PAGE.HIRE_AGENTS.TABS.KNOWLEDGE"),
            cards: [
                { 
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.0.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.0.SUBTITLE"),
                    img: agentsImages.agents_img_16,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.0.ALT")
                },
                {
                    style: {height: "-webkit-fill-available !important"},
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.1.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.1.SUBTITLE"),
                    img: agentsImages.agents_img_17,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.KNOWLEDGE.1.ALT")
                }
            ]
        },
        {
            label: t("AGENTS_PAGE.HIRE_AGENTS.TABS.TOOLS"),
            cards: [
                {
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.TOOLS.0.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.TOOLS.0.SUBTITLE"),
                    img: agentsImages.agents_img_11,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.TOOLS.0.ALT"),
                }
            ]
        },
        {
            label: t("AGENTS_PAGE.HIRE_AGENTS.TABS.INTEGRATIONS"),
            cards: [
                {
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.INTEGRATIONS.0.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.INTEGRATIONS.0.SUBTITLE"),
                    img: agentsImages.agents_img_12,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.INTEGRATIONS.0.ALT"),
                }
            ]
        },
        {
            label: t("AGENTS_PAGE.HIRE_AGENTS.TABS.DATA_INSIGHTS"),
            cards: [
                {
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.0.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.0.SUBTITLE"),
                    img: agentsImages.agents_img_13,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.0.ALT"),
                },
                {
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.1.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.1.SUBTITLE"),
                    img: agentsImages.agents_img_14,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.1.ALT"),
                },
                {
                    title: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.2.TITLE"),
                    subtitle: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.2.SUBTITLE"),
                    img: agentsImages.agents_img_15,
                    alt: t("AGENTS_PAGE.HIRE_AGENTS.CARDS.DATA_INSIGHTS.2.ALT"),
                }
            ]
        }
    ];

    const handleTabChange = (_e, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box className="hire-agents-section">
          <Typography variant="h3" className="hire-agents-title">
            {t("AGENTS_PAGE.HIRE_AGENTS.TITLE")}
          </Typography>
          <Typography variant="subtitle1" className="hire-agents-subtitle">
            {t("AGENTS_PAGE.HIRE_AGENTS.SUBTITLE")}
          </Typography>
      
          {isMobile ? (
            <>
              {/* Mobile Tabs */}
              <Box
                sx={{
                  textAlign: "left",
                  width: "100%",
                  borderBottom: "1px solid rgba(204, 210, 231, 1)",
                  overflowX: "auto",
                }}
              >
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    sx: {
                      height: "2px",
                      borderRadius: "2px",
                      background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                    },
                  }}
                  sx={{
                    minWidth: "max-content",
                    "& .MuiTabs-flexContainer": {
                      gap: "8px",
                    },
                    "& .MuiTab-root": {
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#6B737E",
                      minWidth: "fit-content",
                      paddingX: 2,
                      "&.Mui-selected": {
                        background: "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: 700,
                      },
                    },
                  }}
                >
                  {agentsData.map((agent, index) => (
                    <Tab key={index} label={agent.label} />
                  ))}
                </Tabs>
              </Box>
      
              {/* Mobile Content */}
              <Card className="hire-agents-card-content">
                {agentsData[activeTab].cards.map((card, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", flexDirection: "column", height: "100%" }}
                  >
                    <CardMedia
                      component="img"
                      src={card.img}
                      alt={card.alt}
                      sx={{
                        background: "rgba(236, 238, 245, 1)",
                        padding: "16px",
                        ...card.style
                      }}
                    />
                     <Typography variant="subtitle2" className="hire-agents-card-title">
                      {card.title}
                    </Typography>
                    <Typography variant="body2" className="hire-agents-card-description">
                      {card.subtitle}
                    </Typography>
                  </Box>
                ))}
              </Card>
            </>
          ) : (
            <Grid container spacing={4} className="hire-agents-grid-container">
              <Grid item xs={12} md={3}>
                {/* Desktop Vertical Tabs */}
                <Tabs
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleTabChange}
                  TabIndicatorProps={{ style: { display: "none" } }}
                  sx={{
                    "& .MuiTab-root": {
                      alignItems: "flex-start",
                      textTransform: "none",
                      padding: "0",
                      minHeight: "auto",
                      justifyContent: "flex-start",
                    },
                  }}
                >
                  {agentsData.map((agent, index) => (
                    <Tab
                      key={index}
                      label={
                        <Box
                          sx={{
                            textAlign: "left",
                            width: "100%",
                            borderBottom: "1px solid",
                            borderImageSource:
                              "linear-gradient(89.8deg, #CCD2E7 0.17%, rgba(204, 210, 231, 0) 101.02%)",
                            borderImageSlice: 1,
                            minHeight: "61px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "16px",
                              background:
                                activeTab === index
                                  ? "linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%)"
                                  : "none",
                              WebkitBackgroundClip: activeTab === index ? "text" : "unset",
                              WebkitTextFillColor: activeTab === index ? "transparent" : "#1F2430",
                              borderTop:
                                activeTab === index
                                  ? "2px solid #8D2BFF"
                                  : "2px solid transparent",
                              paddingTop: "8px",
                              display: "inline-block",
                            }}
                          >
                            {agent.label}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
              </Grid>
      
              <Grid item xs={12} md={9}>
                <Card className="hire-agents-card-content">
                  {agentsData[activeTab].label === t("AGENTS_PAGE.HIRE_AGENTS.TABS.DATA_INSIGHTS") ? (
                    <Grid container spacing={2}>
                      {agentsData[activeTab].cards.slice(0, 2).map((card, index) => (
                        <Grid item xs={12} md={6} key={index}>
                          <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Typography variant="subtitle2" className="hire-agents-card-title">
                              {card.title}
                            </Typography>
                            <Typography variant="body2" className="hire-agents-card-description">
                              {card.subtitle}
                            </Typography>
                            <CardMedia
                              component="img"
                              src={card.img}
                              alt={card.alt}
                              sx={{
                                background: "rgba(236, 238, 245, 1)",
                                padding: "16px",
                                height: "100%",
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12} key={2}>
                        <Box>
                          <CardMedia
                            component="img"
                            src={agentsData[activeTab].cards[2].img}
                            alt=""
                            sx={{
                              background: "rgba(236, 238, 245, 1)",
                              padding: "16px",
                            }}
                          />
                          <Typography variant="subtitle2" className="hire-agents-card-title">
                            {agentsData[activeTab].cards[2].title}
                          </Typography>
                          <Typography variant="body2" className="hire-agents-card-description">
                            {agentsData[activeTab].cards[2].subtitle}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : agentsData[activeTab].label === t("AGENTS_PAGE.HIRE_AGENTS.TABS.CHANNELS") ? (
                    <Grid container spacing={2}>
                      {agentsData[activeTab].cards.map((card, index) => {
                        let md = 6; // First row: 2 items
                        if (index >= 2 && index <= 4) md = 4; // Second row: 3 items
                        else if (index >= 5) md = 4; // Third row: 3 items
      
                        return (
                          <Grid item xs={12} md={md} key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                              }}
                            >
                              <CardMedia
                                component="img"
                                src={card.img}
                                alt={card.alt}
                                sx={{
                                  background: "rgba(236, 238, 245, 1)",
                                  padding: "16px",
                                  ...card.style                                }}
                              />
                              <Typography variant="subtitle2" className="hire-agents-card-title">
                                {card.title}
                              </Typography>
                              <Typography variant="body2" className="hire-agents-card-description">
                                {card.subtitle}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Stack direction="row" spacing={2}>
                      {agentsData[activeTab].cards.map((card, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
                        >
                          <CardMedia
                            component="img"
                            src={card.img}
                            alt={card.alt}
                            sx={{
                              background: "rgba(236, 238, 245, 1)",
                              padding: "16px",
                              ...card.style
                            }}
                          />
                          <Typography variant="subtitle2" className="hire-agents-card-title">
                            {card.title}
                          </Typography>
                          <Typography variant="body2" className="hire-agents-card-description">
                            {card.subtitle}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  )}
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      );
    }      

export default HireAgents;
