import React, { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
// --- CSS ---
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const CallToAction = ({ title, subtitle, showButton }) => {
          const sectionRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useGSAP(() => {
        gsap.fromTo(
          sectionRef.current,
          { opacity: 0, y: 0 },
          {
            opacity: 1,
            y: 0,
            duration: 2.5,
            ease: "power2.out",
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "top 100%",
              toggleActions: "play none none reverse",
              once: true,
            },
          }
        );
      }, { scope: sectionRef });

    return (
        <Box className="cta-container" ref={sectionRef}>
            <Typography variant="h3">
                {title}
            </Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
            {showButton && (
                <Button onClick={() => { navigate('/pricing') }}>
                    {t("COMMON.SEE_PRICING_BUTTON")}
                </Button>
            )}
        </Box>
    );
};

export default CallToAction;