// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import WhyChooseIntelswift from "../../components/WhyChooseIntelswift/index.jsx";
import Features from "./Features.jsx";
import Channels from "./Channels.jsx";
import Guide from "./Guide.jsx";
import Integrations from "../../components/Integrations/index.jsx";
import LevelUp from "../../components/LevelUp/index.jsx";
import Blog from "../../components/Blog/index.jsx";
import FAQs from "../../components/FAQs/index.jsx";
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- LiveChat Component ---
export default function LiveChat() {
  const { t } = useTranslation();

  const liveChatFAQs = [
    { question: t("LIVE_CHAT_PAGE.FAQS.Q1.QUESTION"), answer: t("LIVE_CHAT_PAGE.FAQS.Q1.ANSWER") },
    { question: t("LIVE_CHAT_PAGE.FAQS.Q2.QUESTION"), answer: t("LIVE_CHAT_PAGE.FAQS.Q2.ANSWER") },
    { question: t("LIVE_CHAT_PAGE.FAQS.Q3.QUESTION"), answer: t("LIVE_CHAT_PAGE.FAQS.Q3.ANSWER") },
    { question: t("LIVE_CHAT_PAGE.FAQS.Q4.QUESTION"), answer: t("LIVE_CHAT_PAGE.FAQS.Q4.ANSWER") },
  ];

  const whyChooseData = {
    title: t("LIVE_CHAT_PAGE.WHY_CHOOSE.TITLE"),
    subtitle: t("LIVE_CHAT_PAGE.WHY_CHOOSE.SUBTITLE"),
    benefits: [
      {
        title: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.AI_SUPPORT.TITLE"),
        description: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.AI_SUPPORT.DESCRIPTION")
      },
      {
        title: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.HIGHER_CONVERSIONS.TITLE"),
        description: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.HIGHER_CONVERSIONS.DESCRIPTION")
      },
      {
        title: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.HIGHER_SATISFACTION.TITLE"),
        description: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.HIGHER_SATISFACTION.DESCRIPTION")
      },
      {
        title: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.BUSINESS_INTEGRATIONS.TITLE"),
        description: t("LIVE_CHAT_PAGE.WHY_CHOOSE.BENEFITS.BUSINESS_INTEGRATIONS.DESCRIPTION")
      }
    ],
    showButtons: false
  };

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("LIVE_CHAT_PAGE.META.TITLE")}
        description={t("LIVE_CHAT_PAGE.META.DESCRIPTION")}
        url="https://intelswift.com/ai-helpdesk"
      />
      <Header />
      <HeroSection />
      <WhyChooseIntelswift
        title={whyChooseData.title}
        subtitle={whyChooseData.subtitle}
        benefits={whyChooseData.benefits}
        showButtons={whyChooseData.showButtons}
        gridMd={6}
        isProductsPage={true}
      />
      <Features />
      <Channels />
      {/* <Guide /> */}
      <Integrations />
      <LevelUp
        title={t("LIVE_CHAT_PAGE.LEVEL_UP.TITLE")}
        subtitle={t("LIVE_CHAT_PAGE.LEVEL_UP.SUBTITLE")}
        button={t("LIVE_CHAT_PAGE.LEVEL_UP.BUTTON")}
        isLiveChatPage={true}
      />
      <Blog
        isLiveChatPage={true}
      />
      <FAQs
        faqs={liveChatFAQs}
      />
      <Footer />
    </>
  );
}
