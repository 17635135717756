import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from "@mui/material";
import AlternativesComparisonTable from "./AlternativesComparisonTable";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import BlogAnchorNav from "./BlogAnchorNav";
import Link from "@mui/material/Link";
import Header from '../Header';
import Footer from '../Footer';
import { blogHeaderImages, fifthArticle, authors } from "../../utils/imageUtils";
import "./index.css";

const BlogFive = () => {
    const { t } = useTranslation();

    const sections = [
        { id: "section1", title: t("Introduction") },
        { id: "section2", title: t("Best customer service automation software: Our top 15 choices") },
        { id: "Intelswift", title: t("Intelswift") },
        { id: "Intercom", title: t("Intercom") },
        { id: "Trengo", title: t("Trengo") },
        { id: "Customerly", title: t("Customerly") },
        { id: "Kustomer", title: t("Kustomer") },
        { id: "Freshworks", title: t("Freshworks") },
        { id: "HelpCrunch", title: t("HelpCrunch") },
        { id: "Chatfuel", title: t("Chatfuel") },
        { id: "WotNot", title: t("WotNot") },
        { id: "Zendesk", title: t("Zendesk") },
        { id: "Drift", title: t("Drift") },
        { id: "Genesys", title: t("Genesys") },
        { id: "Tidio", title: t("Tidio") },
        { id: "LivePerson", title: t("LivePerson") },
        { id: "Sprinklr", title: t("Sprinklr") },
        { id: "section3", title: t("Best customer service automation tools: Comparison table") },
        { id: "section4", title: t("Conclusion") },
        { id: "section5", title: t("FAQs") }
    ];

    const imageMap = {
        header_img: blogHeaderImages.header_img_article_5,
        oksana_author: authors.oksana_author,
        img_1: fifthArticle.article_5_img_1,
        img_2: fifthArticle.article_5_img_2,
        img_3: fifthArticle.article_5_img_3,
        img_4: fifthArticle.article_5_img_4,
        img_5: fifthArticle.article_5_img_5,
        img_6: fifthArticle.article_5_img_6,
        img_7: fifthArticle.article_5_img_7,
        img_8: fifthArticle.article_5_img_8,
        img_9: fifthArticle.article_5_img_9,
        img_10: fifthArticle.article_5_img_10,
        img_11: fifthArticle.article_5_img_11,
        img_12: fifthArticle.article_5_img_12,
        img_13: fifthArticle.article_5_img_13,
        img_14: fifthArticle.article_5_img_14,
        img_15: fifthArticle.article_5_img_15,
        img_16: fifthArticle.article_5_img_16,
        img_17: fifthArticle.article_5_img_17,
        img_18: fifthArticle.article_5_img_18,
        img_19: fifthArticle.article_5_img_19,
        img_20: fifthArticle.article_5_img_20,
        img_21: fifthArticle.article_5_img_21,
        img_22: fifthArticle.article_5_img_22,
        img_23: fifthArticle.article_5_img_23,
        img_24: fifthArticle.article_5_img_24,
        img_25: fifthArticle.article_5_img_25,
        img_26: fifthArticle.article_5_img_26,
        img_27: fifthArticle.article_5_img_27,
        img_28: fifthArticle.article_5_img_28,
        img_29: fifthArticle.article_5_img_29,
        img_30: fifthArticle.article_5_img_30,
        img_31: fifthArticle.article_5_img_31,
        img_32: fifthArticle.article_5_img_32,
        img_33: fifthArticle.article_5_img_33,
        img_34: fifthArticle.article_5_img_34,
        img_35: fifthArticle.article_5_img_35,
        img_36: fifthArticle.article_5_img_36,
        img_37: fifthArticle.article_5_img_37,
        img_38: fifthArticle.article_5_img_38,
        img_39: fifthArticle.article_5_img_39,
        img_40: fifthArticle.article_5_img_40,
        img_41: fifthArticle.article_5_img_41,
        img_42: fifthArticle.article_5_img_42,
        img_43: fifthArticle.article_5_img_43,
        img_44: fifthArticle.article_5_img_44,
        img_45: fifthArticle.article_5_img_45,
    };

    const automationToolsHeader = ["Tool name", "Features", "Free Trial", "Pricing", "Average user ranking"];

    const automationToolsData = [
        {
            tool: "Intelswift",
            keyFeatures: "AI Agents, Live Chat, AI Copilot, Workflows, AI insights & reporting, Omnichannel and multilingual support",
            freePlan: "21 days",
            startingPrice: "From $19/month, $10/month for each extra seat",
            rating: "5.0"
        },
        {
            tool: "Intercom",
            keyFeatures: "Omnichannel, AI insights & reporting, Fin AI agent, Outbound messaging, Workflows, Knowledge hub and help center",
            freePlan: "14 days",
            startingPrice: "From $39/seat/month",
            rating: "4.5"
        },
        {
            tool: "Trengo",
            keyFeatures: "Multichannel inbox, Automated workflows, AI conversations, Journeys, Reports",
            freePlan: "7 days",
            startingPrice: "From $138/month (5 users)",
            rating: "4.2"
        },
        {
            tool: "Customerly",
            keyFeatures: "Live chat, AI, Video, Help center",
            freePlan: "14 days",
            startingPrice: "From €9/month",
            rating: "4.5"
        },
        {
            tool: "Kustomer",
            keyFeatures: "CRM, AI Copilot, AI Chatbots, Omnichannel, Self-service",
            freePlan: "N/A",
            startingPrice: "From $89/month/user",
            rating: "4.5"
        },
        {
            tool: "Freshworks",
            keyFeatures: "Automations, Omnichannel, Ticketing, Collaboration, Self-service",
            freePlan: "14 days",
            startingPrice: "From $35/agent/month",
            rating: "4.4"
        },
        {
            tool: "HelpCrunch",
            keyFeatures: "Live chat, Chatbot, Shared inbox, Knowledge base",
            freePlan: "14 days",
            startingPrice: "From $12/month",
            rating: "4.7"
        },
        {
            tool: "Chatfuel",
            keyFeatures: "Flow builder, Templates, Automated communication, Multichannel support",
            freePlan: "7 days",
            startingPrice: "From $19.99/month",
            rating: "4.4"
        },
        {
            tool: "WotNot",
            keyFeatures: "Bot builder, AI studio, Live chat, WhatsApp chatbot",
            freePlan: "Free plan",
            startingPrice: "From $99",
            rating: "4.6"
        },
        {
            tool: "Zendesk",
            keyFeatures: "Messaging and live chat, Workforce engagement, AI and automation, Help center, Agent workspace, Voice",
            freePlan: "14 days",
            startingPrice: "From $19/agent/month",
            rating: "4.3"
        },
        {
            tool: "Drift",
            keyFeatures: "Live chat, Custom chatbots, Email, Conversational landing pages, Engage",
            freePlan: "N/A",
            startingPrice: "N/A",
            rating: "4.4"
        },
        {
            tool: "Genesys",
            keyFeatures: "AI, Multichannel support, Contact center, Journey management, Workforce engagement",
            freePlan: "14 days",
            startingPrice: "From $75/user/month",
            rating: "4.3"
        },
        {
            tool: "Tidio",
            keyFeatures: "Lyro AI chatbot, Help desk, Live chat, Flows",
            freePlan: "7 days",
            startingPrice: "From $29/month",
            rating: "4.7"
        },
        {
            tool: "LivePerson",
            keyFeatures: "Understand, Connect, Assist, Automate",
            freePlan: "N/A",
            startingPrice: "N/A",
            rating: "4.3"
        },
        {
            tool: "Sprinklr",
            keyFeatures: "Live chat, Omnichannel support, Conversational AI, Knowledge base, Agent assist, Conversational IVR",
            freePlan: "30 days",
            startingPrice: "From $249/seat/month",
            rating: "4.3"
        }
    ];

    const links = {
        blog: "/blog",
        pwcStudy: "https://www.pwc.com/us/en/library/trust-in-business-survey.html#key2",
        intelswiftSite: "https://intelswift.com/",
        intelswiftAgents: "https://intelswift.com/ai-agents",
        intelswiftPricing: "https://intelswift.com/pricing",
        productHunt: "https://www.producthunt.com/products/intelswift",
        aboutIntelswift: "https://intelswift.com/about",
        intercomAlternative: "https://intelswift.com/blog/intercom-alternative",
        intercomAlternatives: "https://www.intercom.com/",
        intercomG2: "https://www.g2.com/products/intercom/reviews",
        intercomCapterra: "https://www.capterra.com/p/134347/Intercom/",
        trengoG2: "https://www.g2.com/products/trengo/reviews",
        trengoCapterra: "https://www.capterra.com/p/177967/Trengo/",
        customerlyG2: "https://www.g2.com/products/customerly/reviews",
        customerlyCapterra: "https://www.capterra.com/p/173977/Customerly/",
        kustomerG2: "https://www.g2.com/products/kustomer/reviews",
        kustomerCapterra: "https://www.capterra.com/p/158128/Kustomer/",
        freshworksAlternative: "https://intelswift.com/blog/freshworks-alternative",
        freshworksG2: "https://www.g2.com/products/freshdesk/reviews",
        freshworksCapterra: "https://www.capterra.com/p/124981/Freshdesk/",
        helpCrunchG2: "https://www.g2.com/products/helpcrunch/reviews",
        helpCrunchCapterra: "https://www.capterra.com/p/225716/HelpCrunch/",
        chatfuelG2: "https://www.g2.com/products/chatfuel/reviews",
        chatfuelCapterra: "https://www.capterra.com/p/181770/Chatfuel/",
        wotnotG2: "https://www.g2.com/products/wotnot/reviews",
        wotnotCapterra: "https://www.capterra.com/p/211191/WotNot/",
        zendeskG2: "https://www.g2.com/products/zendesk-support-suite/reviews",
        zendeskCapterra: "https://www.capterra.com/p/164283/Zendesk/",
        driftG2: "https://www.g2.com/products/drift/reviews",
        driftCapterra: "https://www.capterra.com/p/227417/Drift/",
        genesysG2: "https://www.g2.com/search?utf8=%E2%9C%93&query=Genesys",
        genesysCapterra: "https://www.capterra.com/p/179417/Genesys-Cloud/",
        tidioG2: "https://www.g2.com/products/tidio/reviews",
        tidioCapterra: "https://www.capterra.com/p/144040/Tidio-Chat/",
        livePersonG2: "https://www.g2.com/products/liveperson/reviews",
        livePersonCapterra: "https://www.capterra.com/p/225424/Conversational-Cloud/",
        sprinklrG2: "https://www.g2.com/products/sprinklr-service/reviews",
        sprinklrCapterra: "https://www.capterra.com/p/131274/Sprinklr-Platform/",
        signUp: "https://login.intelswift.com/oauth/account/sign-up",
        scheduleDemo: "https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo?month=2024-09",
        authorLink: "https://www.linkedin.com/in/oksana-budz?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    };

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href={links.blog} className="breadcrumbs-link">
                        Blog
                    </Link>
                    <Typography color="text.primary">15 Best Customer Service Automation Software</Typography>
                </Breadcrumbs>
            </Box>
            <Box className="blog-post-title-container">
                <Box className="left-section">
                    <Typography className="blog-post-title" variant="h1" gutterBottom>
                        15 Best Customer Service Automation Software
                    </Typography>
                    <Typography className="blog-post-subtitle" variant="h2" gutterBottom>
                        Discover the 15 best customer service automation software solutions – features, pricing, integrations and ratings compared
                    </Typography>
                    <Box className="meta-info">
                        <Typography variant="subtitle1">October 28, 2024</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">15 min read</Typography>
                    </Box>
                </Box>
                <Box className="right-section">
                    <section className="blog-image">
                        <img src={imageMap.header_img} />
                    </section>
                </Box>
            </Box>
            <Grid container spacing={2} className="blog-post-layout">
                <Grid item xs={12} md={3}>
                    {/* Author Section */}
                    <Box className="author-info">
                        <Box mr={2}>
                            <img src={imageMap.oksana_author} alt="Oksana, Head of Content" />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"><strong>Oksana, Head of Content</strong></Typography>
                            <Link href={links.authorLink} target="_blank" rel="noopener noreferrer" className="url">
                                LinkedIn Profile
                            </Link>
                        </Box>
                    </Box>
                    <BlogAnchorNav sections={sections} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box id="section1">
                        <Typography variant="body1">Leaders often overestimate how much consumers trust their business – a <a href={links.pwcStudy} target="_blank" rel="noopener noreferrer" className="url">PWC study from 2024</a> shows a whooping 60% gap between the leader and consumer trust levels. It means you probably have much less space for customer service errors than anticipated. To improve your business’s position and meet customer expectations, you can invest in good communication and automation tools. But how to find the right ones for your scenario?
                            <br /><br />Well, read on – because in this article, we’ll highlight the best customer service automation software solutions on the market in 2024 and beyond.
                        </Typography>
                    </Box>
                    <br /><Box id="section2" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>Best customer service automation software: Our top 15 choices</Typography>
                        {/* Intelswift */}
                        <Box id="Intelswift">
                            <Typography variant="body2" className="media-paragraph">Intelswift</Typography>
                            <img src={imageMap.img_1} alt="Intelswift – one of the best customer service automation software options" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Intelswift</Typography>
                            <br /><Typography variant="body1" gutterBottom><a href={links.intelswiftSite} target="_blank" rel="noopener noreferrer" className="url">Intelswift</a> is a customer service automation tool that integrates into support team workflows and automates repetitive tasks. It helps businesses respond to customers swiftly and professionally. The platform’s AI features can aid users in various ways, like generating data-rich visualizations and forecasts, identifying anomalies etc.</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong><a href={links.intelswiftAgents} target="_blank" rel="noopener noreferrer" className="url">AI Agents</a> </strong> – Automate tasks such as creating FAQs, lead qualification, meeting scheduling and summary generation. Create personalized product recommendations and generate answers in any language, 24/7. Train your agent with just a few clicks, based on your company data (e.g. website or documents).</li></Typography>
                                <Typography variant="body1"><li><strong>Live Chat</strong> – Transfer conversations from AI chats to a live agent whenever it’s unable to fix an issue. Live agents can jump in at any time to take over the conversation smoothly.</li></Typography>
                                <Typography variant="body1"><li><strong>AI Copilot</strong> – Access an AI assistant that boosts team productivity and drives data insights by generating data-rich visualizations, creating data forecasts, detecting anomalies, and so on, based on natural language prompts.</li></Typography>
                                <Typography variant="body1"><li><strong>Workflows</strong> – Easily create customer service workflows with a powerful, intuitive, no-code visual builder.</li></Typography>
                                <Typography variant="body1"><li><strong>Omnichannel and multilingual support</strong> – Intelswift provides omnichannel and multilingual support. It’s worth noting that live agents use our platform to communicate with customers on various channels (e.g., Web widget, Telegram, WhatsApp and more).</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>Intelswift offers 120+ integration options, including popular software such as Hubspot, Salesforce, Zoho, Mailchimp etc. You can set up automation workflows inside our platform, and the AI agent will transfer leads to connected CRM, trigger email workflows to pass data to external analytics tools, and so on.</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User rating</strong></Typography>
                            <Typography variant="body1" gutterBottom>Intelswift doesn’t have ratings on G2 or Capterra yet but has a perfect 5.0 score on <a href={links.productHunt} target="_blank" rel="noopener noreferrer" className="url">Producthunt</a>. You can learn more about our company and solution <a href="https://intelswift.com/about" className="url">here</a>.</Typography>
                            <img src={imageMap.img_2} alt="Intelswift customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Producthunt</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">Intelswift offers three <a href={links.intelswiftPricing} target="_blank" rel="noopener noreferrer" className="url">pricing</a> plans:</Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Personal</strong> – $19/month</li></Typography>
                                <Typography variant="body1"><li><strong>$49/month</strong> – $49/month</li></Typography>
                                <Typography variant="body1"><li><strong>Business</strong> – $125/month</li></Typography>
                            </ul>
                            <Typography variant="body1">You can get a 20% discount with annual billing. There’s also a 21-day trial with access to the complete feature set.
                                <br /><br />All plans include 1 seat. You can add extra seats and extra conversations at any time. It costs $10 per seat and $7 per 100 conversations.
                            </Typography>
                            <img src={imageMap.img_3} alt="Intelswift pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Intelswift</Typography>
                        </Box>
                        {/* Intercom */}
                        <br /><Box id="Intercom">
                            <Typography variant="body2" className="media-paragraph">Intercom</Typography>
                            <img src={imageMap.img_4} alt="Intercom – customer service software with AI enhancements" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>Intercom is a customer service software that focuses on AI enhancements to help businesses optimize their customer support processes.</Typography>
                            <Typography gutterBottom><strong>See also:</strong> <a href={links.intercomAlternative} target="_blank" rel="noopener noreferrer" className="url"> <strong>Intercom alternatives</strong></a></Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Omnichannel</strong> – Direct all communication to one inbox.</li></Typography>
                                <Typography variant="body1"><li><strong>AI insights & reporting</strong> – Optimize customer satisfaction, experience, and engagement with AI-generated insights.</li></Typography>
                                <Typography variant="body1"><li><strong>Fin AI agent</strong> – Resolve up to half of your support volume instantly using an AI-enhanced chatbot.</li></Typography>
                                <Typography variant="body1"><li><strong>Workflows</strong> – Automate workflows with a drag&drop builder (no coding necessary).</li></Typography>
                                <Typography variant="body1"><li><strong>Knowledge hub and help center</strong> – Offer users a way to resolve issues independently with a centralized knowledge library.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>Connect Intercom to solutions like Jira Cloud, Stripe, WhatsApp, Instagram, Salesforce, HubSpot, etc. Zapier integration offers more options.</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.intercomG2} target="_blank" rel="noopener noreferrer" className="url">4.5</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.intercomCapterra} target="_blank" rel="noopener noreferrer" className="url">4.5</a></strong></Typography>
                            <img src={imageMap.img_5} alt="Intercom customer review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">Intercom offers three plans:</Typography>
                            <ul>
                                <Typography variant="body1"><strong>Essential</strong> – $39/seat/month</Typography>
                                <Typography variant="body1"><strong>Advanced</strong> – $99/seat/month</Typography>
                                <Typography variant="body1"><strong>Expert</strong> – $139/seat/month</Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom>You can get a discount with yearly billing (the amount depends on the plan). Also, Intercom offers a 14-day free trial.</Typography>
                            <img src={imageMap.img_6} alt="Intercom pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Intercom</Typography>
                        </Box>
                        {/* Trengo */}
                        <br /><Box id="Trengo">
                            <Typography variant="body2" className="media-paragraph">Trengo</Typography>
                            <img src={imageMap.img_7} alt="Trengo - a customer engagement platform" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Trengo is a customer engagement platform that integrates various communication channels in one collaborative space and offers several functional AI enhancements.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Multichannel inbox</strong> – Bring all key communication into one unified and easy-to-use inbox.</li></Typography>
                                <Typography variant="body1"><li><strong>Automated workflows</strong> – Access automated ticket routing and create workflows on different channels (WhatsApp, email, website live chats, etc.).</li></Typography>
                                <Typography variant="body1"><li><strong>AI conversations</strong> – Offer your customers instant, personalized answers in over 70 languages, with a customizable tone of voice.</li></Typography>
                                <Typography variant="body1"><li><strong>Journeys</strong> – Build unique customer journeys that cover common issues and respond to frequently asked questions.</li></Typography>
                                <Typography variant="body1"><li><strong>Reports</strong> – Generate performance reports and easily share them with stakeholders in different departments.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Trengo offers integrations with over 20 known solutions, such as Shopify, HubSpot, Salesforce, and Wix.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.trengoG2} target="_blank" rel="noopener noreferrer" className="url">4.3</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.trengoCapterra} target="_blank" rel="noopener noreferrer" className="url">4.1</a></strong></Typography>
                            <img src={imageMap.img_8} alt="Trengo customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Trengo offers three plans, with prices based on the number of users, integrations, and available features:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Essential</strong> – $138/month (5 users and 100 conversations/month)</li></Typography>
                                <Typography variant="body1"><li><strong>Boost</strong> – $204/month (5 users and 300 conversations/month)</li></Typography>
                                <Typography variant="body1"><li><strong>Pro</strong> – $341/month (5 users and 1000 conversations/month)</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                These prices are with monthly billing. There’s a 7-day free trial and a 20% discount if you decide to pay annually.
                            </Typography>
                            <img src={imageMap.img_9} alt="Trengo pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Trengo</Typography>
                        </Box>
                        {/* Customerly */}
                        <br /><Box id="Customerly">
                            <Typography variant="body2" className="media-paragraph">Customerly</Typography>
                            <img src={imageMap.img_10} alt="Customerly - messaging platform with AI enhancements" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: GetApp</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Customerly is a messaging platform that provides a help desk, a chat (text and video) you can integrate with your other systems, and some practical AI enhancements.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Access messages from different channels in a unified inbox and reduce workloads with canned responses, templates, and a virtual assistant to handle customer interaction.</li></Typography>
                                <Typography variant="body1"><li><strong>AI</strong> – Simplify tasks and aid your agents, translate messages, and use AI-enhanced chatbots that ask humans for help when they don’t know how to handle a situation.</li></Typography>
                                <Typography variant="body1"><li><strong>Video live chat</strong> – Use face-to-face conversations to engage customers and create personal relationships.</li></Typography>
                                <Typography variant="body1"><li><strong>Help center</strong> – Provide an AI-powered help center where people can find answers to their questions, which reduces the workload for your support teams.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Customerly offers several useful integration options, including WordPress (through a plugin), MailMunch, and Front. The tool also provides a Zapier connection.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.customerlyG2} target="_blank" rel="noopener noreferrer" className="url">4.2</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.customerlyCapterra} target="_blank" rel="noopener noreferrer" className="url">4.6</a></strong></Typography>
                            <img src={imageMap.img_11} alt="Customerly customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Customerly’s price depends on the monthly active users and emails sent. There are four pricing plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Essential</strong> – €9/month</li></Typography>
                                <Typography variant="body1"><li><strong>Startup</strong> – €49/month</li></Typography>
                                <Typography variant="body1"><li><strong>Pro</strong> – €99/month</li></Typography>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – €299/month</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                You get a discount with yearly payments, and there’s also a 14-day free trial. Note that this is an Irish-based company, and prices are only available in EUR.
                            </Typography>
                            <img src={imageMap.img_12} alt="Customerly pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Customerly</Typography>
                        </Box>
                        {/* Kustomer */}
                        <br /><Box id="Kustomer">
                            <Typography variant="body2" className="media-paragraph">Kustomer</Typography>
                            <img src={imageMap.img_13} alt="Kustomer - comprehensive customer service platform" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Kustomer is a comprehensive customer service platform with a built-in CRM system. It provides standard features such as chatbots, omnichannel support, and some AI tools (Copilot, AI-driven chatbots).
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>CRM</strong> – Access a flexible CRM system built specifically for customer service operations, with helpful business process automation features and configurable routing rules.</li></Typography>
                                <Typography variant="body1"><li><strong>AI Copilot</strong> – Resolve customer issues efficiently with the help of AI Copilot, which can expand, spellcheck, and translate messages, write conversation summaries, etc.</li></Typography>
                                <Typography variant="body1"><li><strong>AI Chatbots</strong> – Offer your customers instant access to personalized support 24/7. Templates and no-code configuration tools make it easy to set up.</li></Typography>
                                <Typography variant="body1"><li><strong>Omnichannel</strong> – Offer seamless support across various communication channels (email, chat, SMS, social media, voice).</li></Typography>
                                <Typography variant="body1"><li><strong>Self-service</strong> – Provide your customers with a knowledge base and chatbots, and use proactive messages to help them resolve their problems without your help.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Kustomer offers many useful integrations, including Shopify, RingCentral, MessageBird, Fullstory, Salesforce, Jira, and Slack.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.kustomerG2} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.kustomerCapterra} target="_blank" rel="noopener noreferrer" className="url">4.6</a></strong></Typography>
                            <img src={imageMap.img_14} alt="Kustomer customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Kustomer has two pricing options:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – $89/month/user</li></Typography>
                                <Typography variant="body1"><li><strong>Ultimate</strong> – $139/month/user</li></Typography>
                            </ul>
                            <img src={imageMap.img_15} alt="Kustomer pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Kustomer</Typography>
                        </Box>
                        {/* Freshworks */}
                        <br /><Box id="Freshworks">
                            <Typography variant="body2" className="media-paragraph">Freshworks (Freshdesk)</Typography>
                            <img src={imageMap.img_16} alt="Freshworks (Freshdesk) customer service automation solution" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Freshdesk (a product of a company called Freshworks) is a customer service automation solution that helps companies optimize customer support operations. It’s multichannel and offers solid automation features and AI enhancements.
                            </Typography>
                            <Typography gutterBottom><strong>See also: </strong><a href={links.freshworksAlternative} target="_blank" rel="noopener noreferrer" className="url"><strong>Frameworks alternatives</strong></a></Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Automations</strong> – Assign tickets to agents/groups automatically and enable event-triggered automation and time-triggered maintenance tasks.</li></Typography>
                                <Typography variant="body1"><li><strong>Omnichannel</strong> – Let users write tickets on the website and contact them directly on various channels such as WhatsApp, email, chat, phone or social media.</li></Typography>
                                <Typography variant="body1"><li><strong>Ticketing</strong> – Use a unified team inbox and optimize ticketing with agent collision detection, thank you detector, SLA management and ticket field suggestion.</li></Typography>
                                <Typography variant="body1"><li><strong>Collaboration</strong> – <strong>Improve team collaboration with parent-child tickets, linked tickets and shared ticket ownership.</strong></li></Typography>
                                <Typography variant="body1"><li><strong>Self-service</strong> – <strong>Help customers fix their issues without your help thanks to a widget, automatic solution suggestions and an AI-powered chatbot.</strong></li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Freshdesk integrates with over 1200 other tools, including Playvox, Slack, MS Teams, Shopify, Salesforce and Jira.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.freshworksG2} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.freshworksCapterra} target="_blank" rel="noopener noreferrer" className="url">4.5</a></strong></Typography>
                            <img src={imageMap.img_17} alt="Freshworks (Freshdesk) customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Capterra</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Freshdesk consists of several products with different pricing options. The one most comparable to other positions on our list is the Customer Service Suite with three plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Growth</strong> – $35/agent/month</li></Typography>
                                <Typography variant="body1"><li><strong>Pro</strong> – $83/agent/month</li></Typography>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – $131/agent/month</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                There’s also a 14-day free trial and discount with annual billing.
                            </Typography>
                            <img src={imageMap.img_18} alt="Freshworks (Freshdesk) pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Freshdesk</Typography>
                        </Box>
                        {/* HelpCrunch */}
                        <br /><Box id="HelpCrunch">
                            <Typography variant="body2" className="media-paragraph">HelpCrunch</Typography>
                            <img src={imageMap.img_19} alt="HelpCrunch customer communication platform" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                HelpCrunch is a customer communication platform designed for support and marketing purposes. It offers live chat, omnichannel support, shared inbox, self-service features and email marketing tools. As with most other platforms nowadays, there are also AI enhancements and automation options available.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Talk to your customers in real time or set up automated messages based on pre-defined rules.</li></Typography>
                                <Typography variant="body1"><li><strong>Chatbot</strong> – Access an AI-based website chatbot that will respond to customer queries and offer contextual self-service (ask multiple-choice FAQ questions).</li></Typography>
                                <Typography variant="body1"><li><strong>Shared inbox</strong> – Gather all communication in a shared inbox to speed up work and make collaboration easier.</li></Typography>
                                <Typography variant="body1"><li><strong>Knowledge base</strong> – Build a well-structured knowledge base with categories and sections, and integrate it with a widget on your website.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                HelpCrunch offers many useful integrations, including Slack, WordPress, WhatsApp and Shopify. It also provides a Zapier integration for even more options.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.helpCrunchG2} target="_blank" rel="noopener noreferrer" className="url">4.7</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.helpCrunchCapterra} target="_blank" rel="noopener noreferrer" className="url">4.8</a></strong></Typography>
                            <img src={imageMap.img_20} alt="HelpCrunch user review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                HelpCrunch has three pricing plans with different feature sets, but the price also depends on the number of team members who will be using the platform and the number of sent emails.
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Basic</strong> – $12/month (1 person and no emails)</li></Typography>
                                <Typography variant="body1"><li><strong>Pro</strong> – $20/month (1 person and no emails)</li></Typography>
                                <Typography variant="body1"><li><strong>Unlimited</strong> – $495/month (unlimited number of team members and 50,000 emails)</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                There’s a 20% discount with annual billing. The company also offers a 14-day free trial.
                            </Typography>
                            <img src={imageMap.img_21} alt="HelpCrunch pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: HelpCrunch</Typography>
                        </Box>
                        {/* Chatfuel */}
                        <br /><Box id="Chatfuel">
                            <Typography variant="body2" className="media-paragraph">Chatfuel</Typography>
                            <img src={imageMap.img_22} alt="Chatfuel customer service automation software" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Chatfuel is a no-code tool for automating customer support and sale interactions. It allows you to create AI-powered chatbots for Facebook Messenger, Instagram and WhatsApp.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Flow builder</strong> – Create automated conversation scenarios (answering FAQs, informing about discounts etc.)</li></Typography>
                                <Typography variant="body1"><li><strong>Templates</strong> – Configure chatbot use cases with ready-made templates that cover popular scenarios.</li></Typography>
                                <Typography variant="body1"><li><strong>Automated communication</strong> – Direct conversations to your chatbot from messages or ads that click to WhatsApp.</li></Typography>
                                <Typography variant="body1"><li><strong>Multichannel support</strong> – Use Chatfuel on Facebook, Instagram, WhatsApp and your company’s website.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Chatfuel connects with several tools like Stripe, Calendly, Shopify, Google Sheets and HubSpot. There’s also a Zapier integration.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.chatfuelG2} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra:<a href={links.chatfuelCapterra} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <img src={imageMap.img_23} alt="Chatfuel user opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Chatfuel offers separate pricing plans depending on the communication channels you want to use. Here are the options for Facebook and Instagram:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Business</strong> – $19.99/month (1000 conversations/month)</li></Typography>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – $300/month (the plan details are customizable)</li></Typography>
                            </ul>
                            <img src={imageMap.img_24} alt="Chatfuel Facebook and Instagram pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Chatfuel</Typography>
                            <br /><Typography variant="body1">
                                And here are the plans for WhatsApp communication:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Business</strong> – $34,49/month (1000 conversations/month)</li></Typography>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – $300/month (the plan details are customizable)</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                There’s also a 7-day free trial.
                            </Typography>
                            <br /><img src={imageMap.img_25} alt="Chatfuel WhatsApp pricing" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Chatfuel</Typography>
                        </Box>
                        {/* WotNot */}
                        <br /><Box id="WotNot">
                            <Typography variant="body2" className="media-paragraph">WotNot</Typography>
                            <img src={imageMap.img_26} alt="WotNot chatbot and customer service software" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                WotNot is a no-code chatbot platform companies can use to automate customer interactions. The tool allows you to create customizable chatbots (they can also be AI-enhanced) you can use on several different communication channels.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Bot builder</strong> – Use a visual builder and ready-made templates to create custom chatbots in minutes.</li></Typography>
                                <Typography variant="body1"><li><strong>AI studio</strong> – Train your own ChatGPT chatbots (with support for over 50 languages) based on a set of documents or the website domain. Automatically transfer conversations to human agents when bots find a problem they can’t solve.</li></Typography>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Access a multichannel (website, SMS, WhatsApp, Facebook) live chat to handle all conversations in one place.</li></Typography>
                                <Typography variant="body1"><li><strong>WhatsApp chatbot</strong> – Offer customers 24/7 support via WhatsApp using chatbots.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                WotNot connects to many well-known tools, including HubSpot, Salesforce, Google Calendar, Zendesk and Zoho CRM. The platform also provides a Zapier integration.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.wotnotG2} target="_blank" rel="noopener noreferrer" className="url">4.6</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.wotnotCapterra} target="_blank" rel="noopener noreferrer" className="url">4.6</a></strong></Typography>
                            <img src={imageMap.img_27} alt="WotNot customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                WotNot has five pricing plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Free</strong> – For up to 100 chats and 20 AI answers</li></Typography>
                                <Typography variant="body1"><li><strong>Starter</strong> – $99/month (5,000 chats and 1,000 AI answers)</li></Typography>
                                <Typography variant="body1"><li><strong>Premium</strong> – $299/month (10,000 chats and 2,500 AI answers)</li></Typography>
                                <Typography variant="body1"><li><strong>Ultimate</strong> – $899/month (20,000 and 5,000 AI answers)</li></Typography>
                                <Typography variant="body1"><li><strong>Enterprise</strong> – Custom pricing and details</li></Typography>
                            </ul>
                            <img src={imageMap.img_28} alt="WotNot pricing details" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: WotNot</Typography>
                        </Box>
                        {/* Zendesk */}
                        <br /><Box id="Zendesk">
                            <Typography variant="body2" className="media-paragraph">Zendesk</Typography>
                            <img src={imageMap.img_29} alt="Zendesk system for automating customer service tasks" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Zendesk</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Zendesk Support Suite is a powerful, omnichannel system that allows businesses to automate customer service tasks and optimize their operations in this area to provide a seamless, integrated support experience for their users.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Messaging and live chat</strong> – Offer users help through communication channels like WhatsApp, social media, or business productivity tools.</li></Typography>
                                <Typography variant="body1"><li><strong>Workforce engagement</strong> – Enable automated forecasting, scheduling, monitoring and real-time reporting to optimize team efficiency and engagement.</li></Typography>
                                <Typography variant="body1"><li><strong>AI and automation</strong> – Use AI agents to resolve less complex issues without human employees. Provide support teams with AI assistants to speed up work with suggestions and generated replies your staff can customize. </li></Typography>
                                <Typography variant="body1"><li><strong>Help center</strong> – Offer your users and agents a knowledge base they can use to resolve their issues and find key information easily.</li></Typography>
                                <Typography variant="body1"><li><strong>Agent workspace</strong> – Unify communication channels to foster collaboration between agents and help them react faster.</li></Typography>
                                <Typography variant="body1"><li><strong>Voice</strong> – Speak with your users directly to solve their problems faster.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Zendesk integrates with a large number of other systems, including Harvest, Shopify, Slack, Asana, Linear and many others.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.zendeskG2} target="_blank" rel="noopener noreferrer" className="url">4.3</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.zendeskCapterra} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <img src={imageMap.img_30} alt="Zendesk customer review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Zendesk support suite has three pricing plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Support Team</strong> – $19/agent/month</li></Typography>
                                <Typography variant="body1"><li><strong>Support Professional</strong> – $55/agent/month</li></Typography>
                                <Typography variant="body1"><li><strong>Support Enterprise</strong> – $115/agent/month</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                The prices are with annual billing (there’s no monthly option). There’s also a 14-day free trial.
                            </Typography>
                            <img src={imageMap.img_31} alt="Zendesk pricing details" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Zendesk</Typography>
                        </Box>
                        {/* Drift */}
                        <br /><Box id="Drift">
                            <Typography variant="body2" className="media-paragraph">Drift</Typography>
                            <img src={imageMap.img_32} alt="Drift conversational marketing platform" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Shulex VOC blog</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Drift is a conversational marketing platform that leverages artificial intelligence to engage with website visitors in real time. While it doesn’t focus on customer service, the chatbots you build with it can also be used for this purpose.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Carry out real-time, instant conversations with your customers and access customer feedback without forcing anyone to fill out forms.</li></Typography>
                                <Typography variant="body1"><li><strong>Custom chatbots</strong> – Create custom chatbots to personalize messages and interactions and optimize customer engagement.</li></Typography>
                                <Typography variant="body1"><li><strong>Email</strong> – Sort through the email inbox and automatically route human responses to the correct team member.</li></Typography>
                                <Typography variant="body1"><li><strong>Conversational landing pages</strong> – Create landing pages focused on chat to simplify conversation starting and boost engagement.</li></Typography>
                                <Typography variant="body1"><li><strong>Engage</strong> – Link specific events and website actions to visitors to understand customers better.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                You can connect Drift to various other tools, such as Microsoft Teams, RollWorks, Google Marketing Platform and Clearbit.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.driftG2} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.driftCapterra} target="_blank" rel="noopener noreferrer" className="url">4.5</a></strong></Typography>
                            <img src={imageMap.img_33} alt="Drift customer review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Drift has two plans – Advanced and Premier – but the company doesn’t disclose pricing details on the website. However, this is an enterprise-centered solution that can be costly for small businesses.
                            </Typography>
                        </Box>
                        {/* Genesys */}
                        <br /><Box id="Genesys">
                            <br /><Typography variant="body2" className="media-paragraph">Genesys</Typography>
                            <img src={imageMap.img_34} alt="Genesys customer experience and contact center platform" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Genesys is a customer experience (CX) and contact center platform. It provides tools to optimize your business communications (including useful AI enhancements).
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>AI</strong> – Access conversational AI tools to make bot conversations more alive, as well as AI-based call summarization, forecasting and scheduling.</li></Typography>
                                <Typography variant="body1"><li><strong>Multichannel support</strong> – Use the platform across email, messaging tools, voice communication, social media, text and chat.</li></Typography>
                                <Typography variant="body1"><li><strong>Contact center</strong> – Offer your customers a comprehensive, AI-enhanced contact center with seamless cross-channel communication.</li></Typography>
                                <Typography variant="body1"><li><strong>Journey management</strong> – Use customer journey analytics and orchestration to personalize user experience.</li></Typography>
                                <Typography variant="body1"><li><strong>Workforce engagement</strong> – Align company and personal goals and optimize employee engagement using tools natively integrated with the contact center.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Genesys integrates with over 600 tools, including Zendesk, Salesforce, ServiceNow and Jira.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.genesysG2} target="_blank" rel="noopener noreferrer" className="url">4.3</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.genesysCapterra} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <img src={imageMap.img_35} alt="Genesys user review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Genesys has three pricing plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>CX 1</strong> – $75/user/month</li></Typography>
                                <Typography variant="body1"><li><strong>CX 2</strong> – $115/user/month</li></Typography>
                                <Typography variant="body1"><li><strong>CX 3</strong> – $155/user/month</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                These prices are with annual billing. There’s also a 14-day free trial.
                            </Typography>
                            <img src={imageMap.img_36} alt="Genesys pricing structure" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Genesys</Typography>
                        </Box>
                        {/* Tidio */}
                        <br /><Box id="Tidio">
                            <Typography variant="body2" className="media-paragraph">Tidio</Typography>
                            <img src={imageMap.img_37} alt="Tidio customer service automation software" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Tidio is a customer service platform that helps businesses enhance customer support via live chat, AI-powered chatbots, and automation tools.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Lyro AI chatbot</strong> – Answer frequently asked questions automatically in many languages and automate recurrent tasks (checking order statuses, creating tickets).</li></Typography>
                                <Typography variant="body1"><li><strong>Help desk</strong> – Use a Help Desk ticketing system, live chat and multichannel communication to help your customers more effectively.</li></Typography>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Access live chatting tools with a typing preview feature and a unified inbox for WhatsApp, Instagram, website chat and Messenger.</li></Typography>
                                <Typography variant="body1"><li><strong>Flows</strong> – Use a no-code builder to create conversion paths to optimize your lead generation and sales funnel.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                You can integrate Tidio with other systems like Shopify, WordPress, Magento, BigCommerce and HubSpot. There’s also a Zapier integration.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.tidioG2} target="_blank" rel="noopener noreferrer" className="url">4.7</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.tidioCapterra} target="_blank" rel="noopener noreferrer" className="url">4.7</a></strong></Typography>
                            <img src={imageMap.img_38} alt="Tidio user review" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Tidio has four plans with pricing dependent on the number of conversations (the prices below are with 100 conversations selected).
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Starter</strong> – $29/month</li></Typography>
                                <Typography variant="body1"><li><strong>Growth</strong> – $59/month</li></Typography>
                                <Typography variant="body1"><li><strong>Plus</strong> – $749/month</li></Typography>
                                <Typography variant="body1"><li><strong>Premium</strong> – $2999/month</li></Typography>
                            </ul>
                            <img src={imageMap.img_39} alt="Tidio pricing plans" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Tidio</Typography>
                            <br /><Typography variant="body1">
                                The prices above are with monthly billing (you get two months free with annual). However, they are just for the core functionalities. With all but the most expensive plan, the AI chatbot and the Flows module must be bought separately at €39/month (for 50 conversations) and €29/month (for 2000 visitors – the first 100 are free). There’s also a 7-day free trial.
                            </Typography>
                            <img src={imageMap.img_40} alt="Tidio pricing for add-ons" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Tidio</Typography>
                        </Box>
                        {/* LivePerson */}
                        <br /><Box id="LivePerson">
                            <Typography variant="body2" className="media-paragraph">LivePerson</Typography>
                            <img src={imageMap.img_41} alt="LivePerson cloud platform with AI-powered chatbots" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                LivePerson is a conversational cloud platform that uses AI-powered chatbots. They work alongside a human customer service team to provide personalized 24/7 help.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Reporting</strong> – Analyze conversation and customer data from multiple channels to better understand your customers' wants and needs.</li></Typography>
                                <Typography variant="body1"><li><strong>Omnichannel communication</strong> – Contact customers on multiple channels such as the website, mobile platforms, social media and voice conversations using one platform.</li></Typography>
                                <Typography variant="body1"><li><strong>Employee assistance</strong> – Provide your employees with a customizable workspace and AI tools (Copilot) that help them optimize their work (summarizing, rewriting responses etc.). </li></Typography>
                                <Typography variant="body1"><li><strong>Automation</strong> – Use AI to automate tasks and generate reports and insights regarding conversation data. Build custom AI agents (chatbots) and use a conversation orchestrator to optimize interactions and enable seamless switching between AI chatbots and human customer support agents.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                LivePerson integrates with many other systems, such as Salesforce, Zendesk, NetSuite and Microsoft Dynamics.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.livePersonG2} target="_blank" rel="noopener noreferrer" className="url">4.2</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.livePersonCapterra} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <img src={imageMap.img_42} alt="LivePerson customer opinion" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                LivePerson has three pricing plans – Bronze, Silver and Gold – but doesn’t disclose pricing details on the website.
                            </Typography>
                        </Box>
                        {/* Sprinklr */}
                        <br /><Box id="Sprinklr">
                            <br /><Typography variant="body2" className="media-paragraph">Sprinklr</Typography>
                            <img src={imageMap.img_43} alt="Sprinklr customer service automation software" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom>
                                Sprinklr is a customer experience management platform that provides tools for managing customer experiences and interactions.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Key Features</strong></Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Live chat</strong> – Use integrated live chat to provide 24/7 assistance to your customers on websites and mobile apps.</li></Typography>
                                <Typography variant="body1"><li><strong>Omnichannel support</strong> – Access AI-powered omnichannel routing (chat, social media, email and voice) and tools to easily assign an interaction to an agent.</li></Typography>
                                <Typography variant="body1"><li><strong>Conversational AI</strong> – Create AI bots using a simple no-code process with natural language prompts.</li></Typography>
                                <Typography variant="body1"><li><strong>Knowledge base</strong> – Collect and organize information for customer service teams or other scenarios with a robust knowledge base tool.</li></Typography>
                                <Typography variant="body1"><li><strong>Agent assist</strong> – Automate workflows and offer agents AI tools that provide suggestions and recommendations, summarize cases etc.</li></Typography>
                                <Typography variant="body1"><li><strong>Conversational IVR</strong> – Use smart voice bots to provide intuitive self-service for customers and efficient conversation routing.</li></Typography>
                            </ul>
                            <Typography variant="body1" gutterBottom><strong>Integrations</strong></Typography>
                            <Typography variant="body1" gutterBottom>
                                Sprinklr integrates with various other solutions, including Microsoft Dynamics, Netomi, Okta, Power BI and Salesforce.
                            </Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>User ratings</strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>G2: <a href={links.sprinklrG2} target="_blank" rel="noopener noreferrer" className="url">4.4</a></strong></Typography>
                            <Typography variant="body1" gutterBottom><strong>Capterra: <a href={links.sprinklrCapterra} target="_blank" rel="noopener noreferrer" className="url">4.2</a></strong></Typography>
                            <img src={imageMap.img_44} alt="Sprinklr customer feedback" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: G2</Typography>
                            <br /><Typography variant="body1" gutterBottom><strong>Pricing</strong></Typography>
                            <Typography variant="body1">
                                Sprinklr offers two pricing plans:
                            </Typography>
                            <ul>
                                <Typography variant="body1"><li><strong>Self-serve customer service solution</strong> – $249/seat/month</li></Typography>
                                <Typography variant="body1"><li><strong>Custom CCaaS solution</strong> – custom pricing</li></Typography>
                            </ul>
                            <Typography variant="body1">
                                There’s a 30-day free trial and a 20% discount with annual billing.
                            </Typography>
                            <img src={imageMap.img_45} alt="Sprinklr pricing plans" />
                            <Typography variant="body1" gutterBottom textAlign="center">Image source: Sprinklr</Typography>
                        </Box>
                        <br /><Box id="section3" className="blog-paragraph">
                            <Typography variant="h4" gutterBottom>Best customer service automation tools: Comparison table</Typography>
                            <AlternativesComparisonTable headers={automationToolsHeader} data={automationToolsData} />
                        </Box>
                        <br /><br /><Box id="section4" className="blog-paragraph">
                            <Typography variant="h4" gutterBottom>Conclusion</Typography>
                            <Typography variant="body1" gutterBottom>Customer service automation software can make a night and day difference for a business, and this is especially true in the age of AI when LLM-based bots can converse with humans and solve many of their issues without human intervention. However, the sheer number of solutions on offer is staggering – some just provide tools for building custom bots, others offer analytics, AI assistance for employees and more.
                                <br /><br />If you’re looking for an easy-to-use solution with powerful AI capabilities (especially when it comes to the Copilot implementation), and powerful, easily configurable integrations, Intelswift is one of the best choices out there – it's bound to improve your customer service quality.
                                <br /><br />Interested? Try Intelswift now (no credit card details necessary) by <a href={links.signUp} target="_blank" rel="noopener noreferrer" className="url">signing up</a> or <a href={links.scheduleDemo} target="_blank" rel="noopener noreferrer" className="url">scheduling a demo</a> if you want to see our software in action!
                            </Typography>
                        </Box>
                        <br /><Box id="section5" className="blog-paragraph">
                            <Typography variant="h4" gutterBottom>FAQs</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Can you automate customer service?</strong>
                                <br /><br />You can automate customer service using chatbots, AI-driven helpdesk software, and automated response systems. Platforms such as our Intelswift provide these features and more.
                                <br /><br /><strong>What is an automated system for customer service?</strong>
                                <br /><br />Automated customer service lets businesses manage customer inquiries and support routine tasks without human intervention – while customer service agents deal with more serious issues.
                                <br /><br /><strong>What software is used for customer service?</strong>
                                <br /><br />There are many customer service platforms. One potential option is Intelswift, which offers great ease-of-use, powerful AI capabilities (including a great Copilot implementation), and a rich suite of one-click integrations.
                                <br /><br /><strong>Which CRM has the best customer service?</strong>
                                <br /><br />It depends on your exact needs and requirements. Zendesk, Freshdesk, and Zoho Desk are often mentioned as solutions that provide good customer service. Moreover, if the quality of customer service is important to you, you can invest in a tool like Intelswift, which not only provides top-notch customer service but also allows you to improve yours. The solution also connects to well-known CRM systems, like HubSpot and Salesforce.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
            <Footer />
        </>
    )
}
export default BlogFive;
