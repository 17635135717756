// --- Third Party Libraries ---
import React from "react";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import SEO from "../../components/SEO/index.jsx";
import Header from "../../components/Header/index.jsx";
import HeroSection from "./HeroSection.jsx";
import LearnMore from "./LearnMore.jsx";
import Integrations from "../../components/Integrations/index.jsx";
import Planet from "../../components/Planet/index.jsx";
import BusinessOutcomes from "./BusinessOutcomes.jsx";
import ProductEcosystem from "../../components/ProductEcosystem/index.jsx";
import Testimonials from "./Testimonials.jsx";
import CallToAction from "../../components/CallToAction/index.jsx";
import WhyChooseIntelswift from "../../components/WhyChooseIntelswift/index.jsx";
import Blog from "../../components/Blog/index.jsx";
import FAQs from "../../components/FAQs/index.jsx"
import Footer from "../../components/Footer/index.jsx";
// --- CSS ---
import "./index.css";

// --- HomePage Component ---
export default function HomePage() {
  const { t } = useTranslation();

  const whyChooseData = {
    title: t("HOME_PAGE.WHY_CHOOSE.TITLE"),
    benefits: [
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.MULTILINGUAL_SUPPORT.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.MULTILINGUAL_SUPPORT.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.HUMAN_HANDOFF.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.HUMAN_HANDOFF.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.SECURE_AI.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.OMNICHANNEL_SERVICE.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.OMNICHANNEL_SERVICE.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.ENTERPRISE_QUALITY.DESCRIPTION")
      },
      {
        title: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.WORKFLOW_TEMPLATES.TITLE"),
        description: t("HOME_PAGE.WHY_CHOOSE.BENEFITS.WORKFLOW_TEMPLATES.DESCRIPTION")
      }
    ]
  };

  const homePageFAQs = [
    { question: t("HOME_PAGE.FAQS.Q1.QUESTION"), answer: t("HOME_PAGE.FAQS.Q1.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q2.QUESTION"), answer: t("HOME_PAGE.FAQS.Q2.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q3.QUESTION"), answer: t("HOME_PAGE.FAQS.Q3.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q4.QUESTION"), answer: t("HOME_PAGE.FAQS.Q4.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q5.QUESTION"), answer: t("HOME_PAGE.FAQS.Q5.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q6.QUESTION"), answer: t("HOME_PAGE.FAQS.Q6.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q7.QUESTION"), answer: t("HOME_PAGE.FAQS.Q7.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q8.QUESTION"), answer: t("HOME_PAGE.FAQS.Q8.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q9.QUESTION"), answer: t("HOME_PAGE.FAQS.Q9.ANSWER") },
    { question: t("HOME_PAGE.FAQS.Q10.QUESTION"), answer: t("HOME_PAGE.FAQS.Q10.ANSWER") }
  ];

  // --- Render Component ---
  return (
    <>
      <SEO
        title={t("HOME_PAGE.META.TITLE")}
        description={t("HOME_PAGE.META.DESCRIPTION")}
        url="https://login.intelswift.com/oauth/account/sign-up"
      />
      <Header />
      <HeroSection />
      <LearnMore />
      <Integrations />
      <BusinessOutcomes />
      <ProductEcosystem
        isHomePage={true}
      />
      <Planet />
      {/* <Testimonials /> */}
      <CallToAction
        title={t("HOME_PAGE.CALL_TO_ACTION.TITLE")}
        showButton={true}
      />
      <WhyChooseIntelswift
        title={whyChooseData.title}
        benefits={whyChooseData.benefits}
        showButtons={whyChooseData.showButtons}
        isHomepage={true}
      />
      <Blog />
      <FAQs
        faqs={homePageFAQs}
      />
      <Footer />
    </>
  );
}
